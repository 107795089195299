import * as Utilities from "./utilities";
declare let dataLayer: any[];
declare let GtmBasketLineItems: any[];

export class GtmAnalytics {
    private static _instance: GtmAnalytics;
    private static IsGA4Enabled: boolean;

    private constructor() {
        this.Initialise();
    }

    private Initialise() {
        GtmAnalytics.IsGA4Enabled = Utilities.DataListObj.IsGA4Enabled;

        $(window).on("resize scroll", this.gtmListOfferCards);

        this.refreshGtmBindings();
    }

    public refreshGtmBindings() {
        $("[data-gtm-click-promo=true]").off("click", this.gtmClickPromotion);
        $("[data-gtm-click-promo=true]").on("click", this.gtmClickPromotion);

        $("[data-gtm-click-additemtocart=true], [data-gtm-click-product=true] .btn-add-to-cart").off("click", this.gtmClickAddItemToCart);
        $("[data-gtm-click-additemtocart=true], [data-gtm-click-product=true] .btn-add-to-cart").on("click", this.gtmClickAddItemToCart);

        $("[data-gtm-click-product=true]").off("click", this.gtmClickProduct);
        $("[data-gtm-click-product=true]").on("click", this.gtmClickProduct);

        $("[data-gtm-click-removeitemfromcart=true]").off("click", this.gtmClickRemoveItemFromCart);
        $("[data-gtm-click-removeitemfromcart=true]").on("click", this.gtmClickRemoveItemFromCart);

        $("[data-gtm-click-beginCheckout=true]").off("click", this.gtmClickBeginCheckout);
        $("[data-gtm-click-beginCheckout=true]").on("click", this.gtmClickBeginCheckout);

        $("[data-gtm-click-shippingInfoCheckout]").off("click", this.gtmClickShippingInfoCheckout);
        $("[data-gtm-click-shippingInfoCheckout]").on("click", this.gtmClickShippingInfoCheckout);

        $("[data-gtm-click-paymentInfoCheckout]").off("click", this.gtmClickPaymentInfoCheckout);
        $("[data-gtm-click-paymentInfoCheckout]").on("click", this.gtmClickPaymentInfoCheckout);

        $("[data-gtm-pdp-click-details]").off("click", this.gtmClickProductDetails);
        $("[data-gtm-pdp-click-details]").on("click", this.gtmClickProductDetails);

        $(".faq-item-title").off("click", this.gtmClickFAQTitle);
        $(".faq-item-title").on("click", this.gtmClickFAQTitle);

        $("[data-gtm-click-app]").off("click", this.gtmClickAppLink);
        $("[data-gtm-click-app]").on("click", this.gtmClickAppLink);
    }

    public gtmClickAppLink(sender: any) {
        if (GtmAnalytics.IsGA4Enabled) {
            let senderTarget = sender.currentTarget;

            dataLayer.push({
                "event": "download_app",
                "platform": senderTarget.getAttribute("data-gtm-event-platform"),
                "ga4": GtmAnalytics.IsGA4Enabled
            });
        }
    }

    public gtmClickProductDetails(sender: any) {
        if (GtmAnalytics.IsGA4Enabled) {
            let senderTarget = sender.currentTarget;

            dataLayer.push({
                "event": "product_details",
                "item_id": senderTarget.getAttribute("data-gtm-pdp-item-id"),
                "label": senderTarget.getAttribute("data-gtm-event-value")
            });
        }
    }

    public gtmNewsletter_Success(email: string) {
        if (!GtmAnalytics.IsGA4Enabled)
            dataLayer.push({
                "newsletterSubscriber": "true",
                "userEmailId": email,
                "event": "gaEvent",
                "eventCategory": "Newsletter",
                "eventAction": "Register",
                "eventLabel": "Success"
            });
        else
            dataLayer.push({
                "event": "generate_lead"
            });
    }

    public gtmClickFAQTitle(sender: any) {
        if (GtmAnalytics.IsGA4Enabled && sender.currentTarget.getElementsByTagName("a").length > 0 && sender.currentTarget.getElementsByTagName("a")[0].classList.contains("collapsed")) {
            let h2Title = sender.currentTarget.getElementsByTagName("h2")[0];
            let activeSection = document.querySelector("a.active");

            dataLayer.push({
                "event": "help_request",
                "action": activeSection.getAttribute("data-gtm-action-name"),
                "label": h2Title.getAttribute("data-gtm-label-name"),
                "ga4": GtmAnalytics.IsGA4Enabled
            });
        }
    }

    public gtmClickViewCart(items: any[], value: any) {

        if (!GtmAnalytics.IsGA4Enabled)
            return;

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: "EECviewCart",
            ecommerce: {
                currency: "EUR",
                value: value,
                items: items
            },
            "ga4": GtmAnalytics.IsGA4Enabled
        });
    }

    public gtmClickAddToFavs(item: any) {

        if (!GtmAnalytics.IsGA4Enabled)
            return;

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
            event: "EECaddToWishlist",
            ecommerce: {
                currency: "EUR",
                value: item["price"],
                items: [item]
            },
            "ga4": GtmAnalytics.IsGA4Enabled
        });
    }

    public gtmClickConfirmOrder() {
        if ($(".methods > .selected").length) {
            let paymentSelection = $(".methods > .selected").data().gtmDetailPaymentname;

            let toPush = {
                "event": "EECcheckoutOption",
                "paymentType": paymentSelection,
                "ecommerce": {
                    "checkout_option": {
                        "actionField": { "step": 4, "option": paymentSelection }
                    }
                }
            };

            dataLayer.push(toPush);
        }
        else if ($(".content > .left > .accordian[open]").length) {
            let paymentSelection = $(".content > .left > .accordian[open]").data().gtmDetailPaymentname;

            let toPush = {
                "event": "EECcheckoutOption",
                "paymentType": paymentSelection,
                "ecommerce": {
                    "checkout_option": {
                        "actionField": { "step": 4, "option": paymentSelection }
                    }
                }
            };

            dataLayer.push(toPush);
		}
    }

    public gtmClickShareProfile(itemRef: string, breadcrumb: string, method: string) {
        dataLayer.push({
            "event": "share",
            "method": method,
            "content_type": breadcrumb,
            "item_id": itemRef
        });
    }

    public gtmClickapplyFilters(filterName: string, filterType: string) {
        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": filterType,
            "eventAction": "apply (mobile)",
            "eventLabel": filterName
        });
    }

    public gtmGlobalEventTracking(sender: any) {
        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": $(sender).attr("data-gtm-event-category"),
            "eventAction": $(sender).attr("data-gtm-event-action"),
            "eventLabel": $(sender).attr("data-gtm-event-label")
        });
    }

    public gtmFilterEventTracking(sender: any) {
        let value = $(sender).attr("data-gtm-event-value");
        let eventAction = ($(sender).children("input").find("[checked]").length > 0) ? "select" : "delete";

        if ($(sender).parent().hasClass("delivery") || $(sender).parent().hasClass("pickup"))
            eventAction = !sender.hasAttribute("checked") ? "select" : "delete";

        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": $(sender).attr("data-gtm-event-category"),
            "eventAction": eventAction,
            "eventLabel": value
        });
    }

    public gtmPLPFilterEventTracking(sender: any) {
        let senderParent = $(sender).parent().parent()[0];
        let value = senderParent.hasAttribute("open")? "close" : "open";

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            value = "open";

            if (document.getElementById("productlist-facets").className.indexOf("show") < 0) {
                return;
            }
        }

        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": $(sender).attr("data-gtm-event-category"),
            "eventAction": value,
            "eventLabel": $(sender).attr("data-gtm-event-action")
        });
    }

    public gtmDDEventTracking(sender: any) {
        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": "product sort",
            "eventAction": "open " + $(sender).attr("data-gtm-event-type"),
            "eventLabel": ""
        });
    }

    public gtmCloseFilterEventTracking(sender: any) {
        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": "Product Filter",
            "eventAction": "close",
            "eventLabel": $(sender).attr("data-gtm-event-label")
        });
    }

    public gtmDDLiEventTracking(sender: any) {

        if (GtmAnalytics.IsGA4Enabled) {
            dataLayer.push({
                "event": "sort_products",
                "sort_method": $(sender).attr("data-gtm-event-value")
            });
        }
        else
            dataLayer.push({
                "event": "gaEvent",
                "eventCategory": "product sort",
                "eventAction": "select " + $(sender).attr("data-gtm-event-type"),
                "eventLabel": $(sender).attr("data-gtm-event-value")
            });
    }

    public gtmMobileFilterClickTracking(sender: any) {
        let dataType = $(sender).attr("data-gtm-event-action-type");

        let action = "select";
        if (typeof dataType != "undefined")
            action = "Open " + dataType; 

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (document.getElementById("productlist-facets").className.indexOf("show") < 0) {
                return;
            }
        }

        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": $(sender).attr("data-gtm-event-category"),
            "eventAction": action,
            "eventLabel": $(sender).attr("data-gtm-event-value")
        });
    }

    public gtmMobileFilterCloseTracking(sender: any) {
        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": "product filter",
            "eventAction": "deleted",
            "eventLabel": $(sender).attr("data-gtm-event-action")
        });
    }

    public gtmMobileChildFilterClickTracking(sender: any, isSelected: boolean) {
        let dataType = $(sender).attr("data-gtm-event-action-type");

        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": $(sender).attr("data-gtm-event-category"),
            "eventAction": (isSelected ? "Select " : "delete ") + (dataType ?? "").toLowerCase(),
            "eventLabel": $(sender).attr("data-gtm-event-value")
        });
    }

    public gtmPLPFilterClickTracking(sender: any) {
        let value = sender.checked ? "select" : "delete";

        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": $(sender).attr("data-gtm-event-category").toLowerCase(),
            "eventAction": value,
            "eventLabel": $(sender).attr("data-gtm-event-value")
        });
    }

    public gtmPLPDeleteFilterTracking() {
        dataLayer.push({
            "event": "gaEvent",
            "eventCategory": "product filter",
            "eventAction": "delete",
            "eventLabel": "all"
        });
    }

    public gtmClickAddItemToCart(sender: any) {
        let prod = [];

        if (typeof sender.currentTarget != "undefined" && sender.currentTarget.tagName == "BUTTON" && Utilities.DataListObj.PageType != "OfferDetail")
            prod = GtmAnalytics._instance.generateGTMProduct(sender.currentTarget.parentElement.parentElement);
        else if (typeof sender.currentTarget != "undefined")
            prod = GtmAnalytics._instance.generateGTMProduct(sender.currentTarget);
        else
            prod = GtmAnalytics._instance.generateGTMProduct(sender);

        if (!GtmAnalytics.IsGA4Enabled) {
            if (!prod["variant"]) {
                prod["variant"] = $("#configurable-picker option:selected").text();
                if (!prod["variant"])
                    prod["variant"] = $(".offer-configurable-single-value").text();

                if (prod["variant"] == "") {
                    delete prod["variant"];
                }
            }
            let quantityId = $(this).attr("data-gtm-detail-quantity-name");
            if (quantityId) {
                prod["quantity"] = parseInt($("input[name=" + quantityId + "]").val().toString());
            }
            else if ($("input[name=quantity]").length != 0) {
                prod["quantity"] = parseInt($("input[name=quantity]").val().toString());
            }
            else {
                prod["quantity"] = 1;
            }


            let eventCategory = "Product Detail Page";

            if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0]["pageType"] != "undefined"
                && (dataLayer[0]["pageType"].toLowerCase() == "category" || dataLayer[0]["pageType"].toLowerCase() == "Search"))
                eventCategory = "Product List";

            if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0]["pageType"] != "undefined"
                && dataLayer[0]["pageType"].toLowerCase() == "home")
                eventCategory = "Home Page";

            if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0]["pageType"] != "undefined"
                && dataLayer[0]["pageType"].toLowerCase() == "checkout")
                eventCategory = "Checkout Page";

            dataLayer.push({
                "event": "EECaddToCart",
                "ecommerce": {
                    "currencyCode": "EUR",
                    "add": {
                        "products": [prod]
                    }
                }
            });

            if (typeof prod != "undefined")
                dataLayer.push({
                    "event": "gaEvent",
                    "eventCategory": eventCategory,
                    "eventAction": "Add to cart",
                    "eventLabel": prod.name
                });

        } else {
            if (!prod["item_variant"]) {
                prod["item_variant"] = $(".configurables-measures li.selected").attr("data-value");
                if (!prod["item_variant"])
                    prod["item_variant"] = $(".configurables-colors .desc").attr("data-color");

                if (prod["item_variant"] == "") {
                    delete prod["item_variant"];
                }
            }
            let quantityId = $(this).attr("data-gtm-detail-quantity-name");
            if (quantityId) {
                prod["quantity"] = parseInt($("input[name=" + quantityId + "]").val().toString());
            }
            else if ($(".page-offerV3 .quantityV2 input[name=quantity]").length != 0) {
                prod["quantity"] = parseInt($(".page-offerV3 .quantityV2 input[name=quantity]").val().toString());
            }
            else {
                prod["quantity"] = 1;
            }

            dataLayer.push({ ecommerce: null });

            dataLayer.push({
                "event": "EECaddToCart",
                "ecommerce": {
                    "currency": "EUR",
                    "value": prod["price"],
                    "items": [prod]
                },
                "ga4": GtmAnalytics.IsGA4Enabled
            });
        }
    }

    public generateGTMProduct(target: string) {

        let gtmDetailPrice = $(target).attr("data-gtm-detail-price");
        if (gtmDetailPrice)
            gtmDetailPrice = parseFloat(gtmDetailPrice.replace(",", ".")).toFixed(2);

        if (GtmAnalytics.IsGA4Enabled) {
            let prod = ({
                "item_id": $(target).attr("data-gtm-detail-item-id"),
                "item_name": $(target).attr("data-gtm-detail-item-name"),
                "item_brand": $(target).attr("data-gtm-detail-item-brand"),
                "item_category": $(target).attr("data-gtm-detail-item-category"),
                "item_category2": $(target).attr("data-gtm-detail-item-category2"),
                "item_category3": $(target).attr("data-gtm-detail-item-category3"),
                "item_variant": $(target).attr("data-gtm-detail-item-variant"),
                "affiliation": $(target).attr("data-gtm-detail-item-affiliation"),
                "index": $(target).attr("data-gtm-detail-item-position"),
                "item_list_id": $(target).attr("data-gtm-detail-item-list-id"),
                "item_list_name": $(target).attr("data-gtm-detail-item-list-name"),
                "product_in_promotion": $(target).attr("data-gtm-detail-item-product-in-promotion"),
                "product_delivery_time": $(target).attr("data-gtm-detail-item-product-delivery-time"),
                "price": gtmDetailPrice,
            } as any);


            let dimension14 = $(target).attr("data-gtm-detail-dimension14");
            if (dimension14) {
                prod["dimension14"] = dimension14;
            }

            return prod;
        }

        let prod = ({
            "name": $(target).attr("data-gtm-detail-name"),
            "id": $(target).attr("data-gtm-detail-id"),
            "price": gtmDetailPrice,
            "brand": $(target).attr("data-gtm-detail-brand"),
            "category": $(target).attr("data-gtm-detail-category"),
            "variant": $(target).attr("data-gtm-detail-variant")
        } as any);
        let dimension8 = $(target).attr("data-gtm-detail-dimension8");
        if (dimension8) {
            prod["dimension8"] = dimension8;
        }
        let dimension14 = $(target).attr("data-gtm-detail-dimension14");
        if (dimension14) {
            prod["dimension14"] = dimension14;
        }
        let dimension126 = $(target).attr("data-gtm-detail-dimension126");
        if (dimension126) {
            prod["dimension126"] = dimension126;
        }
        let dimension1 = $(target).attr("data-gtm-detail-dimension1");
        if (dimension1) {
            prod["dimension1"] = dimension1;
        }
        let dimension2 = $(target).attr("data-gtm-detail-dimension2");
        if (dimension2) {
            prod["dimension2"] = dimension2;
        }
        let dimension18 = $(target).attr("data-gtm-detail-dimension18");
        if (dimension18) {
            prod["dimension18"] = dimension18;
        }
        let dimension19 = $(target).attr("data-gtm-detail-dimension19");
        if (dimension19) {
            prod["dimension19"] = dimension19;
        }
        let dimension20 = $(target).attr("data-gtm-detail-dimension20");
        if (dimension20) {
            prod["dimension20"] = dimension20;
        }
        let dimension21 = $(target).attr("data-gtm-detail-dimension21");
        if (dimension21) {
            prod["dimension21"] = dimension21;
        }
        let dimension24 = $(target).attr("data-gtm-detail-dimension24");
        if (dimension24) {
            prod["dimension24"] = dimension24;
        }
        let dimension25 = $(target).attr("data-gtm-detail-dimension25");
        if (dimension25) {
            prod["dimension25"] = dimension25;
        }
        let dimension127 = $(target).attr("data-gtm-detail-dimension127");
        if (dimension127) {
            prod["dimension127"] = dimension127;
        }
        let dimension128 = $(target).attr("data-gtm-detail-dimension128");
        if (dimension128) {
            prod["dimension128"] = dimension128;
        }
        let dimension129 = $(target).attr("data-gtm-detail-dimension129");
        if (dimension129) {
            prod["dimension129"] = dimension129;
        }
        let dimension130 = $(target).attr("data-gtm-detail-dimension130");
        if (dimension130) {
            prod["dimension130"] = dimension130;
        }
        let dimension135 = $(target).attr("data-gtm-detail-dimension135");
        if (dimension135) {
            prod["dimension135"] = dimension135;
        }
        let dimension137 = $(target).attr("data-gtm-detail-dimension137");
        if (dimension137) {
            prod["dimension137"] = dimension137;
        }
        let dimension139 = $(target).attr("data-gtm-detail-dimension139");
        if (dimension139) {
            prod["dimension139"] = dimension139;
        }
        let dimension140 = $(target).attr("data-gtm-detail-dimension140");
        if (dimension140) {
            prod["dimension140"] = dimension140;
        }
        let dimension142 = $(target).attr("data-gtm-detail-dimension142");
        if (dimension142) {
            prod["dimension142"] = dimension142;
        }
        let dimension143 = $(target).attr("data-gtm-detail-dimension143");
        if (dimension143) {
            prod["dimension143"] = dimension143;
        }
        let dimension153 = $(target).attr("data-gtm-detail-dimension153");
        if (dimension153) {
            prod["dimension153"] = dimension153;
        }
        let dimension154 = $(target).attr("data-gtm-detail-dimension154");
        if (dimension154) {
            prod["dimension154"] = dimension154;
        }
        let dimension158 = $(target).attr("data-gtm-detail-dimension158");
        if (dimension158) {
            prod["dimension158"] = dimension158;
        }
        let dimension159 = $(target).attr("data-gtm-detail-dimension159");
        if (dimension159) {
            prod["dimension159"] = dimension159;
        }
        let dimension160 = $(target).attr("data-gtm-detail-dimension160");
        if (dimension160) {
            prod["dimension160"] = dimension160;
        }
        let dimension164 = $(target).attr("data-gtm-detail-dimension164");
        if (dimension164) {
            prod["dimension164"] = dimension164;
        }
        let dimension166 = $(target).attr("data-gtm-detail-dimension166");
        if (dimension166) {
            prod["dimension166"] = dimension166;
        }
        let metric103 = $(target).attr("data-gtm-detail-metric103");
        if (metric103) {
            prod["metric103"] = metric103;
        }
        let metric104 = $(target).attr("data-gtm-detail-metric104");
        if (metric104) {
            prod["metric104"] = metric104;
        }

        return prod;
    }

    private gtmListOfferCards() {
        let impressionsToPush: JQuery<any>[] = [];
        Array.prototype.forEach.call($(".offer-card, .offer-grid > article, .nosto-carousel article"), function (e: HTMLElement) {
            if (GtmAnalytics.Instance.isInViewport(e) && typeof $(e).data("isGTMListed") == "undefined") {
                $(e).data("isGTMListed", "true");
                impressionsToPush.push($(e));
            }
        });
        if (impressionsToPush.length > 0) {
            let products: any[] = [];
            Array.prototype.forEach.call(impressionsToPush, function (e: any) {

                let prod = GtmAnalytics.Instance.generateGTMProduct(e.find("a")[0]);

                if (GtmAnalytics.IsGA4Enabled) {
                    products.push(prod);
                }
                else {
                    let wrapper = $(e).find(".product-container-wrapper");
                    if (!wrapper.is("a"))
                        wrapper = $(e).find("a").eq(0);
                    if (typeof wrapper.attr("data-gtm-detail-productlisting") == "undefined") {
                        wrapper = $(e).find(".nosto-btn").eq(0);
                        prod = GtmAnalytics.Instance.generateGTMProduct(e.find(".nosto-btn")[0]);
                    }

                    prod.list = wrapper.attr("data-gtm-detail-productlisting");
                    prod.position = parseInt(wrapper.attr("data-gtm-detail-position"));

                    if (isNaN(prod.position))
                        if (wrapper.attr("href").split("?").length > 1)
                            if (wrapper.attr("href").split("?")[1].split("&pos=").length > 1)
                                prod.position = wrapper.attr("href").split("?")[1].split("&pos=")[1];

                    products.push(prod);
                }
            });

            if (GtmAnalytics.IsGA4Enabled) {
                dataLayer.push({ ecommerce: null });

                dataLayer.push({
                    "event": "EECproductImpression",
                    "ecommerce": {
                        "item_list_id": document.getElementById("productlist-listing").getAttribute("data-gtm-collection-id"),
                        "item_list_name": document.getElementById("productlist-listing").getAttribute("data-gtm-collection-name"),
                        "items": products
                    },
                    "ga4": GtmAnalytics.IsGA4Enabled
                });
            }
            else {
                dataLayer.push({
                    "event": "EECproductImpression",
                    "ecommerce": {
                        "currencyCode": "EUR",
                        "impressions": products
                    }
                });
            }
        }
    }

    private gtmClickProduct(e: any) {

        let prod = gtmAnalytics.generateGTMProduct(e.currentTarget);

        let eventName = "EECproductClick";

        if (!GtmAnalytics.IsGA4Enabled) {
            prod["position"] = gtmAnalytics.getGTMPosition(e.currentTarget);

            if (isNaN(prod.position))
                if ($(e.currentTarget).attr("href").split("?").length > 1)
                    if ($(e.currentTarget).attr("href").split("?")[1].split("&pos=").length > 1)
                        prod.position = $(e.currentTarget).attr("href").split("?")[1].split("&pos=")[1];

            let eventName = "EECproductClick";

            dataLayer.push({
                "event": eventName,
                "ecommerce": {
                    "click": {
                        "actionField": { "list": gtmAnalytics.getGTMProductListing(e.currentTarget) },
                        "products": [prod]
                    }
                }
            });
        }
        else {
            dataLayer.push({ ecommerce: null });

            dataLayer.push({
                "event": eventName,
                "ecommerce": {
                    "item_list_id": document.getElementById("productlist-listing").getAttribute("data-gtm-collection-id"),
                    "item_list_name": gtmAnalytics.getGTMProductListing(e.currentTarget),
                    "items": [prod]
                },
                "ga4": GtmAnalytics.IsGA4Enabled
            });
        }

    }

    private getGTMProductListing(target: any) {
        return $(target).attr("data-gtm-detail-productlisting");
    }

    private getGTMPosition(target: any) {
        let pos = $(target).attr("data-gtm-detail-position");
        if (pos) {
            return parseFloat(pos.replace(",", "."));
        }
        else {
            return 1;
        }
    }

    private gtmClickPromotion(e: any) {

        if (typeof e.type != "undefined" && e.type.toLowerCase() == "click")
            e = e.currentTarget;

        if (!GtmAnalytics.IsGA4Enabled) {
            if (typeof $(e).data("isGTMListed") == "undefined" && typeof $(e).attr("data-gtm-detail-name") != "undefined") {
                let promotions = [];
                let promotion: any;
                promotion = {
                    "id": $(e).attr("data-gtm-detail-id"),
                    "name": $(e).attr("data-gtm-detail-creative"),
                    "creative": $(e).attr("data-gtm-detail-name"),
                    "position": $(e).attr("data-gtm-detail-position"),
                };
                let dimension14 = $(e).attr("data-gtm-detail-dimension14");
                if (dimension14) {
                    promotion["dimension14"] = dimension14;
                }
                promotions.push(promotion);

                dataLayer.push({
                    "event": "EECpromotionClick",
                    "ecommerce": {
                        "promoClick": {
                            "promotions": promotions
                        }
                    }
                });

                $(e).data("isGTMListed", "true");
            }
        }
        else {
            if (typeof $(e).data("isGTMListed") == "undefined" && typeof $(e).attr("data-gtm-detail-name") != "undefined") {
                let promotion = {
                    "promotion_id": $(e).attr("data-gtm-detail-id"),
                    "creative_name": $(e).attr("data-gtm-detail-creative"),
                    "promotion_name": $(e).attr("data-gtm-detail-name"),
                    "creative_slot": $(e).attr("data-gtm-detail-position"),
                };

                dataLayer.push({ ecommerce: null });
                
                dataLayer.push({
                    "event": "EECpromotionClick",
                    "ecommerce": promotion,
                    "ga4": GtmAnalytics.IsGA4Enabled
                });

                $(e).data("isGTMListed", "true");
            }
        }
    }

    private gtmClickRemoveItemFromCart(e: any) {

        let prod = GtmAnalytics._instance.generateGTMProduct(e.currentTarget);

        prod["quantity"] = parseInt($(this).attr("data-gtm-detail-quantity"));

        if (!GtmAnalytics.IsGA4Enabled) {
            dataLayer.push({
                "event": "EECremoveFromCart",
                "ecommerce": {
                    "currencyCode": "EUR",
                    "remove": {
                        "products": [prod]
                    }
                }
            });
        }
        else {

            let price = prod["price"];

            if (!price && e.currentTarget.parentElement.getElementsByClassName("price-after").length > 0) {
                prod["price"] = parseFloat(e.currentTarget.parentElement.getElementsByClassName("price-after")[0].textContent.replace("€", "").replace(",", "."));
            }

            let dimension14 = $(this).attr("data-gtm-detail-dimension14");
            if (dimension14) {
                prod["dimension14"] = dimension14;
            }

            dataLayer.push({ ecommerce: null });

            dataLayer.push({
                "event": "EECremoveFromCart",
                "ecommerce": {
                    "currency": "EUR",
                    "value": prod["price"],
                    "items": [prod]
                },
                "ga4": GtmAnalytics.IsGA4Enabled
            });
        }
    }

    private gtmClickBeginCheckout() {

        let products = GtmBasketLineItems;

        if (!GtmAnalytics.IsGA4Enabled || !products)
            return;

        let promoInput = "";
        if (document.getElementsByClassName("promo-cancel").length > 0) {
            promoInput = document.querySelector(".promo-cancel input[readonly]").textContent;
        }

        let price = 0;

        if (document.getElementsByClassName("subtotal").length > 0 && document.querySelector(".subtotal span")) {
            price = parseFloat(document.querySelector(".subtotal span").textContent.replace(".", "").replace(",", "."));
        }

        dataLayer.push({ ecommerce: null });

        dataLayer.push({
            "event": "EECBeginCheckout",
            "ecommerce": {
                "currency": "EUR",
                "value": price,
                "coupon": promoInput,
                "items": products
            },
            "ga4": GtmAnalytics.IsGA4Enabled
        });
    }

    private gtmClickShippingInfoCheckout() {

        let products = GtmBasketLineItems;

        if (!GtmAnalytics.IsGA4Enabled || !products)
            return;

        let promoInput = "";
        if (document.getElementsByClassName("promo-cancel").length > 0) {
            promoInput = document.querySelector(".promo-cancel input[readonly]").textContent;
        }

        let price = 0;

        if (document.getElementsByClassName("subtotal").length > 0 && document.querySelector(".subtotal span")) {
            price = parseFloat(document.querySelector(".subtotal span").textContent.replace(".", "").replace(",", "."));
        }

        let shipingTier = "";

        if (document.querySelector("[checked]"))
            shipingTier = document.querySelector("[checked]").getAttribute("data-type");

        dataLayer.push({ ecommerce: null });

        dataLayer.push({
            "event": "EECAddShippingInfo",
            "ecommerce": {
                "currency": "EUR",
                "value": price,
                "coupon": promoInput,
                "shipping_tier": shipingTier,
                "items": products
            },
            "ga4": GtmAnalytics.IsGA4Enabled
        });
    }

    private gtmClickPaymentInfoCheckout() {

        try {
            let products = GtmBasketLineItems;

            if (!GtmAnalytics.IsGA4Enabled || !products)
                return;

            let promoInput = "";
            if (document.getElementsByClassName("promo-cancel").length > 0) {
                promoInput = document.querySelector(".promo-cancel input[readonly]").textContent;
            }

            let price = 0;

            if (document.getElementsByClassName("total").length > 0 && document.querySelector(".total span")) {
                price = parseFloat(document.querySelector(".total span").textContent.replace(".", "").replace(",", "."));
            }

            let paymentType = "";

            if (document.querySelector("details[open]"))
                paymentType = document.querySelector("details[open]").getAttribute("data-type");

            dataLayer.push({ ecommerce: null });

            dataLayer.push({
                "event": "EECAddPaymentInfo",
                "ecommerce": {
                    "currency": "EUR",
                    "value": price,
                    "coupon": promoInput,
                    "payment_type": paymentType,
                    "items": products
                },
                "ga4": GtmAnalytics.IsGA4Enabled
            });
        } catch (e: any) { }
    }

    public quantcastChoiceUpdateCookieTags(cookies: string[]) {
        let grantedCookies: { [id: string]: any; } = {};
        let grantedCookiesString = "";

        cookies.forEach(function (cookie) {
            if (cookie != "") {
                grantedCookies[cookie] = "granted";
                grantedCookiesString += cookie + "|";
            }
        });

        gtag("consent", "update", grantedCookies as Gtag.ConsentParams);

        dataLayer.push({
            "event": "update_cookies"
        });

        Utilities.createCookie("gtagGrantedCookies", grantedCookiesString, 360);
    }

    public isInViewport(element: HTMLElement) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }


    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

let gtmAnalytics: GtmAnalytics;
gtmAnalytics = GtmAnalytics.Instance;

if (gtmAnalytics) {
    (window as any).gtmAnalytics = GtmAnalytics.Instance;
}