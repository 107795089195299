﻿/** Shop Pop Up Availability start **/
let availabilityLinkId = ".availability-popup-link";
let availabilityPopUpHolder = "body";
let availabilitySectionId = " .availability-modal ";
let postCodeSectionId = ".postcode-basket-modal ";
let availabilityRegionSectionId = " .availability-region-modal ";
let availabilitySectionTempHolderId = " #availability-modal-temp ";
let availabilityRegionFormSelector = "#product-availability-form";
//$(availabilityLinkId).bind("click", GetAvailabilityForm);
$(document).ready(function () {
    $(".availability-popup-link-from-card").bind("click", GetAvailabilityFormFromCard);
});

function GetAvailabilityFormFromCard(ev) {
    let quantity = 1;
    let itemReference = $(this).closest("div.product-container").find("input[name=itemReference]").val();
    let productReference = $(this).closest("div.product-container").find("input[name=productReference]").val();
    let subfamily = $(this).closest("div.product-container").find("input[name=subfamily]").val();
    let specificItemReference = $(this).attr("data-itemReference");
    if (specificItemReference) {
        itemReference = specificItemReference;
    }
    GetAvailabilityForm(itemReference, productReference, subfamily);
    return false;
}
function GetAvailabilityForm(itemReferenceIn, productReferenceIn, subFamilyIn) {
    if ($(availabilityPopUpHolder).length == 0) {
        console.log("holder " + availabilityPopUpHolder + " not found");
        return false;
    }    
    if (itemReferenceIn === undefined || productReferenceIn === undefined || subFamilyIn === undefined
        || itemReferenceIn == "" || productReferenceIn == "" || subFamilyIn == "") {
        return false;
    }
    if (IsAvailabilitySectionLoaded()) {
        if (!IsAvailabilitySectionLoadedWithElements(itemReferenceIn, productReferenceIn, subFamilyIn)) {
            GetAvailabilityAjaxCall(itemReferenceIn, productReferenceIn, subFamilyIn);
        }
        else {
            $(availabilitySectionId).modal("show");
            //return true;
        }
    }
    else {
        GetAvailabilityAjaxCall(itemReferenceIn, productReferenceIn, subFamilyIn);
    }
    return false;
}
function GetAvailabilityRegionForm(itemReferenceIn, productReferenceIn, subFamilyIn, shopCode) {
    if ($(availabilityPopUpHolder).length == 0) {
        console.log("holder " + availabilityPopUpHolder + " not found");
        return false;
    }
    if (itemReferenceIn === undefined || productReferenceIn === undefined || subFamilyIn === undefined
        || itemReferenceIn == "" || productReferenceIn == "" || subFamilyIn == "") {
        return false;
    }
    //if (IsAvailabilityRegionSectionLoaded()) {
    //    if (!IsAvailabilityRegionSectionLoadedWithElements(itemReferenceIn, productReferenceIn, subFamilyIn)) {
    //        GetAvailabilityRegionAjaxCall(itemReferenceIn, productReferenceIn, subFamilyIn, shopCode)
    //    }
    //    else {
    //        $(availabilityRegionSectionId).modal('show');
    //        //return true;
    //    }
    //}
    //else {
        GetAvailabilityRegionAjaxCall(itemReferenceIn, productReferenceIn, subFamilyIn, shopCode);
    //}
    return false;
}

function GetAvailabilityAjaxCall(itemReferenceIn, productReferenceIn, subFamilyIn){
    $.post("/Offer/GetTheoreticalAvailabilityPopUpFormAsHtml",
            {
                "itemReference": itemReferenceIn,
                "productReference": productReferenceIn,
                "subFamily": subFamilyIn
            },
        function (data) {
            if (isSuccessful(data)) {
                if (IsAvailabilitySectionLoaded()) {
                    $(availabilityPopUpHolder + " " + availabilitySectionId + " .contents").html($("<div/>").html(data.FormHtml).contents().find(".contents").html());
                }
                else {
                    $(availabilityPopUpHolder).prepend(data.FormHtml);
                    $(availabilitySectionId).modal();                    
                }
                $(availabilitySectionId).modal("show");
                $(availabilitySectionId + " input[name*=PostalCode]").bind("focus", setRadioToPostalCode);
                $(availabilitySectionId + " select").bind("click", setRadioToList);
                $(".look-for-shop-btn").click("click", LookForShop);
                $(selectShopButtonId).bind("click", SelectShopButtonClick);
                $(shopSelectionRadios).bind("click", EnableSubmitShopButton);
                DisabledSubmitShopButton();
                $(availabilityPopup_itemReference).val(itemReferenceIn);
                $(availabilityPopup_productReference).val(productReferenceIn);
                $(availabilityPopup_subFamily).val(subFamilyIn);
                setRadioToPostalCode();
                LookForShop();
            }
            populateStatusMessage(data);
        });
    return false;
}
function GetAvailabilityRegionAjaxCall(itemReferenceIn, productReferenceIn, subFamilyIn, shopCode) {
    if (ajaxBusy)
        ajaxBusy.PageBusy = true;
    $.post("/Offer/GetTheoreticalAvailabilityRegionPopUpFormAsHtml",
        {
            "itemReference": itemReferenceIn,
            "productReference": productReferenceIn,
            "subFamily": subFamilyIn,
            "isShopCode": shopCode
        },
        function (data) {
            if (isSuccessful(data)) {
                if (IsAvailabilityRegionSectionLoaded()) {
                    $(availabilityPopUpHolder + " " + availabilityRegionSectionId + " .contents").html($("<div/>").html(data.FormHtml).contents().find(".contents").html());
                }
                else {
                    $(availabilityPopUpHolder).prepend(data.FormHtml);
                    $(availabilityRegionSectionId).modal();
                }
                $(availabilityRegionSectionId).on("hide.bs.modal", function (e) {
                    if (gtmVirtualPageViewStoreSelect) {
                        gtmVirtualPageviewStoreSelectClosed();
                    }
                });
                $(availabilityRegionSectionId).modal("show");
                if (gtmVirtualPageViewStoreSelect) {
                    gtmVirtualPageViewStoreSelect();
                }
                $(availabilityRegionSectionId + " input[name*=PostalCode]").bind("focus", setRadioToPostalCode);
                $(availabilityRegionSectionId + " select").bind("click", setRadioToList);
                $(".look-for-shop-btn").click("click", LookForShopWithRegion);
                $(selectShopButtonId).bind("click", SelectShopButtonClick);
                $(shopSelectionRadios).bind("click", EnableSubmitShopButton);
                DisabledSubmitShopButton();
                $(availabilityPopup_itemReference).val(itemReferenceIn);
                $(availabilityPopup_productReference).val(productReferenceIn);
                $(availabilityPopup_subFamily).val(subFamilyIn);
                setRadioToPostalCode();
                LookForShopWithRegion();
                let form = $(availabilityRegionFormSelector);
                form.removeData("validator");
                form.removeData("unobtrusiveValidation");
                $.validator.unobtrusive.parse(form);
            }
            populateStatusMessage(data);
        });
    return false;
}

function IsAvailabilitySectionLoaded() {
    return $(availabilityPopUpHolder + " " + availabilitySectionId).length > 0;
}
function IsAvailabilityRegionSectionLoaded() {
    return $(availabilityPopUpHolder + " " + availabilityRegionSectionId).length > 0;
}
function CloseAvailabilityModal() {
    $(availabilitySectionId).modal("hide");
}
function ClosePostCodeModal() {
    $(postCodeSectionId).modal("hide");
}

function IsAvailabilitySectionLoadedWithElements(itemReferenceIn, productReferenceIn, subFamilyIn) {
    return IsAvailabilitySectionLoaded() != ""
    && $(availabilityPopup_itemReference).val() == itemReferenceIn
    && $(availabilityPopup_productReference).val() == productReferenceIn
    && $(availabilityPopup_subFamily).val() == subFamilyIn;
}

function IsAvailabilityRegionSectionLoadedWithElements(itemReferenceIn, productReferenceIn, subFamilyIn) {
    return IsAvailabilityRegionSectionLoaded() != ""
        && $(availabilityPopup_itemReference).val() == itemReferenceIn
        && $(availabilityPopup_productReference).val() == productReferenceIn
        && $(availabilityPopup_subFamily).val() == subFamilyIn;
}

var availabilityPopup_itemReference = "input[name=availability-popup-itemreference]";
var availabilityPopup_productReference = "input[name=availability-popup-productReference]";
var availabilityPopup_subFamily = "input[name=availability-popup-subfamily]";

function setRadioToList() {
    $("#FromList").prop("checked", true);
    return false;
}

function setRadioToPostalCode() {
    $("#PostalCode").prop("checked", true);
    return false;
}
let htmlWaitingArea = "<div class='loader'></div>";
function loadingAreas(area) {
    $(area).html(htmlWaitingArea);
}
function removeloadingAreas(area) {
    $(area).html("");
}

var shopSelectionRadios = "#shop-list-section-content input[type='radio']";
let shopSelectionWaitingArea = "#shop-list-section-content .waiting-area";
function SelectShopButtonClick(withRegion) {
    let shopCode = $(shopSelectionRadios + ":checked").val();
    withRegion = withRegion || false;

    let radioSelect = $("[name*='.RadioSelection']:checked").val();
    let postalCode;
    if (radioSelect == "PostalCode") {
        postalCode = $("input[name*=PostalCode]").val().trim();
    }

    if (shopCode !== undefined && shopCode != "") {

        $(selectShopButtonId).hide();
        disableAjaxBusySettings();
        loadingAreas(shopSelectionWaitingArea);

        $.post("/Offer/SetShopFromAvailabilityShopPopUpList",
            {
                "shopCode": shopCode,
                "postalCode": postalCode
            },
        function (data) {
            removeloadingAreas(shopSelectionWaitingArea);
            ShowSubmitShopMessage(data.ReponseStatus.Message);
            createAjaxBusySettings();

            if (withRegion && typeof CheckAvailabilityWithRegion == "function") {
                CheckAvailabilityWithRegion();
            }
            else if (typeof CheckAvailability == "function") {
                CheckAvailability();
            }

        });
    }
    return false;
}

function ShowSubmitShopMessage(message) {
    $(selectShopButtonId).hide();
    $(".shop-list-section .message").removeClass("hidden");
    $(".shop-list-section .message").html(message);
    setTimeout(HideSubmitShopMessage, 2000);
}
function HideSubmitShopMessage() {
    $(".shop-list-section .message").addClass("hidden");
    CloseAvailabilityModal();
    $(selectShopButtonId).show();
}
function ShowSubmitPostalCodeMessage(message) {
    $(".look-for-shop-btn").hide();
    $("#postcode-section-content-holder .message").removeClass("hidden");
    $("#postcode-section-content-holder .message").html(message);
    setTimeout(HideSubmitPostalCodeMessage, 2000);
}
function HideSubmitPostalCodeMessage() {
    $("#postcode-section-content-holder .message").addClass("hidden");
    ClosePostCodeModal();
    $(".look-for-shop-btn").show();
}
var selectShopButtonId = ".select-shop-btn";
function LookForShop() {
    let postalCode = "";
    let shopCode = "";

    let radioSelect = $("[name*='.RadioSelection']:checked").val();
    $("#product-availability-form").valid();
    if (radioSelect == "PostalCode") {
        postalCode = $("input[name*=PostalCode]").val().trim();
        if (postalCode == "") {
            return false;
        }
    }
    else {
        shopCode = $(availabilitySectionId + " select").val();
    }
    let shopListSectionId = "#shop-list-section-content-holder";
    disableAjaxBusySettings();
    loadingAreas(shopListSectionId);
    $.post("/Offer/GetUpdatedAvailabilityForPopUpSelection",
        {
            "itemReference": $(availabilityPopup_itemReference).val(),
            "productReference": $(availabilityPopup_productReference).val(),
            "subFamily": $(availabilityPopup_subFamily).val(),
            "postalCode": postalCode,
            "shopCode": shopCode
        },
    function (data) {
        if (isSuccessful(data)) {
            $(shopListSectionId).html(data.ListHtml);
            $(selectShopButtonId).bind("click", SelectShopButtonClick);
            $(shopSelectionRadios).bind("click", EnableSubmitShopButton);
            DisabledSubmitShopButton();
            refreshGTMBindingsShopSelection();
        }
        else {
            $(shopListSectionId).html("<strong>Error</strong>");
        }
        populateStatusMessage(data);
        createAjaxBusySettings();
    });
    return false;
}
function LookForShopWithRegion() {
    let postalCode = "";
    let region = "";

    let radioSelect = $("[name*='.RadioSelection']:checked").val();

    if (!$("#product-availability-form").valid())
        return false;

    if (radioSelect == "PostalCode") {
        postalCode = $("input[name*=PostalCode]").val().trim();
        if (postalCode == "") {
            return false;
        }
    }
    else {
        region = $(availabilityRegionSectionId + " select").val();
    }
    let shopListSectionId = "#shop-list-section-content-holder";
    disableAjaxBusySettings();
    loadingAreas(shopListSectionId);
    $.post("/Offer/GetUpdatedAvailabilityWithRegionForPopUpSelection",
        {
            "itemReference": $(availabilityPopup_itemReference).val(),
            "productReference": $(availabilityPopup_productReference).val(),
            "subFamily": $(availabilityPopup_subFamily).val(),
            "postalCode": postalCode,
            "region": region
        },
        function (data) {
            if (isSuccessful(data)) {
                $(shopListSectionId).html(data.ListHtml);
                $(selectShopButtonId).bind("click", SelectShopButtonClick);
                $(shopSelectionRadios).bind("click", EnableSubmitShopButton);
                DisabledSubmitShopButton();
                refreshGTMBindingsShopSelection();

                if ($(shopListSectionId).find("input[type='radio']").length) {
                    $(shopListSectionId).find("input[type='radio']").eq(0).click();
                }
            }
            else {
                $(shopListSectionId).html("<strong>Error</strong>");
            }
            populateStatusMessage(data);
            createAjaxBusySettings();
        });
    return false;
}
function SetPostCode() {
    let postalCode = "";

    $("#basket-postcode-form").valid();
    let shopListSectionId = "#postcode-section-content-holder";
    postalCode = $(shopListSectionId).find("#txt_codigo").val();

    disableAjaxBusySettings();
    loadingAreas(shopListSectionId);
    $.post("/Offer/SetInformedPostalCode",
        {
            "postalCode": postalCode
        },
        function (data) {
            $(shopListSectionId).html($(data.response).find("#postcode-section-content-holder").html());
            $(".look-for-shop-btn").click("click", SetPostCode);

            if (data.isSuccessful) {
                if (typeof refreshCart !== "undefined") {
                    refreshCart();
                    window.location.reload();
                }
                ShowSubmitPostalCodeMessage(data.Message);
                createAjaxBusySettings();
            }
        });
    return false;
}
function EnableSubmitShopButton() {
    $(selectShopButtonId).prop("disabled", false);
}
function DisabledSubmitShopButton() {
    $(selectShopButtonId).prop("disabled", true);
}
/** Shop Pop Up Availability End **/