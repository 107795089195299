import { app4Less } from "./app4Less";

class AjaxBusy {
    private readonly _AjaxTimeout = 500;
    private readonly _RedirectTimeout = 3000;
    private readonly _showClass = "show";
    private static _instance: AjaxBusy;
    private static _context: HTMLElement = document.getElementById("ajaxBusyV2");
    private _timeoutHandle: ReturnType<typeof setTimeout>;
    private _pageBusy = false;
    private _Window_KeyUp = this.Window_KeyUp.bind(this);

    private constructor() {
        this.Initialise();
    }

    private Initialise() {
        jQuery(document)
            .ajaxStart(() => this.PageBusy_AjaxStart())
            .ajaxStop(() => this.PageBusy_AjaxStop());

        AjaxBusy._context.querySelector(".close").addEventListener("click", () => this.Close_Click());
        window.addEventListener("pagehide", () => this.Window_BeforeUnload());
        window.addEventListener("pageshow", () => this.Hide());
    }

    private Close_Click() {
        AjaxBusy._context.classList.remove(this._showClass);
    }

    private Window_BeforeUnload() {
        let timeout = this._RedirectTimeout;
        const active = (document.activeElement as HTMLAnchorElement);
        if (active) {
            const currentHref = active.href;
            if (currentHref) {
                if (currentHref.indexOf("/checkout/cart") > -1)
                    timeout = this._AjaxTimeout;
                else if (currentHref.indexOf("tel:") == 0)
                    return;
            }
        }

        this.Show(undefined, timeout);
    }

    private Window_KeyUp(e: KeyboardEvent) {
        if (e.key == "Escape")
            this.Hide();
    }

    private PageBusy_AjaxStart() {
        if (this._pageBusy)
            this.Show();
    }

    private PageBusy_AjaxStop() {
        this._pageBusy = false;
        this.Hide();
    }

    public Show(container?: HTMLElement, timeout?: number) {
        if (this._timeoutHandle) {
            clearTimeout(this._timeoutHandle);
        }

        this._timeoutHandle = setTimeout(() => {
            clearTimeout(this._timeoutHandle);
            if (container) {
                const loader = document.createElement("div");
                loader.classList.add("loader");
                container.appendChild(loader);
                container.classList.add("loading");
            }
            else
                AjaxBusy._context.classList.add(this._showClass);

            if (app4Less)
                app4Less.ToggleBusy(true);
        }, timeout || this._AjaxTimeout);

        window.addEventListener("keyup", this._Window_KeyUp);
    }

    public Hide(container?: HTMLElement) {
        if (this._timeoutHandle) {
            clearTimeout(this._timeoutHandle);
        }
        if (container) {
            const loader = container.querySelector(":scope > .loader");
            if (loader)
                container.removeChild(loader);
            container.classList.remove("loading");
        }
        else
            AjaxBusy._context.classList.remove(this._showClass);

        if (app4Less)
            app4Less.ToggleBusy(false);

        window.removeEventListener("keyup", this._Window_KeyUp);
    }

    public get PageBusy() {
        return this._pageBusy;
    }

    public set PageBusy(val: boolean) {
        this._pageBusy = val;
    }

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}
export const ajaxBusy = (window as any).ajaxBusy || AjaxBusy.Instance;
if (ajaxBusy) {
    (window as any).ajaxBusy = ajaxBusy;
}