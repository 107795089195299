﻿jQuery(document).ready(function () {
    createAjaxBusySettings();

});
function disableAjaxBusySettings() {
    $.ajaxSetup({
        cache: false,
        async: true,
        beforeSend: function () {
        },
        complete: function () {
        },
        error: function (e, x, settings, exception) { }
    });
}
function showPageLoadingScreen() {
    $("#ajaxBusy").fadeIn();
}
function removePageLoadingScreen() {
    $("#ajaxBusy").fadeOut();
}
function createAjaxBusySettings() {

    $.ajaxSetup({
        cache: false,
        async: true,
        beforeSend: function () {
            if (!/^\/validation\//i.test(this.url))
                showPageLoadingScreen();
        },
        complete: function () {
            if (!/^\/validation\//i.test(this.url))
                removePageLoadingScreen();
        },
        error: function (e, x, settings, exception) {
            let logError = false;
            if (logError) {
                //console.log("Error occured.");
                //console.log(e);
                //console.log(x);
                //console.log(settings);
                //console.log(exception);
                let message;
                let statusErrorMap = {
                    "400": "Server understood the request, but request content was invalid.",
                    "401": "Unauthorized access.",
                    "403": "Forbidden resource can't be accessed.",
                    "500": "Internal server error.",
                    "503": "Service unavailable."
                };
                if (x.status) {
                    message = statusErrorMap[x.status];
                    if (!message) {
                        message = "Unknown Error \n.";
                    }
                } else if (exception == "parsererror") {
                    message = "Error.\nParsing JSON Request failed.";
                } else if (exception == "timeout") {
                    message = "Request Time out.";
                } else if (exception == "abort") {
                    message = "Request was aborted by the server";
                } else {
                    message = "Unknown Error. \n";
                }
                $(this).css("display", "inline");
                alert("Error occured : " + settings + ".\nError type : " + message);
            }
            //ShowPageDialogBoxSingleButton("Error", "Error occured : " + settings + ". Error type : " + message, "Ok", function () { });
        },
    });

}