export class ToTop {
    private readonly _ShowTopOffset = 150;
    private static _instance: ToTop;
    private static _context: HTMLElement = document.getElementById("toTop");
    private _bottomTranslate: number;
    private _shownStatus = false;

    private constructor() {
        this.Initialise();
    }

    private Initialise() {
        this.Window_Scroll();
        ToTop._context.addEventListener("click", () => this.ToTop_Click());
        addEventListener("scroll", () => this.Window_Scroll());
    }

    private ToTop_Click() {
        scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    private Window_Scroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (!this._shownStatus && scrollTop >= this._ShowTopOffset) {
            ToTop._context.classList.add("show");
            if (this._bottomTranslate)
                this.ApplyTransform();
            this._shownStatus = true;
        }
        else if (this._shownStatus && scrollTop < this._ShowTopOffset) {
            ToTop._context.classList.remove("show");
            ToTop._context.style.removeProperty("transform");
            this._shownStatus = false;
        }
    }

    public get BottomTranslate() {
        return this._bottomTranslate;
    }

    public set BottomTranslate(translate: number) {
        this._bottomTranslate = translate;
        if (this._shownStatus)
            this.ApplyTransform();
    }

    private ApplyTransform() {
        ToTop._context.style.setProperty("transform", `translateY(${this._bottomTranslate}px)`, "important");
    }

    public static get Context() {
        return this._context;
    }

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}
export const toTop = ToTop.Instance;