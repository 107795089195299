import * as Variables from "./variables";
import * as Enums from "./_enumerations";
declare function __tcfapi(...args: any[]): void;

export class Footer {
    private static _instance: Footer;
    private static readonly _context: HTMLElement = document.querySelector("#footer:not(.footer-basket)");
    private readonly _linkDetails = Footer._context.querySelectorAll(".links details");
    private readonly _descContent = Footer._context.querySelector(".desc > div > .content") as HTMLElement;
    private readonly _LinkDetails_Click = this.LinkDetails_Click.bind(this);
    private readonly _legal = Footer._context.querySelector(".legal") as HTMLElement;
    
    private constructor() {
        if (this._descContent) {
            const toggle = this._descContent.nextElementSibling;
            if (toggle && toggle.classList.contains("toggle")) {
                toggle.addEventListener("click", (e) => this.DescToggle_Click(e));
            }
        }

        if (this._legal) {
            const cookieConfig = this._legal.querySelector(".cookie-config");
            if (cookieConfig && typeof __tcfapi !== "undefined") {
                cookieConfig.addEventListener("click", this.CookieConfig_Click);
            }
        }

        const screen_Md_Lower = Variables.screen(Enums.Screen.md, Enums.ScreenBoundary.lower);
        screen_Md_Lower.addListener((e) => this.Screen_Md_Lower_Change(e.matches));
        this.Screen_Md_Lower_Change(screen_Md_Lower.matches);
    }

    private Screen_Md_Lower_Change(matches: boolean) {
        if (matches) {
            this._linkDetails.forEach((detail) => {
                detail.setAttribute("open", "");
                detail.querySelector(":scope > summary").removeEventListener("click", this._LinkDetails_Click);
            });
        } else {
            this._linkDetails.forEach((detail) => {
                detail.removeAttribute("open");
                detail.querySelector(":scope > summary").addEventListener("click", this._LinkDetails_Click);
            });
        }
    }

    private CookieConfig_Click = (() => {
        __tcfapi("displayConsentUi", 2, function () { });
    });

    private LinkDetails_Click(e: Event) {
        const summary = e.currentTarget as HTMLElement;
        const detail = summary.parentElement as HTMLElement;
        const ul = summary.parentElement.querySelector(":scope > ul") as HTMLElement;

        if (!parseInt(ul.style.maxHeight)) {
            requestAnimationFrame(() => ul.style.maxHeight = `${ul.scrollHeight}px`);
        }
        else {
            ul.style.maxHeight = "0px";
            const transitionEnd = (e: Event) => {
                e.currentTarget.removeEventListener(e.type, transitionEnd);
                detail.removeAttribute("open");
            };
            ul.addEventListener("transitionend", transitionEnd);
            e.preventDefault();
        }
    }

    private DescToggle_Click(e: Event) {
        const toggle = e.target as HTMLButtonElement;

        if (this._descContent.classList.toggle("expand")) {
            this._descContent.style.maxHeight = `${this._descContent.scrollHeight}px`;
            toggle.textContent = toggle.dataset.collapse;
        }
        else {
            this._descContent.style.removeProperty("max-height");
            toggle.textContent = toggle.dataset.expand;
        }
    }

    public static get Context() {
        return this._context;
    }

    public static get Apps() {
        if (!Footer._context)
            return;

        return Footer._context.querySelector(".apps");
    }

    public static get Desc() {
        if (!Footer._context)
            return;

        return Footer._context.querySelector(".desc");
    }

    public static get Info() {
        if (!Footer._context)
            return;

        return Footer._context.querySelector(".info");
    }

    public static get Social() {
        if (!Footer._context)
            return;

        return Footer._context.querySelector(".social");
    }

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}

export let footer: Footer;
footer = Footer.Instance;

if (footer) {
    (window as any).footer = footer;
}