import * as Variables from "./variables";
import * as Utilities from "./utilities";
import * as Enums from "./_enumerations";
import { isScrolledIntoView } from "./utilities";
import { header, Header } from "./headerV2";
import { Footer } from "./footer";

export class HorizontalMenu {
	private static _instance: HorizontalMenu;
	private static readonly _context: HTMLElement = document.getElementById("menuV4");
	private readonly _menu: HTMLElement = HorizontalMenu._context.querySelector(":scope > ul");
	private readonly _subMenus = this._menu.querySelectorAll(":scope > li");
	private readonly _rightMenuItems: Array<Element> = [];
	private readonly _hoverSensitivityMs = 50;
	private _hoverTimeoutHandle: number;
	private _initialised: boolean;

	private constructor() {
		Variables.mobile.addListener((e) => this.Mobile_Change(e.matches));
		this.Mobile_Change(Variables.mobile.matches);
	}

	private Mobile_Change(mobile: boolean) {
		if (!mobile)
			this.Initialise();
	}

	private Initialise() {
		if (this._initialised)
			return;

		this._subMenus.forEach(_ => {
			_.addEventListener("touchend", this.TopMenuItem_TouchEnd);
			_.addEventListener("mouseenter", this.TopMenuItem_MouseEnter);
			_.addEventListener("mouseleave", this.Item_MouseLeave);
		});
	}

	private TopMenuItem_TouchEnd = ((e: Event) => {
		const item = e.currentTarget as HTMLElement;

		if (item.classList.contains("fade"))
			return;

		e.preventDefault();

		this.ShowSubMenu(item);
	}).bind(this);

	private TopMenuItem_MouseEnter = ((e: Event) => {
		const item = e.currentTarget as HTMLElement;

		window.clearTimeout(this._hoverTimeoutHandle);

		if (item.classList.contains("fade"))
			return;

		this._hoverTimeoutHandle = window.setTimeout(() =>
			this.ShowSubMenu(item), this._hoverSensitivityMs);
	}).bind(this);

	private Item_MouseLeave = ((_e: Event) => {
		this.ToggleShown(false);
	}).bind(this);

	private ShowSubMenu(item: HTMLElement) {
		if (item.classList.contains("fade"))
			return;

		this.Hide();

		item.classList.add("fade");
		item.focus();

		this.ToggleShown(true);
	}

	private ToggleShown(show: boolean) {
		//document.documentElement.classList.toggle("noscroll", show);

		if (!show)
			this.Hide();

		this.ToggleVeil(show);
	}

	private ToggleVeil(show: boolean) {
		// Reset Body_TransitionEnd
		document.body.removeEventListener("transitionend", this.Body_TransitionEnd);

		if (show) {
			document.body.classList.remove("out");
			document.body.classList.add("veil-header");

			if (!Variables.mobile.matches) {
				this._menu.style.setProperty("--head-height", `${Header.Context.offsetHeight - 1}px`); // Remove 1px as offsetHeight gets ceil value
			}
		}
		else {
			this._menu.style.removeProperty("--head-height");

			if (document.body.classList.contains("veil-header")) {
				document.body.addEventListener("transitionend", this.Body_TransitionEnd);
				requestAnimationFrame(_ => document.body.classList.add("out"));
			}
		}

		Utilities.ToggleEventListener(document.body, "mousedown", this.Body_MouseDown, show);
	}

	private Body_MouseDown = ((e: Event) => {
		if (e.currentTarget !== e.target)
			return;

		this.ToggleShown(false);
	}).bind(this);

	private Body_TransitionEnd = (() => {
		requestAnimationFrame(_ => {
			document.body.classList.remove("out");
			document.body.classList.remove("veil-header");
		});
		document.body.removeEventListener("transitionend", this.Body_TransitionEnd);
	}).bind(this);

	private Hide() {
		window.clearTimeout(this._hoverTimeoutHandle);

		this._menu.querySelectorAll(".fade").forEach(_ => _.classList.remove("fade"));
	}

	public static get Instance() {
		if (!this._context)
			return;

		if (!this._instance && (window as any).horizontalMenu)
			this._instance = (window as any).horizontalMenu;

		return this._instance || (this._instance = new this());
	}
}
export const horizontalMenu = HorizontalMenu.Instance;

if (horizontalMenu) {
	(window as any).horizontalMenu = horizontalMenu;
}