import * as Utilities from "./utilities";

type DeliverySearch_Request = (deliveryType: Utilities.DeliveryType) => void;
type SetDeliveryValid = () => void;

export class PostCode {
    private readonly _DeliverySearch_KeyUpDelay = 1000;
    private _DeliverySearch_KeyUpDelay_Timer: ReturnType<typeof setTimeout>;
    private _DeliverySearch_Request: DeliverySearch_Request;
    private _SetDeliveryValid: SetDeliveryValid;
    private _ThisContext: any;

    constructor(postCode: HTMLInputElement, thisArg: any, DeliverySearch_Request: DeliverySearch_Request, SetDeliveryValid: SetDeliveryValid) {
        if (!postCode)
            return;

        this._DeliverySearch_Request = DeliverySearch_Request;
        this._SetDeliveryValid = SetDeliveryValid;
        this._ThisContext = thisArg;

        postCode.addEventListener("keyup", (e) => this.PostCode_KeyUp(e));
        postCode.addEventListener("focus", (e) => this.PostCode_Focus(e));
        postCode.addEventListener("focusout", (e) => this.PostCode_Focusout(e));
    }

    private PostCode_KeyUp(e: Event) {
        const postCode = e.target as HTMLInputElement;
        clearTimeout(this._DeliverySearch_KeyUpDelay_Timer);

        if ((e as KeyboardEvent).key === "Enter") {
            e.preventDefault();
            postCode.blur();
        }
        else if (isNaN(parseInt((e as KeyboardEvent).key))) {
            this._DeliverySearch_KeyUpDelay_Timer = setTimeout(() => {
                this._DeliverySearch_Request.call(this._ThisContext, Utilities.DeliveryType.PostCode);
            }, postCode.value.length >= postCode.minLength ? 0 : this._DeliverySearch_KeyUpDelay);
        }
    }

    private PostCode_Focus(e: Event) {
        const postCode = e.target as HTMLInputElement;

        if (!postCode.classList.contains("has-text"))
            postCode.classList.add("has-text");

        postCode.select();
    }

    private PostCode_Focusout(e: Event) {
        const postCode = e.target as HTMLInputElement;

        if (!postCode.value.trim())// {
            postCode.classList.remove("has-text");
        //    this._SetDeliveryValid.call(this._ThisContext);
        //}
        //else
        this._DeliverySearch_Request.call(this._ThisContext, Utilities.DeliveryType.PostCode);
    }
}