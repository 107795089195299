class Form {
    private static _instance: Form;
    private static _context = document.querySelectorAll("form");

    private constructor() {
        Form._context.forEach(form => {
            form.querySelectorAll(".password-container").forEach(cont => {
                const toggle = cont.querySelector(".password-toggle") as HTMLElement;
                if (toggle) {
                    const input = cont.querySelector("input");
                    if (!input)
                        return;

                    input.addEventListener("keyup", () => this.Password_KeyPress(input, toggle));
                    toggle.addEventListener("click", () => this.PasswordToggle_Click(input, toggle));
                }
            });
        });
    }

    private Password_KeyPress(input: HTMLInputElement, toggle: HTMLElement) {
        toggle.classList.toggle("fade-in", input.value.length > 0);
    }

    private PasswordToggle_Click(input: HTMLInputElement, toggle: HTMLElement) {
        toggle.classList.toggle("fa-eye-slash");
        input.setAttribute("type", input.getAttribute("type") == "password" ? "text" : "password");
    }

    public static get Instance() {
        return this._context.length && (this._instance || (this._instance = new this()));
    }
}
export const form = Form.Instance;