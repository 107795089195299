import * as Variables from "./variables";

class Ekomi {
    private static _instance: Ekomi;
    private _context: HTMLElement;
    private _leeway = 20;
    private _prevScrollTop: number;

    public Initialise = (() => {
        //addEventListener("scroll", this.Window_Scroll);
    }).bind(this);

    private Window_Scroll() {
        const context = document.querySelector("#widget-container .pull-left");
        if (!context)
            return;

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const diff = scrollTop - this._prevScrollTop;

        if (diff > this._leeway) {
            this._context.classList.add("hide");
        }
        else if (diff <= -this._prevScrollTop) {
            this._context.classList.remove("hide");
        }
        this._prevScrollTop = Math.max(0, scrollTop);
    }

    public static get Instance() {
        return Variables.mobile.matches && (this._instance || (this._instance = new this()));
    }
}
export let ekomi: Ekomi;
if (!(window as any).ekomi)
    ekomi = Ekomi.Instance;

if (ekomi) {
    (window as any).ekomi = ekomi;
}