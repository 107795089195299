﻿import { gtmNewsletter_Error, gtmNewsletter_Success } from "./cs.gtm";
import { initialisePhoneItems } from "./cs.phone";

let popUpModalId = " .dynamic-popup-modal.modal ";
var htmlWaitingArea = "<div class='waiting-area'><div id='ajaxBusyIconWaiting'><img src='/Content/images/ajax-loader-white.gif' /> </div></div>";

let newsletterFooterId = " #subscribefooter-modal ";
let discountPopUpModalWaitingAreaFooter = newsletterFooterId + " .waiting-area";
let discountPopUpObjectModalButtonIdFooter = newsletterFooterId + " .submit-form-btn";
let discountPopUpObjectModalMessageAreaFooter = newsletterFooterId + " .message";
let successText = newsletterFooterId + " .successtext";
let footernewsletterformId = newsletterFooterId + " form";


$(function () {
    InitNewsletterFooter();
});

function InitNewsletterFooter() {
    let footernewsletterform = $(footernewsletterformId);
    if ($(footernewsletterform).length > 0) {
        $.validator.unobtrusive.parse(footernewsletterform);
        $(discountPopUpObjectModalButtonIdFooter).bind("click", SubmitSubscribeFormFooter);
    }

}

function SubmitSubscribeFormFooter() {
    let footernewsletterform = $(footernewsletterformId);
    let url = $(footernewsletterform).attr("action");
    if ($(footernewsletterform).valid()) {
        $(discountPopUpObjectModalButtonIdFooter).hide();
        disableAjaxBusySettings();
        loadingAreas(discountPopUpModalWaitingAreaFooter);
        let hiddenShop = $("input[name=static-page-newsletter-shop]");
        let shopQueryString = "";
        if ($(hiddenShop).length > 0) {
            shopQueryString = "&Shop=" + $(hiddenShop).val();
        }
        $.post(url, $(footernewsletterform).serialize() + shopQueryString,
           function (data) {
               removeloadingAreas(discountPopUpModalWaitingAreaFooter);
               if (data.StatusAsString == "Successful") {
                   $(discountPopUpObjectModalButtonIdFooter).text($(successText).text());
                   $(discountPopUpObjectModalButtonIdFooter).show();
                   $(discountPopUpObjectModalButtonIdFooter).attr("disabled", true);
                   
                   if (typeof data.ReponseStatus != null && typeof data.ReponseStatus.Object != null)
                       gtmNewsletter_Success(data.ReponseStatus.Object);
               }
               createAjaxBusySettings();
               $(discountPopUpObjectModalButtonIdFooter).show();
           });
    }
    return false;
}
function loadingAreas(area) {
    $(area).html(htmlWaitingArea);
}
function removeloadingAreas(area) {
    $(area).html("");
}
function ShowSubmitPopUpMessage(message) {
    $(discountPopUpObjectModalButtonIdFooter).hide();
    $(discountPopUpObjectModalMessageAreaFooter).removeClass("hidden");
    $(discountPopUpObjectModalMessageAreaFooter).html(message);
    setTimeout(HideSubmitPopUpMessage, 3000);
}
function HideSubmitPopUpMessage() {
    $(discountPopUpObjectModalMessageAreaFooter).addClass("hidden");
    $(discountPopUpObjectModalButtonIdFooter).show();
}

let newsletterPopUpHolder = "body";
let newsletterSectionId = " #newsletter-modal ";
let newsletterFormSelector = newsletterSectionId + " form";
let newsletterFormSubmitDetailsButtonSelector = ".newsletter-form-submit-details-btn";
var htmlWaitingArea = "<div class='waiting-area'><div id='ajaxBusyIconWaiting'><img src='/Content/images/ajax-loader-white.gif' /> </div></div>";
let newsletterButtonsDiv = newsletterSectionId + " .submit-section .buttons";
let newsletterFormMessageDivSelector = newsletterSectionId + " .submit-section div.message";
let newsletterStatusSections = ".newsletter-status-section";

function showNewsletterPopUp() {
    //check if newsletter is found
    if (!$(newsletterSectionId).length) {
        //download the html
        populateNewsletterPopUp();
    }
    else {
        $(newsletterSectionId).modal("show");
    }
    return false;
}
function showAssemblyPopUp() {
    ShowMontajeModalPopUp();
    return false;
}
function populateNewsletterPopUp() {
    $.post("/Module/NewsletterHtml",
        function (data) {
            $(newsletterPopUpHolder).prepend(data.Object);
            $(newsletterSectionId).modal();
            showNewsletterPopUpContainer();
            $(newsletterSectionId).on("hidden.bs.modal", function () {
                closeNewsletterPopUpContainer();
            });
            initialisePhoneItems();
            let form = $(newsletterFormSelector);
            form.removeData("validator");
            form.removeData("unobtrusiveValidation");
            $.validator.unobtrusive.parse(form);
            $(newsletterFormSubmitDetailsButtonSelector).bind("click", submitNewsletterPopupForm);
        });
}

function submitNewsletterPopupForm(event) {
    let form = $(newsletterFormSelector);
    form.validate();
    if (form.valid()) {
        let action = form.attr("action");
        $(newsletterButtonsDiv).hide();
        $(newsletterFormMessageDivSelector).html(htmlWaitingArea);
        $(newsletterFormMessageDivSelector).show();
        $.post(action, form.serialize(), function (data) {
            setTimeout(function () {
                $(newsletterFormMessageDivSelector).html("");
                $(newsletterStatusSections).hide();
                if (data.StatusAsString == "Successful") {
                    $(".newsletter-detail").hide();
                    $(".newsletter-success").show();
                    var top = $(".newsletter-success").position().top;
                    gtmNewsletter_Success(data.EmailId);
                    $(window).scrollTop(top);
                }
                else {
                    $(".newsletter-failed [data-failed-message]").html(data.ReponseStatus.Message);
                    $(".newsletter-detail").hide();
                    $(".newsletter-failed").show();
                    var top = $(".newsletter-failed").position().top;
                    gtmNewsletter_Error(data.ReponseStatus.Object);
                    $(window).scrollTop(top);
                }
            }, 2000);
        })
        .fail(function () {
            $(".newsletter-detail").hide();
            $(newsletterFormMessageDivSelector).html("");
            $(newsletterStatusSections).hide();
            $(".newsletter-failed").show();

            let top = $(".newsletter-failed").position().top;
            $(window).scrollTop(top);
        });
    }
    event.preventDefault();
    return false;
}

function showNewsletterPopUpContainer() {

    $(newsletterSectionId).modal("show");
    $(newsletterSectionId + " input[name*='IsNewsletterPopupFormValidatable']").val(true);
}

function closeNewsletterPopUpContainer() {
    $(newsletterButtonsDiv).show();
    $(newsletterStatusSections).hide();
    $(newsletterSectionId + " input[name*='IsNewsletterPopupFormValidatable']").val(false);
    $(".newsletter-detail").show();
    return false;
}

export {
    showNewsletterPopUp
};