export enum Device {
    Mobile = 1,
    Desktop
}

export enum Screen {
    xxs,
    xs,
    sm,
    md,
    lg,
    xl
}

export enum ScreenBoundary {
    upper,
    lower
}

export enum DeliveryMode {
    Home = "HOME",
    Pickup = "PICKUP"
}

export enum PickupType {
    Default = "Default",
    Drive = "Drive"
}

export enum Modal {
    ClickAndDrive = "modal-clickanddrive",
    PostalCode = "modal-postalcode",
    AvailabilityWarning = "modal-availabilitywarning"
}

export enum Extension {
    Webp,
    Jpeg,
    Png
}

export enum Address {
    Billing,
    Delivery
}