import * as Utilities from "./utilities";
import { GtmAnalytics } from "./gtm";

class Newsletter {
    private static _instance: Newsletter;
    private static _context = document.querySelectorAll(".form-newsletter") as NodeListOf<HTMLFormElement>;

    private constructor() {
        Newsletter._context.forEach((newsletter) => new this._newsletter(newsletter));
    }

    private _newsletter = class {
        private readonly _context: HTMLFormElement;
        private readonly _email: HTMLInputElement;
        private readonly _name: HTMLInputElement;
        private readonly _surname: HTMLInputElement;
        private readonly _privacyPolicy: HTMLInputElement;
        private readonly _submit: HTMLButtonElement;
        private _attemptedSubmit: boolean;

        constructor(newsletter: HTMLFormElement) {
            this._context = newsletter;
            this._email = newsletter.querySelector(".email > input");
            this._name = newsletter.querySelector(".name > label > input[name='Name']");
            this._surname = newsletter.querySelector(".name > label > input[name='Surname']");
            this._privacyPolicy = newsletter.querySelector(".checkbox > input");
            this._submit = newsletter.querySelector(":scope > button");

            this._email.addEventListener("focusout", () => this.Email_CheckValidity(true));
            this._submit.addEventListener("click", (e) => this.Newsletter_Request(e));
        }

        private Email_CheckValidity(ignoreValueMissing = false) {
            const container = this._email.parentElement;

            if (this._email.checkValidity() ||
                (this._email.validity.valueMissing && ignoreValueMissing && !this._attemptedSubmit)) {

                this.SetValid(container);
                return 1;
            }

            let state = undefined;
            if (this._email.validity.valueMissing)
                state = Utilities.ValidityStates.Valuemissing;

            this.SetInvalid(container, state);
            return 0;
        }

        private Name_CheckValidity() {
            if (this._name === null) {
                return 1;
            }

            const container = this._name.parentElement;

            // Added null check not to cause problems with deployment
            if (this._name.checkValidity()) {

                this.SetValid(container);
                return 1;
            }

            let state = undefined;
            if (this._name.validity.valueMissing)
                state = Utilities.ValidityStates.Valuemissing;

            this.SetInvalid(container, state);
            return 0;
        }

        private Surname_CheckValidity() {
            if (this._surname === null) {
                return 1;
            }
            const container = this._surname.parentElement;

            // Added null check not to cause problems with deployment
            if (this._surname.checkValidity()) {

                this.SetValid(container);
                return 1;
            }

            let state = undefined;
            if (this._surname.validity.valueMissing)
                state = Utilities.ValidityStates.Valuemissing;

            this.SetInvalid(container, state);
            return 0;
        }

        private PrivacyPolicy_CheckValidity() {
            const container = this._privacyPolicy.parentElement;

            if (this._privacyPolicy.checkValidity()) {

                this.SetValid(container);
                return 1;
            }

            let state = undefined;
            if (this._privacyPolicy.validity.valueMissing)
                state = Utilities.ValidityStates.Valuemissing;

            this.SetInvalid(container, state);

            return 0;
        }

        private Newsletter_Request(e: Event) {
            e.preventDefault();
            this._attemptedSubmit = true;

            if (!(this.Email_CheckValidity() &
                this.Name_CheckValidity() &
                this.Surname_CheckValidity() &
                this.PrivacyPolicy_CheckValidity()))
                return;

            const form = new FormData(this._context);
            form.set(this._privacyPolicy.name, this._privacyPolicy.checked.toString());

            Utilities.Fetch({
                url: "/module/discountpopupmoduleformsubmit",
                method: Utilities.FetchMethod.POST,
                success: (e) => this.Newsletter_Response(JSON.parse((e.target as XMLHttpRequest).response)),
                body: form,
                showBusy: true
            });
        }

        private Newsletter_Response(res: any) {
            if (res.StatusAsString !== "Successful") {
                this._context.dataset.status = res.ReponseStatus.Message;
                return;
            }

            this._context.classList.add("success");
            this._context.dataset.status = res.ReponseStatus.Message;
            GtmAnalytics.Instance.gtmNewsletter_Success(res.ReponseStatus.Object);
        }

        private SetInvalid(container: HTMLElement, state: Utilities.ValidityStates = Utilities.ValidityStates.Invalid, message?: string) {
            container.classList.add("error");
            delete this._context.dataset.status;

            if (!message && state == Utilities.ValidityStates.Valuemissing)
                message = Utilities.DataListObj.ValidationMessage.Valuemissing;

            if (!message)
                message = container.dataset[`validity${Utilities.ValidityStates[state]}`];

            if (message) {
                const validation = container.querySelector(".validation");
                if (validation)
                    validation.innerHTML = message;
            }
        }

        private SetValid(container: HTMLElement) {
            container.classList.remove("error");
            delete this._context.dataset.status;
            //this._deliveryTitle.innerHTML = this._deliveryTitle.dataset.title;
        }
    };

    public static get Instance() {
        return this._context.length && (this._instance || (this._instance = new this()));
    }
}
export const newsletter = Newsletter.Instance;