import { miniCart } from "./mini-cartV2";

declare function requestIdleCallback(...args: any[]): void;

class PageMiniCart {
    private static _instance: PageMiniCart;
    private static readonly _context: HTMLElement = document.getElementById("page-mini-cart");

    private constructor() {
        PageMiniCart._context.appendChild(miniCart.Context);
        miniCart.BusyContainer = undefined;

        if (typeof requestIdleCallback !== "function")
            miniCart.MiniCart_Request();
    }

    public static get Instance() {
        return this._context && miniCart && (this._instance || (this._instance = new this()));
    }
}
export const pageMiniCart = PageMiniCart.Instance;