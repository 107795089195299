export class App4Less {
    private static _instance: App4Less;
    private static readonly _context = document.querySelector("html.app4less") as HTMLElement;

    constructor() {
        // Sync basket count
        let lastVal = 0;
        setInterval(function () {
            const items = parseInt(sessionStorage.getItem("BasketCount"));
            if (lastVal != items) {
                top.postMessage("cart-" + items, "*");
                lastVal = items;
            }
        }, 1000);

        // Login remember me always checked
        const rememberMe = document.querySelector(".page-login .remember-me") as HTMLElement;
        if (rememberMe) {
            const checkbox = rememberMe.querySelector("input[type=checkbox]") as HTMLInputElement;
            checkbox.checked = true;
            rememberMe.style.display = "none";
        }
    }

    public ToggleBusy(force: boolean) {
        if (force)
            top.postMessage("loader-show", "*");
        else
            top.postMessage("loader-hide", "*");
    }

    public ScrollToTop() {
        top.postMessage("top", "*");
    }

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}
export const app4Less = App4Less.Instance;