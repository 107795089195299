import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";

class VerticalMenu {
	private static _instance: VerticalMenu;
	private static readonly _context: HTMLElement = document.getElementById("vert-menu");
	protected readonly _container: HTMLElement = VerticalMenu._context.querySelector(".vert-menu__container");
	protected _busyContainer = this._container;
	private readonly _statusMessage = VerticalMenu._context.querySelector(".status-message");
	private _startX: number;
	private _currentX: number;
	private _touching: boolean;
	private _touchingThreshold: number = this._container.clientWidth / 3;
	private readonly _left: HTMLElement = this._container.querySelector(".top-level-menu");
	private readonly _right: HTMLElement = this._container.querySelector(".menu-items");
	private _menuItemsContainer: HTMLElement = this._container.querySelector(".menu-items__container");
	private _mobileInitialised: boolean;
	private _desktopInitialised: boolean;
	private _verticalMenuLoaded: boolean;
	private readonly _hoverSensitivityMs = 50;
	private _hoverTimeoutHandle: number;
	private readonly _screen_Xs_Upper = Variables.screen(Enums.Screen.xs, Enums.ScreenBoundary.upper);

	private constructor() {
		VerticalMenu._context.addEventListener("pointerdown", () => this.Hide());
		this._container.addEventListener("pointerdown", (e) => e.stopPropagation());
		this._container.querySelectorAll(".close").forEach(close => close.addEventListener("click", () => this.Hide()));

		this._screen_Xs_Upper.addListener((e) => this.Mobile_Change(e.matches));
		this.Mobile_Change(this._screen_Xs_Upper.matches);
	}

	private Mobile_Change(mobile: boolean) {
		Utilities.ToggleEventListener(document, "touchstart", this.VerticalMenu_TouchStart, !mobile);
		Utilities.ToggleEventListener(document, "touchmove", this.VerticalMenu_TouchMove, !mobile);
		Utilities.ToggleEventListener(document, "touchend", this.VerticalMenu_TouchEnd, !mobile);

		if (this._verticalMenuLoaded)
			this.Initialise(mobile);
	}

	private MenuItem_Click = ((e: Event) => {
		const anchor = e.currentTarget as HTMLElement;
		const menuItem = anchor.parentElement;

		const subMenu = menuItem.querySelector(":scope > ul");
		if (!subMenu)
			return;

		e.preventDefault();

		this.ToggleMenuItem(menuItem);
	});

	private ToggleMenuItem(menuItem: HTMLElement) {
		if (menuItem.classList.contains("show")) {
			menuItem.classList.remove("show");

			// Collapse sub-menu items
			menuItem.querySelectorAll(".show").forEach(_ =>
				_.classList.remove("show"));
			return;
		}

		menuItem.parentElement.querySelectorAll(":scope > .show").forEach(_ =>
			_.classList.remove("show"));
		menuItem.classList.add("show");
	}

	private IsSuccess(res: any) {
		return (res.Status || res.StatusAsString) === "Successful";
	}

	private VerticalMenu_TouchStart = ((e: TouchEvent) => {
		if (!VerticalMenu._context.classList.contains("show"))
			return;

		this._startX = e.touches[0].pageX;
		this._currentX = this._startX;

		this._touching = true;
		requestAnimationFrame(this.Update);
	}).bind(this);

	private VerticalMenu_TouchMove = ((e: TouchEvent) => {
		if (!this._touching)
			return;

		this._currentX = e.touches[0].pageX;

		e.preventDefault();
	}).bind(this);

	private VerticalMenu_TouchEnd = (() => {
		if (!this._touching)
			return;

		this._touching = false;

		const translateX = Math.min(0, this._currentX - this._startX);
		this._container.style.transform = "";

		if (Math.abs(translateX) > this._touchingThreshold)
			this.Hide();
	}).bind(this);

	private Update = (() => {
		if (!this._touching)
			return;

		requestAnimationFrame(this.Update);

		const translateX = Math.min(0, this._currentX - this._startX);
		this._container.style.transform = `translateX(${translateX}px`;
	}).bind(this);

	public Show = () => this.Toggle(true);

	public Hide = () => this.Toggle(false);

	public Toggle(show?: boolean) {
		VerticalMenu._context.classList.toggle("show", show);
		document.documentElement.classList.toggle("noscroll", show);

		if (show) {
			if (!this._verticalMenuLoaded)
				this.VerticalMenu_Request();
			else
				this.Initialise(this._screen_Xs_Upper.matches);
		}
		else {
			(document.activeElement as HTMLElement).blur();
		}
	}

	public VerticalMenu_Request() {
		Utilities.Fetch({
			url: "/layout/loadverticalmenu",
			success: (e) => this.VerticalMenu_Response(JSON.parse((e.target as XMLHttpRequest).response)),
			headers: { "Content-Type": "application/json;charset=UTF-8" },
			showBusy: true,
			busyContainer: this._container as HTMLElement
		});
	}

	public VerticalMenu_Response(res: any) {
		if (!this.IsSuccess(res)) {
			this._statusMessage.innerHTML = res.StatusMessage;
			return;
		}

		this._statusMessage.innerHTML = "";

		this._menuItemsContainer = Utilities.ReplaceInnerHTML(this._menuItemsContainer, res.Html);

		this.Initialise(this._screen_Xs_Upper.matches);

		this._verticalMenuLoaded = true;
	}

	private Initialise(mobile: boolean) {
		mobile ? this.InitialiseMobile() : this.InitialiseDesktop();
	}

	private InitialiseDesktop() {
		if (this._desktopInitialised)
			return;

		const itemLvl1 = [...this._menuItemsContainer.querySelectorAll(":scope > li > a")] as Array<HTMLElement>;
		const topLvlMenuItems = itemLvl1.map(_ => _.cloneNode(true) as HTMLElement);
		this._left.prepend(...topLvlMenuItems);

		// Show first top menu by default
		if (topLvlMenuItems.length > 0) {
			const firstTopLvlMenuItem = topLvlMenuItems[0];
			const lvl1 = this.GetLvl1MenuItem(firstTopLvlMenuItem.dataset.id);
			this.ShowSubMenu(firstTopLvlMenuItem, lvl1);
		}

		topLvlMenuItems.forEach(_ => _.addEventListener("touchend", this.TopMenuItem_TouchEnd));
		topLvlMenuItems.forEach(_ => _.addEventListener("mouseenter", this.TopMenuItem_MouseEnter));

		this._desktopInitialised = true;
	}

	private InitialiseMobile() {
		if (this._mobileInitialised)
			return;

		this._menuItemsContainer.querySelectorAll("li > a").forEach(_ =>
			_.addEventListener("click", this.MenuItem_Click));

		const viewAllText = this._menuItemsContainer.dataset.viewAll;
		this._menuItemsContainer.querySelectorAll("li > a[href] ~ ul").forEach(subMenu => {
			const viewAllLink = subMenu.parentElement.querySelector(":scope > a").cloneNode(false);
			viewAllLink.textContent = viewAllText;

			const viewAll = document.createElement("li");
			viewAll.appendChild(viewAllLink);
			viewAll.classList.add("view-all");
			delete viewAll.dataset.id;
			subMenu.insertBefore(viewAll, subMenu.firstChild);
		});

		this._mobileInitialised = true;
	}

	private TopMenuItem_TouchEnd = ((e: Event) => {
		const item = e.currentTarget as HTMLElement;

		const lvl1 = this.GetLvl1MenuItem(item.dataset.id);
		if (lvl1.classList.contains("show"))
			return;

		e.preventDefault();

		this.ShowSubMenu(item, lvl1);

		item.focus();
	}).bind(this);

	private TopMenuItem_MouseEnter = ((e: Event) => {
		const item = e.currentTarget as HTMLElement;

		window.clearTimeout(this._hoverTimeoutHandle);

		const lvl1 = this.GetLvl1MenuItem(item.dataset.id);
		if (lvl1.classList.contains("show"))
			return;

		this._hoverTimeoutHandle = window.setTimeout(() =>
			this.ShowSubMenu(item, lvl1), this._hoverSensitivityMs);
	}).bind(this);

	private ShowSubMenu(topLvlMenuItem: HTMLElement, subMenu: HTMLElement) {
		if (subMenu.classList.contains("show"))
			return;

		topLvlMenuItem.parentElement.querySelectorAll(":scope > .selected").forEach(_ => _.classList.remove("selected"));
		this._menuItemsContainer.querySelectorAll(":scope > .show").forEach(_ => _.classList.remove("show", "fade"));
		topLvlMenuItem.classList.add("selected");
		subMenu.classList.add("show");
		requestAnimationFrame(_ => subMenu.classList.add("fade"));
	}

	private GetLvl1MenuItem(topMenuId: string) {
		return this._menuItemsContainer.querySelector(`:scope > li > a[data-id='${topMenuId}']`).parentElement;
	}

	public get Context() {
		return VerticalMenu._context;
	}

	public set BusyContainer(container: HTMLElement) {
		this._busyContainer = container;
	}

	public static get Instance() {
		if (!this._context)
			return;

		if (!this._instance && (window as any).verticalMenu)
			this._instance = (window as any).verticalMenu;

		return this._instance || (this._instance = new this());
	}
}

export let verticalMenu = (window as any).verticalMenu;
if (!verticalMenu) {
	verticalMenu = VerticalMenu.Instance;
	if (verticalMenu) {
		(window as any).verticalMenu = verticalMenu;
	}
}