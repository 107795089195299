import { modal } from "./modal";
import * as Enums from "./_enumerations";
import { PostCode } from "./post-code";
import * as Utilities from "./utilities";

export class ModalPostalCode {
    private static _instance: ModalPostalCode;
    private _postalcodetemplate: HTMLElement;
    protected _postCode: HTMLInputElement;
    protected readonly _delivery: HTMLElement;
    protected readonly _deliveryTitle: HTMLElement;

    private constructor() {
        const params = new URLSearchParams(window.location.search.toLowerCase());
        const modalPostCode = params.get("modalpostcode");
        if (modalPostCode === "true") {
            document.cookie = "modalPostCode=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        } else if (modalPostCode === "false") {
            document.cookie = "modalPostCode=closed;path=/";
        }
        this.CheckForPostCode();

        this._postCode = document.querySelector("#modal_postalcodeform .postcode-text > input");
        this._postalcodetemplate = document.querySelector("#modal-postalcode");
        this._delivery = document.querySelector("#modal_postalcodeform > .postcode ");
        this._deliveryTitle = document.querySelector("#modal_postalcodeform > .validation");

        if (document.querySelector("#modal_postalcodeform") != undefined) {
            this._postCode.blur();
            this._postCode.addEventListener("focus", () => { new PostCode(this._postCode, this, this.CheckInputValidity, this.SetDeliveryValid); });
            document.querySelector("#modal_postalcodeform").addEventListener("submit", function (e: any) {
                this.form_submit();
                e.stopPropagation();
                e.preventDefault();
                return false;
            }.bind(this));

            this._postalcodetemplate.querySelector(".modal__close").addEventListener("click", function () {
                document.cookie = "modalPostCode=closed;path=/";
            });
        }
    }

    private CheckInputValidity() {
        if (!this._postCode.checkValidity()) {
            let state = Utilities.ValidityStates.Patternmismatch;
            if (this._postCode.validity.tooShort)
                state = Utilities.ValidityStates.Tooshort;

            this.SetDeliveryInvalid(state);
            return;
        }
    }

    private form_submit = (() => {

        const body = {
            postalCode: undefined as string,
            shopCode: undefined as string
        };
        body.postalCode = this._postCode.value;

        Utilities.Fetch({
            url: "/offer/getshoplistforpostalcode",
            method: Utilities.FetchMethod.POST,
            success: (e) => this.PostalCode_Response(JSON.parse((e.target as XMLHttpRequest).response)),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify(body),
            showBusy: true,
        });

        return false;
    }).bind(this);

    private PostalCode_Response(res: any) {
        if (res.Status == "Successful") {
            modal.Close(Enums.Modal.PostalCode);
            window.location.reload();
        }
        else {
            this.SetDeliveryInvalid(Utilities.ValidityStates.Invalid, res.InvalidPostCode);
        }
        return false;
    }

    private SetDeliveryValid() {

    }

    private SetDeliveryInvalid(state: Utilities.ValidityStates, message?: string) {
        this._delivery.classList.add("error");
        message = message || this._delivery.dataset[`validity${Utilities.ValidityStates[state]}`];
        if (message)
            this._deliveryTitle.innerHTML = message;
    }

    private CheckForPostCode() {
        if (document.cookie.indexOf("InfPostalCode") == -1
            && (Utilities.DataListObj.PageType == "Category" || Utilities.DataListObj.PageType == "Home"
            || Utilities.DataListObj.PageType == "OfferDetail")
            && document.cookie.indexOf("modalPostCode") < 0) {
            modal.Show("modal-postalcode");
        }
    }

    public static get Instance() {
        return (this._instance || (this._instance = new this()));
    }
}

export let modalPostalCode = (window as any).modalPostalCode || ModalPostalCode.Instance ;
if (!(window as any).modalPostalCode)
    modalPostalCode = ModalPostalCode.Instance;

if (modalPostalCode) {
    (window as any).modalPostalCode = modalPostalCode;
}