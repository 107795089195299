declare let MicroModal: any;

class Modal {
    private static _instance: Modal;
    private readonly _defaultOptions = {
        awaitCloseAnimation: true,
        onShow: this.Modal_Show,
        onClose: this.Modal_Close
    };

    public Show(id: string, title?: string, options?: any) {
        if (typeof MicroModal === "undefined")
            return;

        const content = document.getElementById(id);
        if (!content)
            return;

        options = Object.assign({}, this._defaultOptions, options);

        if (content.classList.contains("modal")) {
            MicroModal.show(id, options);
            return;
        }

        let header = "";
        if (title)
            header = `
<header class="modal__header">
    <h2 class="modal__title" id="${id}-title">${title}</h2>
</header>`;

        const template = document.createElement("template");
        template.innerHTML = `
  <article class="modal micromodal-slide" id="${id}" aria-hidden="true">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="${id}-title">
        <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
        <div class="modal__content" id="${id}-content">
          ${header}
        </div>
      </div>
    </div>
  </article>`;
        const fragment = document.importNode(template.content, true);

        const contentFragment = content.tagName === "TEMPLATE" ?
            document.importNode((content as HTMLTemplateElement).content, true) :
            document.createDocumentFragment().appendChild(content.cloneNode(true));

        content.parentNode.removeChild(content);

        fragment.getElementById(`${id}-content`).appendChild(contentFragment);

        document.body.appendChild(fragment);
        MicroModal.show(id, options);
    }

    public Close(id: string) {
        MicroModal.close(id);
    }

    private Modal_Show() {
        document.documentElement.classList.add("noscroll");
    }

    private Modal_Close() {
        document.documentElement.classList.remove("noscroll");
    }

    public static get Instance() {
        return (this._instance || (this._instance = new this()));
    }
}
export const modal = (window as any).modal || Modal.Instance;
if (modal) {
    (window as any).modal = modal;
}