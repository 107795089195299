class Popup {
    private static _instance: Popup;
    private static _context = document.querySelectorAll(".popup");
    public _timerId: ReturnType<typeof setTimeout>;
    public _timerHandler: () => void;

    private constructor() {
        Popup._context.forEach((popup: HTMLElement) => {
            const trigger = popup.previousElementSibling as HTMLElement;

            if (trigger) {
                if (trigger.dataset.trigger == "click") {
                    trigger.addEventListener("click", (e) => this.Click(e, popup, trigger));
                    popup.addEventListener("transitionend", () => this.TransitionEnd(popup));
                    popup.addEventListener("focusout", () => this.Close(popup));
                } else {
                    trigger.addEventListener("mouseenter", () => this.MouseEnter(popup, trigger));
                    trigger.addEventListener("mouseleave", () => this.MouseLeave(popup, trigger));
                }
            }
            popup.addEventListener("click", (e) => e.preventDefault());
            const close = popup.querySelector(".btn-close");
            if (close)
                close.addEventListener("click", () => this.Close(popup));
        });
    }

    private Click(e: Event, popup: HTMLElement, trigger: HTMLElement) {
        if (popup.dataset.popupConditionClass && !trigger.classList.contains(popup.dataset.popupConditionClass))
            return;

        e.preventDefault();
        popup.classList.add("in");
    }

    private TransitionEnd(popup: HTMLElement) {
        popup.focus();
    }

    private MouseEnter(popup: HTMLElement, trigger: HTMLElement) {
        if (popup.dataset.popupConditionClass && !trigger.classList.contains(popup.dataset.popupConditionClass))
            return;

        this.ClearPendingTimers();

        popup.classList.add("in");
    }

    private MouseLeave(popup: HTMLElement, trigger: HTMLElement) {
        if (popup.dataset.popupConditionClass && !trigger.classList.contains(popup.dataset.popupConditionClass))
            return;

        this.ClearPendingTimers();

        this._timerHandler = this.Close.bind(this, popup);
        this._timerId = setTimeout(this._timerHandler, 3000);
    }

    private ClearPendingTimers() {
        if (this._timerHandler)
            this._timerHandler();
        clearTimeout(this._timerId);
    }

    public Close(popup: HTMLElement) {
        if (!popup)
            return;

        clearTimeout(this._timerId);
        this._timerHandler = undefined;

        popup.classList.remove("in");
    }

    public static get Instance() {
        return this._context.length && (this._instance || (this._instance = new this()));
    }
}
export const popup = Popup.Instance;