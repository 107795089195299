export { };

// 2024/04/29 - Implementing 'hide' / 'show' prototype methods will conflict with Bootstrap
// https://www.softec.lu/site/DevelopersCorner/BootstrapPrototypeConflict

declare global {
    interface Element {
        show_element(): void;
        hide_element(): void;
        toggle_element(): void;
    }
}

HTMLElement.prototype.show_element = function (this: Element) {
    (this as HTMLElement).style.display = "block";
};

HTMLElement.prototype.hide_element = function (this: Element) {
    (this as HTMLElement).style.display = "none";
};

HTMLElement.prototype.toggle_element = function (this: Element) {
    (this as HTMLElement).style.display = (this as HTMLElement).style.display === "none" ? "block" : "none";
};
