import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";

class Debug {
    private static _instance: Debug;
    private static _context: HTMLElement = document.querySelector(".debug");
    private _handle: HTMLElement = Debug._context.querySelector(":scope > header");
    private _body: HTMLElement = Debug._context.querySelector(":scope > div");
    private _copy: HTMLElement = this._handle.querySelector(".copy");
    private _draggableCont: AbortController;

    private constructor() {
        this.RemoveDebugQuery();

        const screen_Sm_Lower = Variables.screen(Enums.Screen.sm, Enums.ScreenBoundary.lower);
        screen_Sm_Lower.addListener((e) => this.Screen_Sm_Lower_Change(e.matches));
        this.Screen_Sm_Lower_Change(screen_Sm_Lower.matches);

        this._handle.querySelector(".close").addEventListener("click", this.Close);
        this._copy.addEventListener("click", this.Copy);
    }

    private Screen_Sm_Lower_Change(matches: boolean) {
        if (matches) {
            this._draggableCont = Utilities.SetDraggable(Debug._context, this._handle);
        }
        else {
            if (this._draggableCont)
                this._draggableCont.abort();
        }
    }

    private Close = (() => {
        Utilities.Fetch({
            url: "/admin/debug",
            method: Utilities.FetchMethod.POST,
            error: (e) => console.log(e),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                status: false
            }),
            showBusy: false
        });

        this.RemoveDebugQuery();

        Debug._context.classList.add("hide");
    }).bind(this);

    private Copy = (() => {
        delete this._body.dataset.status;

        navigator.clipboard.writeText(this._body.textContent.trim()).then(() => {
            this._copy.classList.add("success");
        }, (err) => {
            this._body.dataset.status = err;
        });
    }).bind(this);

    private RemoveDebugQuery() {
        const urlParams = new URLSearchParams(window.location.search.toLowerCase());
        urlParams.delete("debug");
        let url = `${window.location.pathname}?${urlParams.toString()}`;
        if (url.endsWith("?"))
            url = url.slice(0, -1);
        history.replaceState(undefined, "", url);
    }

    public static get Instance() {
        if (!this._context)
            return;

        if (!this._instance && (window as any).csDebug)
            this._instance = (window as any).csDebug;

        return this._instance || (this._instance = new this());
    }
}
const csDebug = Debug.Instance;
if (csDebug)
    (window as any).csDebug = csDebug;