import * as StackTrace from "stacktrace-js";
import * as Utilities from "./utilities";

if (Utilities.DataListObj.IsJavaScriptLoggingEnabled) {
	const errorHandler = async (event: ErrorEvent | PromiseRejectionEvent) => {
		try {
			let message: string, error: Error;
			if (event instanceof ErrorEvent) {
				message = event.message;
				error = event.error;
			}
			else if (event instanceof PromiseRejectionEvent) {
				message = event.reason.message;
				error = new Error(message);

				let stackWithoutError: string = event.reason.stack;
				const firstAtIndex = stackWithoutError.search(/(?:^at | at )/);
				if (firstAtIndex > -1)
					stackWithoutError = stackWithoutError.substr(firstAtIndex);
				error.stack = stackWithoutError;
			}

			let firstFileName, stackFrames;
			try {
				stackFrames = await StackTrace.fromError(error);
				firstFileName = stackFrames[0].fileName ?? "";
			}
			catch (ex)
			{
				// Filename is not provided in one of the stack frames
				if (ex instanceof TypeError && ex.message == "Given file name is not a String") {
					console.warn(ex);
					return;
				}
				throw ex;
			}

			// Only log *.conforama.* exceptions
			if (!firstFileName.startsWith("webpack://") &&
				!/\/\/.*(?:\.conforama\.)[^/]*\//.test(firstFileName))
				return;

			await Utilities.LogExceptionToAPI(
				message,
				message + "\n" +
				stackFrames.map(function (sf) {
					return sf.toString();
				}).join("\n")
			);
		}
		catch (ex) {
			console.error(ex);
		}
	};
	window.addEventListener("error", errorHandler);
	window.addEventListener("unhandledrejection", errorHandler);
}