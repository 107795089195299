import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import { modal } from "./modal";
import { PostCode } from "./post-code";
import { miniCart } from "./mini-cart";
import { Header } from "./headerV2";
import { MainMenu } from "./main-menu";
import { Footer } from "./footer";
import { ToTop, toTop } from "./to-top";
import { Timer } from "./timer";
import { app4Less } from "./app4Less";
import { contains } from "jquery";
declare function gtmAddToWishList(...args: any[]): void;
declare function GetFinancialForm(...args: any[]): void;
declare function RefreshCalculateLink(...args: any[]): void;
declare function refreshGtmBindings(...args: any[]): void;
declare function getGTMProductListing(...args: any[]): string;
declare function getGTMPosition(...args: any[]): number;
declare function gtmVirtualPageViewCart(...args: any[]): void;
declare let cart: any;
declare function Swiper(...args: any[]): void;
declare function lightGallery(...args: any[]): void;
declare function Drift(...args: any[]): void;

interface Image {
    ItemReference: string;
    UrlSuffix: string;
    Title: string;
}

interface Chrono {
    EndDateUTC: string;
    Name: string;
    Description: string;
}

interface Configurable {
    OfferId: string;
    ItemReference: string;
    PriceAfter: number;
    PriceDiscount: number;
    PriceBefore: string;
    IsAssemblySelected: boolean;
    IsRecoverySelected: boolean;
    IsWarrantySelected: boolean;
    AssemblyPrice: string;
    RecoveryPrice: string;
    WarrantyPrice: string;
    HasAssembly: boolean;
    HasRecovery: boolean;
    HasWarranty: boolean;
    TagChrono: Chrono;
}

interface OfferData {
    ItemReference: string;
    ProductReference: string;
    Title: string;
    SubFamily: string;
    Quantity: number;
    IsAssemblySelected: boolean;
    IsRecoverySelected: boolean;
    IsWarrantySelected: boolean;
    HighResImagePrefix: string;
    LargeImagePrefix: string;
    MediumImagePrefix: string;
    SmallImagePrefix: string;
    Configurables: Configurable[];
    CurrentConfigurable: Configurable;
    Images: Image[];
    CurrentImages: Image[];
    IsWebPEnabled: boolean;
}

class EntregaDomicilio {
    private static _instance: EntregaDomicilio;
    private _context: HTMLElement;
    private _scrollers: NodeListOf<Element>;
    private _handler: ReturnType<typeof setTimeout>;
    private _lastScrollLeft = 0;
    private _ticking = false;
    private readonly _threshold = 5;

    private constructor() {
        document.addEventListener("click", (e: Event) => {
            if (e.target instanceof Element) {
                const target = e.target.closest(".montaje-popup-link");
                if (target) {
                    e.preventDefault();
                    this.ShowModal();
                }
            }
        });
    }

    public Initialise() {
        this._context = document.getElementById("entrega-domicilio");
        if (!this._context)
            return;

        this._scrollers = this._context.querySelectorAll(".table-container > .table-scroller");

        const screen_Sm_Lower = Variables.screen(Enums.Screen.sm, Enums.ScreenBoundary.lower);
        screen_Sm_Lower.addListener((e) => this.Screen_Sm_Lower_Change(e.matches));
        this.Screen_Sm_Lower_Change(screen_Sm_Lower.matches);
    }

    public ShowModal() {
        new Promise<void>((resolve, reject) => {
            let miModal = document.body.querySelector("#modal-entregadomicilio");
            if (miModal) {
                resolve();
                return;
            }

            Utilities.Fetch({
                url: "/shoppingcart/getmontajepopup",
                success: (e) => {
                    this.Modal_Response(JSON.parse((e.target as XMLHttpRequest).response));
                    resolve();
                },
                error: (e) => reject(e)
            });
        })
            .then(this.Modal_Show)
            .catch((e: any) => {
                if (e instanceof Error)
                    console.error(e, e.stack);
            });
    }

    private Modal_Response = ((data: any) => {
        if (!data)
            return;

        document.body.insertAdjacentHTML("beforeend", data.Html);
    }).bind(this);

    private Modal_Show = (() => {
        modal.Show("modal-entregadomicilio");

        this.Initialise();
    }).bind(this);

    private Screen_Sm_Lower_Change(matches: boolean) {  
        if (matches) {
            window.removeEventListener("resize", this.Window_Resize);

            this._scrollers.forEach((scroller) => {
                const container = scroller.parentElement;

                scroller.removeEventListener("scroll", this.Scroller_Scroll);

                container.querySelectorAll(":scope > .scroll-btn").forEach((scrollBtn) => {
                    scrollBtn.removeEventListener("touchstart", this.Scroller_MouseDown);
                    scrollBtn.removeEventListener("touchend", this.Scroller_MouseUp);
                    scrollBtn.removeEventListener("contextmenu", this.Scroller_ContextMenu);
                });
            });
        }
        else {
            window.addEventListener("resize", this.Window_Resize, {
                passive: true
            });
            this.CheckScroller();

            this._scrollers.forEach((scroller) => {
                const container = scroller.parentElement;

                scroller.addEventListener("scroll", this.Scroller_Scroll);

                container.querySelectorAll(":scope > .scroll-btn").forEach((scrollBtn) => {
                    scrollBtn.addEventListener("touchstart", this.Scroller_MouseDown);
                    scrollBtn.addEventListener("touchend", this.Scroller_MouseUp);
                    scrollBtn.addEventListener("contextmenu", this.Scroller_ContextMenu);
                });
            });
        }
    }

    private Window_Resize = (Utilities.Throttle(() => {
        this.CheckScroller();
    }, 100)).bind(this);

    private Scroller_Scroll = ((e: Event) => {
        const scroller = e.currentTarget as HTMLElement;

        this._lastScrollLeft = scroller.scrollLeft;

        if (!this._ticking) {
            requestAnimationFrame(() => {
                this.UpdateScroller(this._lastScrollLeft, scroller);
                this._ticking = false;
            });

            this._ticking = true;
        }

    }).bind(this);

    private CheckScroller() {
        this._scrollers.forEach((scroller: HTMLElement) => {
            if (scroller.scrollWidth > scroller.clientWidth)
                this.UpdateScroller(scroller.scrollLeft, scroller);
            else
                scroller.classList.remove("scroller-left", "scroller-right");
        });
    }

    private UpdateScroller(scrollLeft: number, scroller: HTMLElement) {
        scroller.classList.toggle("scroller-left", scrollLeft > this._threshold);
        scroller.classList.toggle("scroller-right", scrollLeft < (scroller.scrollWidth - scroller.clientWidth - this._threshold));
    }

    private Scroller_MouseDown = ((e: Event) => {
        const scrollBtn = e.currentTarget as HTMLElement;
        const container = scrollBtn.parentElement;
        const scroller = container.querySelector(":scope > .table-scroller");

        let xPixels = 1;
        if (scrollBtn.classList.contains("btn-left"))
            xPixels = -xPixels;
        this._handler = setInterval(function () {
            scroller.scrollBy(xPixels, 0); // May need to be -1 to go down
        }, 0); // Play around with this number. May go too fast

        return false;
    }).bind(this);

    private Scroller_MouseUp = (() => {
        clearInterval(this._handler);
        return false;
    }).bind(this);

    private Scroller_ContextMenu = ((e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }).bind(this);

    public static get Instance() {
        return (this._instance || (this._instance = new this()));
    }
}
export let entregaDomicilio = (window as any).entregaDomicilio;
if (!entregaDomicilio) {
    entregaDomicilio = EntregaDomicilio.Instance;
    if (entregaDomicilio) {
        entregaDomicilio.Initialise();
        (window as any).entregaDomicilio = entregaDomicilio;
    }
}