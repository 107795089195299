import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import { CartBaseV2 } from "./cart-baseV2";
import { GtmAnalytics } from "./gtm";

declare function requestIdleCallback(...args: any[]): void;

class MiniCartV2 extends CartBaseV2 {
    private static _instance: MiniCartV2;
    private static readonly _context: HTMLElement = document.getElementById("mini-cartV2");
    protected readonly _container: HTMLElement = MiniCartV2._context.querySelector(".mini-cart__container");
    protected _busyContainer = this._container;
    protected _lines = this._container.querySelector(".lines-container");
    private readonly _footer = this._container.querySelector(":scope > footer");
    private readonly _statusMessage = MiniCartV2._context.querySelector(".status-message");
    protected readonly _selectList = this._container.querySelectorAll(".select");
    protected readonly _delivery: HTMLElement;
    protected readonly _deliveryTitle: HTMLElement;
    protected readonly _submitOrderPickupDrive: HTMLButtonElement;
    private _startX: number;
    private _currentX: number;
    private _touching: boolean;
    private _touchingThreshold: number = document.documentElement.clientWidth / 3;
    private readonly _update = this.Update.bind(this);
    protected RefreshCart = (): any => undefined;
    
    private _updateCartCalled: boolean;

    private _currentGAProducts: any[];
    private _currentSubtotalDecimal: any[];

    private constructor() {
        super();

        this.Init();

        MiniCartV2._context.addEventListener("mousedown", () => this.Hide());
        this._container.addEventListener("mousedown", (e) => e.stopPropagation());
        this._container.querySelectorAll(".close").forEach(close => close.addEventListener("click", () => this.Hide()));
        
        document.addEventListener("touchstart", (e) => this.MiniCart_TouchStart(e));
        document.addEventListener("touchmove", (e) => this.MiniCart_TouchMove(e));
        document.addEventListener("touchend", () => this.MiniCart_TouchEnd());

        // Commented due to Logistics perf improvement
        //if (typeof requestIdleCallback === "function")
        //    requestIdleCallback(() => {
        //        this.MiniCart_Request();
        //    });

        const screen_Md_Lower = Variables.screen(Enums.Screen.md, Enums.ScreenBoundary.lower);
        screen_Md_Lower.addListener((e) => this.Screen_Md_Lower_Change(e.matches));
        this.Screen_Md_Lower_Change(screen_Md_Lower.matches);
    }

    private Screen_Md_Lower_Change(matches: boolean) {
        this._lines.querySelectorAll(":scope > * > .quantityV2").forEach((quantity: HTMLElement) => {
            this.Quantity_Screen_Md_Lower_Matches(matches, quantity);
        });
    }

    get AddQuantityUrl() { return "/layout/step1addquantity"; }
    get DeleteItemUrl() { return "/layout/step1deleteitem"; }
    get ArticleMargin() { return 0; }
    get ParcelMargin() { return 0; }

    private InitialiseLines(offerId: string) {
        this.Resize_Changed();
        (Array.prototype.slice.call(this._lines.children) as Array<Node>).forEach((line) => {
            this.InitialiseLine(line as HTMLElement, offerId);
        });
    }

    private Resize_Changed() {
        this._container.classList.toggle("top-cont-buy", this._lines.children.length > 2);
        this._container.classList.toggle("has-scroll", this._lines.scrollHeight > this._lines.clientHeight);
    }

    protected SetDeliveryValid() {
        this._delivery.classList.remove("error");
        this._deliveryTitle.innerHTML = this._deliveryTitle.dataset.title || "";
    }

    private InitialiseLine(line: HTMLElement, offerId?: string) {
        (line.querySelector(":scope > .quantityV2") as HTMLElement).onclick = (e) => this.Quantity_Click(e, line);
        (line.querySelector(".delete-bin") as HTMLElement).onclick = (e) => {
            this.Delete_Click(e, line);
            this.Resize_Changed();
        };

        if (offerId && line.dataset.offerId == offerId) {
            this._lines.scrollTop = line.offsetTop;
            line.classList.add("added");
        }
    }

    private Quantity_Screen_Md_Lower_Matches(matches: boolean, quantity: HTMLElement) {
        const quantityInput = quantity.querySelector(":scope > input[name=quantity]") as HTMLInputElement;
        if (matches) {
            quantityInput.readOnly = true;
            quantityInput.removeEventListener("keyup", this.Quantity_KeyUp);
            quantityInput.removeEventListener("focusout", this.Quantity_FocusOut);
        }
        else {
            quantityInput.readOnly = false;
            quantityInput.addEventListener("keyup", this.Quantity_KeyUp);
            quantityInput.addEventListener("focusout", this.Quantity_FocusOut);
        }
    }

    public UpdateCartCount(count?: number): number {
        if (!isNaN(count))
            sessionStorage.setItem("BasketCount", count.toString());
        else
            count = parseInt(sessionStorage.getItem("BasketCount"));

        // HeaderV2.header was returning "cannot access uninitialised variable" in Safari.
        // Worked around this by using a direct function reference from "window".
        if ((window as any).updateCartHeaderTotal)
            (window as any).updateCartHeaderTotal(count);

        return count;
    }

    public UpdateCart(res: any, line?: HTMLElement, offerId?: string) {
        if (!this.IsSuccess(res)) {
            this._statusMessage.innerHTML = res.StatusMessage;
            return;
        }

        this._updateCartCalled = true;

        this._statusMessage.innerHTML = "";

        this.UpdateCartCount(res.ItemsCount);

        if (this.ToggleEmptyCart(!res.ItemsCount))
            return;

        this._footer.querySelector(".total > .price").textContent = res.SubTotal;

        if (res.LinesHtml || res.SingleLineHtml) {
            if (res.SingleLineHtml && line) {
                // Refresh single line
                line = Utilities.ReplaceOuterHTML(line, res.SingleLineHtml);
                this.InitialiseLine(line, offerId);
            }
            else {
                // Refresh all lines
                this._lines = Utilities.ReplaceInnerHTML(this._lines, res.LinesHtml);
                this.InitialiseLines(offerId);
            }
        }

        GtmAnalytics.Instance.refreshGtmBindings();

        if (res.GAProducts && res.SubTotalDecimal) {
            this._currentGAProducts = res.GAProducts;
            this._currentSubtotalDecimal = res.SubTotalDecimal;
        }
    }

    protected ToggleEmptyCart(force: boolean): boolean {
        if (typeof force === "boolean") {
            if (force)
                this._container.classList.add("empty");
            else
                this._container.classList.remove("empty");

            return force;
        }

        return this._container.classList.toggle("empty", force);
    }

    private MiniCart_TouchStart(e: TouchEvent) {
        if (!MiniCartV2._context.classList.contains("show") || this._lines.contains((e.target as Node)))
            return;

        this._startX = e.touches[0].pageX;
        this._currentX = this._startX;

        this._touching = true;
        requestAnimationFrame(this._update);
    }

    private MiniCart_TouchMove(e: TouchEvent) {
        if (!this._touching)
            return;

        this._currentX = e.touches[0].pageX;

        e.preventDefault();
    }

    private MiniCart_TouchEnd() {
        if (!this._touching)
            return;

        this._touching = false;

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = "";

        if (translateX > this._touchingThreshold)
            this.Hide();
    }

    private Update() {
        if (!this._touching)
            return;

        requestAnimationFrame(this._update);

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = `translateX(${translateX}px`;
    }

    public Show = (res?: any, offerId?: string) => this.Toggle(true, res, offerId);

    public Hide = () => this.Toggle(false);

    public Toggle(show?: boolean, res?: any, offerId?: string) {
        MiniCartV2._context.classList.toggle("show", show);
        document.documentElement.classList.toggle("noscroll", show);

        if (show) {

            if (this._currentGAProducts && this._currentSubtotalDecimal)
                GtmAnalytics.Instance.gtmClickViewCart(this._currentGAProducts, this._currentSubtotalDecimal);

            if (!res && this._updateCartCalled)
                return;

            const cartContents = this._container.querySelector(":scope > article");
            if (cartContents)
                this._container.removeChild(cartContents);

            if (res) {
                this.UpdateCart(res, undefined, offerId);
                return;
            }

            this.MiniCart_Request();
        }
        else {
            (document.activeElement as HTMLElement).blur();
        }
    }

    public AddToCart(
        productReference: string,
        itemReference: string,
        quantity: number,
        listValue: string,
        position: number,
        isAssemblySelected: boolean,
        isRecoverySelected: boolean,
        isWarrantySelected: boolean,
        shopCode: string) {

        return new Promise<any>((resolve, reject) => {
            Utilities.Fetch({
                url: "/ShoppingCart/AddToBasket",
                method: Utilities.FetchMethod.POST,
                success: (e) => resolve(JSON.parse((e.target as XMLHttpRequest).response)),
                error: (e) => reject(e),
                headers: { "Content-Type": "application/json;charset=UTF-8" },
                body: JSON.stringify({
                    productReference: productReference,
                    itemReference: itemReference,
                    quantity: quantity,
                    marketingTagListValue: listValue,
                    marketingTagPosition: position,
                    isAssemblySelected: isAssemblySelected,
                    isRecoverySelected: isRecoverySelected,
                    isWarrantySelected: isWarrantySelected,
                    shopCode: shopCode
                }),
                showBusy: true
            });
        });
    }

    public MiniCart_Request() {
        const cartCount = this.UpdateCartCount();

        if (this.ToggleEmptyCart(!cartCount))
            return;

        Utilities.Fetch({
            url: "/layout/getbasketmodalv2",
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response)),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            showBusy: true,
            busyContainer: this._lines as HTMLElement
        });
    }

    public get Context() {
        return MiniCartV2._context;
    }

    public set BusyContainer(container: HTMLElement) {
        this._busyContainer = container;
    }

    public static get Instance() {
        if (!this._context)
            return;

        if (!this._instance && (window as any).miniCart)
            this._instance = (window as any).miniCart;

        return this._instance || (this._instance = new this());
    }
}

export let miniCart = (window as any).miniCart;
if (!miniCart) {
    miniCart = MiniCartV2.Instance;
    if (miniCart) {
        (window as any).miniCart = miniCart;
    }
}