import * as Variables from "../../../globalVariables";
import * as Enums from "./_enumerations";

const mediaList: { [s: string]: MediaQueryList; } = {};

export const mobile = window.matchMedia("(max-width:" + Variables.device.mobile.maxWidth + ")");
export const screen = (screen: Enums.Screen, screenBoundary?: Enums.ScreenBoundary) => {
    let upperDim, lowerDim;
    switch (screen) {
        case Enums.Screen.xxs:
            upperDim = Variables.media.screenXS;
            break;
        case Enums.Screen.xs:
            lowerDim = Variables.media.screenXS;
            upperDim = Variables.media.screenSM;
            break;
        case Enums.Screen.sm:
            lowerDim = Variables.media.screenSM;
            upperDim = Variables.media.screenMD;
            break;
        case Enums.Screen.md:
            lowerDim = Variables.media.screenMD;
            upperDim = Variables.media.screenLG;
            break;
        case Enums.Screen.lg:
            lowerDim = Variables.media.screenLG;
            upperDim = Variables.media.screenXL;
            break;
        case Enums.Screen.xl:
            lowerDim = Variables.media.screenXL;
            break;
    }

    let upper = "", lower = "";
    if (upperDim)
        upper = `and (max-width: ${upperDim})`;
    if (lowerDim)
        lower = `and (min-width: ${lowerDim})`;
    let media = "only screen ";
    switch (screenBoundary) {
        case Enums.ScreenBoundary.upper:
            media += upper;
            break;
        case Enums.ScreenBoundary.lower:
            media += lower;
            break;
        default:
            media += `${lower} ${upper}`;
    }

    // Cache media for performance
    return (mediaList[media] = mediaList[media] || window.matchMedia(media));
};
export const touch = window.matchMedia(Variables.media.touch).matches;
export const notTouch = window.matchMedia(Variables.media.notTouch).matches;
export const transition = Variables.transition;
export const mobileScreenWidth = parseInt(Variables.media.screenXS);
export const tabletTrustedShopsBadge = window.matchMedia(`only screen and (min-width: ${Variables.media.trustedShopsBadge}) and (max-width: ${Variables.media.screenMD})`);

(window as any).variables = {
    mobile: mobile
};