import * as Variables from "./variables";

(function Nosto() {
    const container_handler = (e: Event) => {
        const con = e.currentTarget as HTMLElement;
        const items = con.querySelectorAll(".nosto-item");
        if (items.length == 0)
            return;

        if (!Variables.touch) {
            items.forEach((item: HTMLElement) => {
                item.removeEventListener("mouseenter", AddNoTransition_MouseEnter);
                item.addEventListener("mouseenter", AddNoTransition_MouseEnter);
            });

            con.querySelectorAll(".slick-arrow").forEach((button) => {
                button.removeEventListener("mouseup", RemoveNoTransition);
                button.addEventListener("mouseup", RemoveNoTransition);
            });

            con.removeEventListener("mouseenter", container_handler);
        }
        else {
            const slider = con.querySelector(".slick-slider");
            items.forEach((item: HTMLElement) => {
                item.addEventListener("touchstart", AddNoTransition_MouseEnter);
            });
            $(slider)
                .on("touchmove" as any, RemoveNoTransition as any);

            con.removeEventListener("touchstart", container_handler);
        }
    };

    if (!Variables.touch) {
        document.querySelectorAll(".nosto_element").forEach(con =>
            con.addEventListener("mouseenter", container_handler)
        );
    }
    else {
        document.querySelectorAll(".nosto_element").forEach(con =>
            con.addEventListener("touchstart", container_handler)
        );
    }

    const Slick_Swipe = (_e: Event, slick: any) => {
        const items = (Array.prototype.slice.call(slick.$slides) as Array<HTMLElement>);

        items.forEach((item: HTMLElement) => {
            AddNoTransition(item);
        });
    };

    const AddNoTransition_MouseEnter = (e: Event) => {
        const item = e.currentTarget as HTMLElement;
        AddNoTransition(item);
    };

    function AddNoTransition(item: HTMLElement) {
        const slider = item.parentElement;

        const transform = slider.style.transform;
        const x = transform.substring("translate3d(".length, transform.indexOf("px") + 2);
        if (parseFloat(x) != 0)
            slider.style.marginLeft = x;
        slider.classList.add("notransition");
    }

    const RemoveNoTransition = (e: Event) => {
        const button = e.currentTarget as HTMLElement;

        const slider = button.parentElement.querySelector(".slick-track") as HTMLElement;
        const marginLeft = slider.style.marginLeft;
        slider.style.transform = `translate3d(${marginLeft}, 0, 0)`;
        slider.style.marginLeft = "";
        slider.classList.remove("notransition");

        (Array.prototype.slice.call(slider.children) as Array<HTMLElement>).forEach((item) => {
            const colorConf = item.querySelector(".configurables-colors");
            if (colorConf) {
                colorConf.classList.add("less");
                const trig = colorConf.querySelector(".read-more-trigger");
                if (trig)
                    trig.classList.remove("less");
            }

            const sizeConf = item.querySelector(".configurables-measures");
            if (sizeConf) {
                sizeConf.classList.remove("selected");
                sizeConf.querySelector(".select-value").classList.remove("selected");
            }
        });
    };
})();