import * as Utilities from "./utilities";
import * as gtm from "./gtm";
declare function __tcfapi(...args: any[]): void;

if (typeof __tcfapi !== "undefined") {
    __tcfapi("addEventListener", 2, function (tcData: any, success: any) {
        if (success && Utilities.DataListObj.RM71259_Enabled) {
            if (tcData.gdprApplies) {
                if (tcData.eventStatus === "useractioncomplete") {
                    // The user gave consent through the UI, get the tcdata string and
                    // do the ad request. The example here is for Google publisher tag.
                    // You may need to modify this code for usage in your web page.

                    let consents = tcData.purpose.consents;
                    let grantedCookies: string[] = [];

                    if (consents[1] === true) {
                        if (!grantedCookies.includes("personalization_storage"))
                            grantedCookies.push("personalization_storage");

                        if (!grantedCookies.includes("functionality_storage"))
                            grantedCookies.push("functionality_storage");
                    }

                    if ((consents[2] === true || consents[3] === true || consents[4] === true || consents[7] === true) && !grantedCookies.includes("ad_storage"))
                        grantedCookies.push("ad_storage");

                    if ((consents[5] === true || consents[6] === true) && !grantedCookies.includes("personalization_storage"))
                        grantedCookies.push("personalization_storage");

                    if ((consents[8] === true || consents[9] === true || consents[10] === true) && !grantedCookies.includes("analytics_storage"))
                        grantedCookies.push("analytics_storage");

                    gtm.GtmAnalytics.Instance.quantcastChoiceUpdateCookieTags(grantedCookies);
                }
                else if (tcData.eventStatus === "tcloaded") {
                    let gtagCookies = Utilities.readCookie("gtagGrantedCookies");

                    if (gtagCookies === null && typeof __tcfapi !== "undefined")
                        __tcfapi("displayConsentUi", 2, function () { });
                }
            }
        }
    });
}