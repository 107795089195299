import * as Utilities from "./utilities";
import { miniCart } from "./mini-cartV2";
declare let cart: any;

class OfferCard {
    private static _instance: OfferCard;
    private static _context = document.querySelectorAll(".offer-grid > article") as NodeListOf<HTMLElement>;
    private _parent: HTMLElement;

    private constructor() {
        this.Initialise();
    }

    public Initialise() {
        OfferCard._context = document.querySelectorAll(".offer-grid > article");
        if (!OfferCard._context.length)
            return;

        this._parent = OfferCard._context.item(0).parentElement;

        const selectPicker = this._parent.querySelectorAll(".selectpicker");
        if (selectPicker.length)
            (jQuery(selectPicker) as any).selectpicker();

        this.InitialiseCards(Array.prototype.slice.call(OfferCard._context));
    }

    public InitialiseCards(cards: Array<HTMLElement>) {

        cards.forEach((card) => {
            card.addEventListener("mouseenter", OfferCard.Card_MouseEnter);
            card.addEventListener("mouseleave", OfferCard.Card_MouseLeave);
            card.addEventListener("click", OfferCard.Card_Click);

            const tagList = card.querySelectorAll(".tag-list > li");
            tagList.forEach((tag) => {
                tag.addEventListener("click", (e) => e.preventDefault());
            });

            const configurables = card.querySelector(".configurables") as HTMLInputElement;
            if (configurables) {
                const selectValue = configurables.querySelector(".select-value") as HTMLElement;
                const selectContainer = configurables.querySelector(".select-container") as HTMLInputElement;
                const wrapper = card.querySelector(".product-container-wrapper");
                if (wrapper && configurables) {
                    wrapper.addEventListener("mouseleave", () => {
                        configurables.classList.remove("open");
                        if (selectValue)
                            selectValue.classList.remove("selected");
                        if (selectContainer)
                            selectContainer.style.maxHeight = "";
                    });
                }

                if (selectValue) {
                    const selectOptions = selectContainer.firstElementChild;

                    selectValue.addEventListener("click", (e) => {
                        const self = e.currentTarget as HTMLElement;

                        selectContainer.style.maxHeight = self.classList.toggle("selected") ? selectOptions.clientHeight + "px" : "";

                        e.preventDefault();
                    });

                    const selectOptionsInput = card.querySelectorAll(".select-options > input");
                    selectOptionsInput.forEach((selectOption) => {

                        this.SelectOption_Change(selectOption as HTMLInputElement, selectValue);
                        selectOption.addEventListener("change", (e) => {
                            this.SelectOption_Change(e.target as HTMLInputElement, selectValue);
                            this.ItemReference_Change(e.target as HTMLElement, card);
                        });
                    });
                }
            }

            const configurablesColors = card.querySelector(".configurables-colors") as HTMLInputElement;
            if (configurablesColors) {
                const colorImages = configurablesColors.querySelectorAll(":scope > img, .read-more-target > img, picture");
                colorImages.forEach((colorImage) => {

                    colorImage.addEventListener("click", (e) => {
                        e.preventDefault();
                        colorImages.forEach((val) => {
                            if (val === colorImage) {
                                val.classList.add("selected");
                                this.ItemReference_Change(colorImage as HTMLElement, card);
                            }
                            else
                                val.classList.remove("selected");
                        });
                    });
                });

                const readMoreTrigger = configurablesColors.querySelector(".read-more-trigger") as HTMLInputElement;
                if (readMoreTrigger) {
                    readMoreTrigger.addEventListener("click", (e) => {
                        e.preventDefault();
                        requestAnimationFrame(_ => {
                            const readMoreTarget = configurablesColors.querySelector(".read-more-target") as HTMLElement;
                            readMoreTarget.style.display = readMoreTarget.style.display ? "" : "block";
                            requestAnimationFrame(_ => configurablesColors.classList.toggle("open"));
                        });
                    });
                }
            }

            if ($(".btn-holder").length > 0) {
                card.querySelector(".btn-holder").addEventListener("click", (e) => {
                    const container = e.currentTarget as HTMLElement;
                    if (container == e.target) {
                        const button = container.querySelector(".btn-add-item-to-cart-from-card") as HTMLButtonElement;
                        button.click();
                        e.preventDefault();
                    }
                });
            }

            const addToCart = card.querySelector(".btn-add-to-cart") as HTMLButtonElement;
            addToCart.addEventListener("click", (e) => this.AddToCart_Click(e, card));
        });
    }

    private static Card_MouseLeave(e: Event) {
        const card = e.currentTarget as HTMLElement;

        card.style.minHeight = "";
        card.firstElementChild.classList.remove("hover");

        const configurablesColorsTarget = card.querySelector(".configurables-colors .read-more-target") as HTMLInputElement;
        if (configurablesColorsTarget)
            configurablesColorsTarget.style.display = "";
    }

    private static Card_MouseEnter(e: Event) {
        const card = e.currentTarget as HTMLElement;

        // Set height (assuming that the height will not change) so that when positioned absolute and in a single card column 
        // the following cards do not reposition to fill the 0 height.
        card.style.minHeight = card.getBoundingClientRect().height + "px";
        card.firstElementChild.classList.add("hover");
    }

    private static Card_Click(e: Event) {
        const card = e.currentTarget as HTMLElement;
        if (!card.dataset.itemreference)
            return;

        let newUrl = Utilities.updateQueryStringParameter(window.location.href, "item", card.dataset.itemreference);

        const pageBreak = Utilities.PreviousSibling(card, "[data-page]") as HTMLElement;
        if (pageBreak)
            newUrl = Utilities.updateQueryStringParameter(newUrl, "page", pageBreak.dataset.page);

        window.history.replaceState(undefined, "", newUrl);
        window.history.scrollRestoration = "manual";
    }

    private SelectOption_Change(selectOption: HTMLInputElement, selectValue: HTMLElement) {
        if (selectOption.checked) {
            selectValue.textContent = selectOption.nextElementSibling.textContent;
            selectValue.classList.remove("selected");
            selectOption.parentElement.parentElement.style.maxHeight = "";
        }
    }

    private ItemReference_Change(item: HTMLElement, card: HTMLElement) {

        const itemReference = item.dataset.itemreference;

        const prodListUrl = item.dataset.prodlistUrl;
        if (prodListUrl) {
            const img = (card.querySelector(".image-holder > img") as HTMLImageElement);
            if (img)
                img.src = prodListUrl;
            else {
                const picture = (card.querySelector(".image-holder > picture") as HTMLPictureElement);
                if (picture) {
                    (picture.querySelector(":scope > img") as HTMLImageElement).src = prodListUrl;
                    const webPSource = picture.querySelector(":scope > source[type='image/webp']") as HTMLSourceElement;
                    if (webPSource)
                        webPSource.srcset = prodListUrl.substr(0, prodListUrl.lastIndexOf(".")) + ".webp";
                }
            }
        }

        card.dataset.itemreference = itemReference;

        const addToCartItemRef = card.querySelector(".btn-holder input[type='hidden'][name='itemReference']") as HTMLInputElement;
        if (addToCartItemRef)
            addToCartItemRef.value = itemReference;

        const itemUrl = item.dataset.itemurl;
        if (itemUrl) {
            card.querySelectorAll("a").forEach((anchor: HTMLAnchorElement) => anchor.href = itemUrl);

            card.querySelector(".selling-price").textContent = item.dataset.itempriceafter;
            const discount = card.querySelector(".discount");
            const originalPrice = card.querySelector(".original-price");
            if (parseInt(item.dataset.itempricedisc) > 0) {
                if (discount) {
                    discount.textContent = item.dataset.itempricedisc + "%";
                }
                originalPrice.textContent = item.dataset.itempricebefore;
            }
            else {
                if (discount) {
                    discount.innerHTML = "&nbsp;";
                }
                originalPrice.textContent = "";
            }
        }

        let item_tags = item.dataset.tagsList;
        if (item_tags !== undefined) {
            let tags_list = item_tags.split(",");
            card.querySelectorAll(".tag-el").forEach((tag: HTMLElement) => {
                if (tags_list.includes(tag.dataset.tagId)) {
                    tag.classList.remove("hide");
                } else {
                    tag.classList.add("hide");
                }
            });
        }
    }

    private AddToCart_Click = ((e: Event, line: HTMLElement) => {
        e.preventDefault();

        const addToCart = e.currentTarget as HTMLButtonElement;
        const quantity = 1;

        addToCart.onclick = undefined;

        miniCart.AddToCart(
            line.dataset.productreference,
            line.dataset.itemreference,
            quantity,
            null,
            0,
            false,
            false,
            false,
            line.dataset.shopcode)
            .finally(() => {
                addToCart.onclick = (e) => this.AddToCart_Click(e, line);
            })
            .then((res: any) => {
                if (!this.IsSuccess(res)) {
                    //this._statusMessage.innerHTML = res.RenderedStatus;
                    return;
                }
                if (res.ShoppingCartResponse && typeof cart === "object") {
                    if (cart.UpdateCart)
                        cart.UpdateCart(res.ShoppingCartResponse);
                    else
                        parent.location.reload();

                    return;
                }

                if (res.SimpleCartResponse) {
                    miniCart.Show(res.SimpleCartResponse, line.dataset.offerId);
                }
            });
    }).bind(this);

    private IsSuccess(res: any) {
        return (res.Status || res.StatusAsString) === "Successful";
    }

    public static get Instance() {
        if (!this._context.length)
            return;

        if (!this._instance && (window as any).offerCard)
            this._instance = (window as any).offerCard;

        return this._instance || (this._instance = new this());
    }
}
export const offerCard = OfferCard.Instance;
if (offerCard) {
    (window as any).offerCard = offerCard;
}