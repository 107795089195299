import * as Utilities from "./utilities";
import { PostCode } from "./post-code";
import { GtmAnalytics } from "./gtm";
import { popup } from "./popup";
import { CSSelect, CSOption } from "./csSelect";

export abstract class CartBaseV2 {
    private _clickListener: any;
    protected _prevPostCode: string;
    protected _prevShopCode: string;

    protected abstract _lines: Element;
    protected abstract get AddQuantityUrl(): string;
    protected abstract get DeleteItemUrl(): string;
    protected abstract UpdateCart(res: any, line?: HTMLElement): void;
    protected abstract ToggleEmptyCart(force: boolean): boolean;
    protected abstract get ArticleMargin(): number;
    protected abstract get ParcelMargin(): number;
    protected abstract _selectList: NodeListOf<Element>;
    protected abstract _busyContainer: HTMLElement;
    protected abstract RefreshCart(): void;

    protected Init() {
    }

    protected Select_Click(select: HTMLElement, container: HTMLElement, force?: boolean) {
        const maxHeight = (container.firstElementChild.scrollHeight + 2) + "px";

        requestAnimationFrame(_ => {
            // Initialise max-height after reload with open services
            if (container.style.maxHeight == "none")
                container.style.maxHeight = maxHeight;

            requestAnimationFrame(_ => {
                container.style.maxHeight = select.classList.toggle("selected", force) ? maxHeight : "";
            });

        });
    }

    protected SelectOption_Click(item: HTMLElement, value: HTMLElement, options: Array<Node>): boolean {
        let changed = false;

        options.forEach((option: HTMLElement) => {
            if (option == item) {
                if (!option.classList.contains("selected")) {
                    option.classList.add("selected");
                    changed = true;
                }
                value.textContent = option.textContent;
            }
            else
                option.classList.remove("selected");
        });

        return changed;
    }

    protected Services_Request(type: string, checked: boolean, line: HTMLElement, quantity?: number, target?: HTMLElement) {
        if (!type)
            return;
        const itemReference = line.dataset.itemreference;
        if (!itemReference)
            return;
        const isGift = (line.dataset.isgift || "").toLowerCase() == "true";
        const shopCode = line.dataset.shopcode;

        const options: Utilities.FetchOptions = {
            url: "/shoppingcart/step1updateservice",
            method: Utilities.FetchMethod.POST,
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response), line),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                itemReference: itemReference,
                state: checked,
                isGift: isGift,
                type: type,
                quantity: quantity,
                shopCode: shopCode
            }),
            showBusy: true
        };
        if (target) {
            this._clickListener = target.onclick;
            target.onclick = undefined;
            options.always = () => { if (this._clickListener) target.onclick = this._clickListener; };
        }
        Utilities.Fetch(options);
    }

    protected Quantity_Click(e: Event, line: HTMLElement, fromServices = false) {
        const self = e.target as HTMLButtonElement;
        const currentTarget = e.currentTarget as HTMLElement;
        if (self == currentTarget)
            return;

        const maxQuantity = fromServices ? parseInt(line.dataset.quantity) : Utilities.DataListObj.MaxLineQuantity;

        const isMinus = self.classList.contains("minus");
        const isPlus = self.classList.contains("plus");
        if (!isMinus && !isPlus)
            return;
        const quantity = currentTarget.querySelector(":scope > input[name=quantity]") as HTMLInputElement;
        const other = (isMinus ? currentTarget.querySelector(":scope .plus") : currentTarget.querySelector(":scope .minus")) as HTMLButtonElement;
        let intQuantity = parseInt(quantity.value);

        if (isMinus) {
            intQuantity--;
            if (intQuantity < 1)
                return;
            if (intQuantity <= 1)
                self.disabled = true;
        }
        else {
            intQuantity++;
            GtmAnalytics.Instance.gtmClickAddItemToCart(self);
            if (intQuantity > maxQuantity)
                return;
            if (intQuantity >= maxQuantity)
                self.disabled = true;
        }
        other.disabled = false;

        quantity.value = intQuantity.toString();

        if (fromServices) {
            const checkbox = Utilities.PreviousSibling(currentTarget, "cs-checkbox").querySelector(":scope > label > input") as HTMLInputElement;
            if (checkbox.checked) {
                this._clickListener = currentTarget.onclick;
                currentTarget.onclick = undefined;

                this.Services_Request(checkbox.dataset.type, checkbox.checked, line, intQuantity, currentTarget);
            }
        }
        else {
            this._clickListener = currentTarget.onclick;
            currentTarget.onclick = undefined;

            this.AddQuantity_Request(intQuantity, line, currentTarget);
        }
    }

    protected Quantity_KeyUp = ((e: KeyboardEvent) => {
        const quantityInput = e.currentTarget as HTMLInputElement;
        const quantity = quantityInput.parentElement;
        const intQuantity = parseInt(quantityInput.value) || 1;
        const minus = quantity.querySelector(":scope > .minus") as HTMLButtonElement;
        const plus = quantity.querySelector(":scope > .plus") as HTMLButtonElement;

        if (intQuantity <= 1) {
            minus.disabled = true;
            plus.disabled = false;
        }
        else if (intQuantity >= Utilities.DataListObj.MaxLineQuantity) {
            plus.disabled = true;
            minus.disabled = false;
        }
        else {
            plus.disabled = false;
            minus.disabled = false;
        }

        if (e.key == "Enter") {
            quantityInput.blur();
            return;
        }

        if (!this.Quantity_CheckValidity(quantityInput))
            return;
    }).bind(this);

    protected Quantity_FocusOut = ((e: Event) => {
        const quantityInput = e.currentTarget as HTMLInputElement;
        const quantity = quantityInput.parentElement;
        const line = quantity.parentElement;

        if (quantityInput.value === "")
            quantityInput.value = "1";

        if (!this.Quantity_CheckValidity(quantityInput))
            return;

        this.AddQuantity_Request(parseInt(quantityInput.value), line, quantity);
    }).bind(this);

    private AddQuantity_Request(quantity: number, line: HTMLElement, target: HTMLElement) {
        if (quantity == 0)
            return;
        const itemReference = line.dataset.itemreference;
        const shopCode = line.dataset.shopcode;
        if (!itemReference)
            return;

        Utilities.Fetch({
            url: this.AddQuantityUrl,
            method: Utilities.FetchMethod.POST,
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response), line),
            always: () => { if (this._clickListener) target.onclick = this._clickListener; },
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                itemReference: itemReference,
                quantity: quantity,
                shopCode: shopCode
            }),
            showBusy: true
        });
    }

    private Quantity_CheckValidity = ((quantityInput: HTMLInputElement) => {
        const quantity = quantityInput.parentElement;

        if (quantityInput.checkValidity()) {

            this.SetValid(quantity);
            return 1;
        }

        let state = undefined;
        if (quantityInput.validity.rangeUnderflow)
            state = Utilities.ValidityStates.Rangeunderflow;
        else if (quantityInput.validity.rangeOverflow)
            state = Utilities.ValidityStates.Rangeoverflow;
        if (quantityInput.validity.badInput)
            state = Utilities.ValidityStates.Badinput;

        this.SetInvalid(quantity, state);

        return 0;
    }).bind(this);

    private SetValid(container: HTMLElement) {
        if (!container.classList.contains("error"))
            return;

        container.classList.remove("error");
        delete container.dataset.status;
        //this._deliveryTitle.innerHTML = this._deliveryTitle.dataset.title;
    }

    private SetInvalid(container: HTMLElement, state: Utilities.ValidityStates = Utilities.ValidityStates.Invalid, message?: string) {
        container.classList.add("error");
        delete container.dataset.status;
        //this.ToggleAddToCart(false);

        if (!message && state == Utilities.ValidityStates.Valuemissing)
            message = Utilities.DataListObj.ValidationMessage.Valuemissing;

        if (!message)
            message = container.dataset[`validity${Utilities.ValidityStates[state]}`];

        if (message)
            container.dataset.status = message;
    }

    protected Delete_Click(e: Event, line: HTMLElement) {
        const currentTarget = e.currentTarget as HTMLElement;

        this._clickListener = currentTarget.onclick;
        currentTarget.onclick = undefined;

        this.Delete_Request(line, currentTarget);
    }

    private Delete_Request(line: HTMLElement, target: HTMLElement) {
        const itemReference = line.dataset.itemreference;
        if (!itemReference)
            return;
        const isGift = (line.dataset.isgift || "").toLowerCase() == "true";

        Utilities.Fetch({
            url: this.DeleteItemUrl,
            method: Utilities.FetchMethod.POST,
            success: (e) => this.Delete_Response(JSON.parse((e.target as XMLHttpRequest).response), line),
            always: () => { if (this._clickListener) target.onclick = this._clickListener; },
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                itemReference: itemReference,
                shopCode: line.dataset.shopcode,
                isGift: isGift
            }),
            showBusy: true
        });
    }

    protected IsSuccess(res: any) {
        return (res.Status || res.StatusAsString) === "Successful";
    }

    private Delete_Response(res: any, line: HTMLElement) {
        //if (this.ToggleEmptyCart(this._lines.childElementCount <= 1))
        //    return;

        if (this.IsSuccess(res)) {
            const node = line.parentElement.querySelectorAll(":scope > article").length == 1 ? line.parentElement : line;

            node.addEventListener("transitionend", () => this.Delete_Animate(line));
            node.classList.add("deleted");
        }

        this.UpdateCart(res, line);
    }

    private Delete_Animate(lineToDelete: HTMLElement) {
        const parcelsParent = lineToDelete.parentElement.parentElement as HTMLElement;
        const lineParent = lineToDelete.parentElement as HTMLElement;

        const parcels = Array.prototype.slice.call(parcelsParent.children) as Array<Node>;
        const lines = Array.prototype.slice.call(lineParent.children) as Array<Node>;
        const parcelStartIndex = parcels.indexOf(lineParent) + 1;
        const parcelsLength = parcels.length;
        const lineStartIndex = lines.indexOf(lineToDelete) + 1;
        const linesLength = lines.length;
        let height = 0;
        let onlyChild = false;
        if (lineParent.querySelectorAll(":scope > article").length == 1) {
            onlyChild = true;
            height = lineParent.getBoundingClientRect().height + this.ParcelMargin; // margin
        }
        else
            height = lineToDelete.getBoundingClientRect().height + this.ArticleMargin; // margin

        if (parcelStartIndex > parcelsLength)
            return;
        else if (parcelStartIndex === parcelsLength && lineStartIndex == linesLength) {
            //lines.removeChild(lineToDelete);
            return;
        }

        requestAnimationFrame(_ => {
            for (let i = lineStartIndex; i < linesLength; i++) {
                const line = lines[i] as HTMLElement;

                line.style.transition = "transform .5s cubic-bezier(0,0,0.31,1)";
            }
            for (let i = parcelStartIndex; i < parcelsLength; i++) {
                const parcel = parcels[i] as HTMLElement;

                parcel.style.transition = "transform .5s cubic-bezier(0,0,0.31,1)";
            }

            const onAnimationComplete = (e: Event) => {
                for (let i = lineStartIndex; i < linesLength; i++) {
                    const line = lines[i] as HTMLElement;

                    line.style.transition = "";
                    line.style.transform = "";
                }
                for (let i = parcelStartIndex; i < parcelsLength; i++) {
                    const parcel = parcels[i] as HTMLElement;

                    parcel.style.transition = "";
                    parcel.style.transform = "";
                }
                //if (onlyChild)
                //    lineParent.remove();
                //else
                    //lines.removeChild(lineToDelete);
                e.target.removeEventListener("transitionend", onAnimationComplete);
            };
            //parcels[parcelsLength - 1].addEventListener("transitionend", onAnimationComplete);

            requestAnimationFrame(_ => {
                for (let i = lineStartIndex; i < linesLength; i++) {
                    const line = lines[i] as HTMLElement;

                    line.style.transform = `translateY(-${height}px)`;
                }
                for (let i = parcelStartIndex; i < parcelsLength; i++) {
                    const parcel = parcels[i] as HTMLElement;

                    parcel.style.transform = `translateY(-${height}px)`;
                }
            });
        });
    }
}