module.exports = {
    device: {
        mobile: {
            maxWidth: "1024px"
        }
    },
    media: {
        touch: "(-moz-touch-enabled: 1), (pointer:coarse)",
        notTouch: "(-moz-touch-enabled: 0), not all and (any-pointer:coarse)",
        screenXS: "375px",
        screenSM: "767px",
        screenMD: "1025px",
        screenLG: "1200px",
        screenXL: "1700px",
        trustedShopsBadge: "649px"
    },
    transition: {
        easeOut: "cubic-bezier(0, 0, 0.31, 1)"
    }
};