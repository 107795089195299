import * as Variables from "./variables";
import * as Enums from "./_enumerations";

class CheckoutTotals {
    private readonly _screen_Sm_Upper = Variables.screen(Enums.Screen.sm, Enums.ScreenBoundary.upper);
    private static _instance: CheckoutTotals;
    private static _context: HTMLElement;
    private _container: HTMLElement;
    private _startX: number;
    private _currentX: number;
    private _touching: boolean;
    private _touchingThreshold: number = document.documentElement.clientWidth / 3;

    public Initialise() {
        CheckoutTotals._context = CheckoutTotals.Context;
        this._container = CheckoutTotals._context.querySelector(".right__container");

        this._screen_Sm_Upper.addListener((e) => this.Screen_Sm_Upper_Change(e.matches));
        this.Screen_Sm_Upper_Change(this._screen_Sm_Upper.matches);
    }

    private Screen_Sm_Upper_Change(matches: boolean) {
        if (matches) {
            CheckoutTotals._context.addEventListener("mousedown", () => this.Hide());
            this._container.addEventListener("mousedown", (e) => e.stopPropagation());
            CheckoutTotals._context.querySelectorAll(".close").forEach(close => close.addEventListener("click", () => this.Hide()));

            document.addEventListener("touchstart", (e) => this.Totals_TouchStart(e));
            document.addEventListener("touchmove", (e) => this.Totals_TouchMove(e));
            document.addEventListener("touchend", () => this.Totals_TouchEnd());
        }
    }

    private Totals_TouchStart(e: TouchEvent) {
        if (!CheckoutTotals._context.classList.contains("show"))
            return;

        this._startX = e.touches[0].pageX;
        this._currentX = this._startX;

        this._touching = true;
        requestAnimationFrame(this.Update);
    }

    private Totals_TouchMove(e: TouchEvent) {
        if (!this._touching)
            return;

        this._currentX = e.touches[0].pageX;

        e.preventDefault();
    }

    private Totals_TouchEnd() {
        if (!this._touching)
            return;

        this._touching = false;

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = "";

        if (translateX > this._touchingThreshold)
            this.Hide();
    }

    private Update = (() => {
        if (!this._touching)
            return;

        requestAnimationFrame(this.Update);

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = `translateX(${translateX}px`;
    }).bind(this);

    public Show = () => this.Toggle(true);

    public Hide = () => this.Toggle(false);

    public Toggle(show?: boolean) {
        if (!this._screen_Sm_Upper.matches)
            return;

        CheckoutTotals._context.classList.toggle("show", show);
        document.documentElement.classList.toggle("noscroll", show);
        if (!show)
            (document.activeElement as HTMLElement).blur();
    }

    private static get Context(): HTMLElement {
        return document.querySelector("#checkout-totals");
    }

    public static get Instance() {
        return this.Context && (this._instance || (this._instance = new this()));
    }
}
export let checkoutTotals = (window as any).checkoutTotals;
if (!checkoutTotals) {
    checkoutTotals = CheckoutTotals.Instance;
    if (checkoutTotals) {
        (window as any).checkoutTotals = checkoutTotals;

        checkoutTotals.Initialise();
    }
}