export class Timer {
    private static _instance: Timer;
    private readonly _context: HTMLElement;
    private readonly _segments: Array<HTMLElement>;
    private readonly _seconds: HTMLElement;
    private readonly _minutes: HTMLElement;
    private readonly _hours: HTMLElement;
    private readonly _days: HTMLElement;

    private constructor(context: HTMLElement) {
        this._context = context;
        this._segments = Array.prototype.slice.call(context.children) as Array<HTMLElement>;
        this._seconds = this._segments.pop();
        this._minutes = this._segments.pop();
        this._hours = this._segments.pop();
        this._days = this._segments.pop();

        const endDate = new Date(context.dataset.enddate);
        if (isNaN(endDate.valueOf()))
            return;

        const interval = setInterval(() => {
            const nowDate = Date.now();
            const diffMs = endDate.valueOf() - nowDate.valueOf();
            if (diffMs <= 0) {
                clearInterval(interval);
                this.Update(0, 0, 0, 0);
                return;
            }

            const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);

            this.Update(days, hours, minutes, seconds);
        }, 1000);
        
    }

    private Update(days: number, hours: number, minutes: number, seconds: number) {
        if (this._seconds)
            this._seconds.dataset.num = seconds.toString().padStart(2, "0");

        if (this._minutes)
            this._minutes.dataset.num = minutes.toString().padStart(2, "0");

        if (this._hours)
            this._hours.dataset.num = hours.toString().padStart(2, "0");

        if (this._days)
            this._days.dataset.num = days.toString().padStart(2, "0");
    }

    public static Instance(context: HTMLElement) {
        return context && context.dataset.enddate && (this._instance || (this._instance = new this(context)));
    }
}
document.querySelectorAll(".timer").forEach(_ =>
    Timer.Instance(_ as HTMLElement));