import * as Utilities from "./utilities";

export class CSSelect extends HTMLElement {
    private _select: HTMLElement;
    private _button: HTMLButtonElement;
    private _container: HTMLElement;
    private _list: HTMLElement;
    private _value: string;
    private _validity = new Utilities.ValidityState();
    private _customValidity: string;

    constructor() {
        // Always call super first in constructor
        super();
    }

    protected attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
        switch (name) {
            case "disabled":
                this.UpdateDisabled();
                break;
        }
    }

    protected connectedCallback() {
        // Check if select contents are already in place or is inside a template
        if (this.querySelector(":scope > .select") || this.closest("template"))
            return;

        const selectTemplate = document.querySelector("#csSelect") as HTMLTemplateElement;
        const root = selectTemplate.content.cloneNode(true) as HTMLElement;

        this._select = root.querySelector(".select");

        this._button = root.querySelector("button");
        this._button.removeEventListener("mousedown", this.Button_MouseDown);
        this._button.addEventListener("mousedown", this.Button_MouseDown);

        this._container = root.querySelector(".select-container");
        this._list = root.querySelector(".list");

        // Default text
        this.SetDefault();

        this.checkDisabled();

        this.appendChild(root);
    }

    private Button_MouseDown = ((e: Event) => {
        // Prevent focus
        e.preventDefault();
        this._select.focus();
    }).bind(this);

    private Button_Click = (() => {
        this.toggle();
    }).bind(this);

    private Select_Blur = (() => {
        this.toggle(false);
    }).bind(this);

    private SetDefault = (() => {
        const defaultText = this.getAttribute("default");
        this._button.textContent = defaultText;
        this._value = "";
    }).bind(this);

    private UpdateDisabled() {
        if (!this._select)
            return;

        const disabled = this._select.classList.toggle("disabled", this.hasAttribute("disabled"));
        this.tabIndex = disabled ? -1 : 0;
    }

    private Option_Click = ((index: number) => {
        let selected: boolean;
        for (let i = 0; i < this._list.children.length; i++) {
            const option = this._list.children.item(i) as HTMLElement;

            if (option.classList.toggle("selected", i === index)) {
                this._value = option.dataset.value;
                this._button.innerHTML = option.innerHTML;
                selected = true;
            }
        }

        if (!selected)
            this.SetDefault();
    }).bind(this);

    private GetOptionIndex = ((value: string) => {
        for (let i = 0; i < this._list.children.length; i++) {
            const option = this._list.children.item(i) as HTMLElement;

            if (option.dataset.value === value)
                return i;
        }

        return -1;
    }).bind(this);

    private GetSelectedIndex = (() => {
        for (let i = 0; i < this._list.children.length; i++) {
            const option = this._list.children.item(i) as HTMLElement;

            if (option.classList.contains("selected"))
                return i;
        }

        return -1;
    }).bind(this);

    public toggle(force?: boolean) {
        if (this.disabled) {
            return;
        }

        if (typeof force === "boolean" &&
            ((this._container.style.maxHeight == "none" && !force) ||
                (this._container.style.maxHeight != "none" && force))) {
            return;
        }

        const options = Array.prototype.slice.call(this._container.children) as Array<HTMLElement>;
        let maxHeight = "";
        options.forEach((option) => {
            if (option.offsetParent !== null) {
                maxHeight = option.offsetHeight + "px";
                return;
            }
        });

        requestAnimationFrame(_ => {
            // Initialise max-height after reload with open services
            if (this._container.style.maxHeight == "none") {
                this._container.style.maxHeight = maxHeight;
            }

            requestAnimationFrame(_ =>
                this._container.style.maxHeight = this._select.classList.toggle("selected", force) ? maxHeight : "");
        });
    }

    public empty() {
        Utilities.clear(this._list);
    }

    public setCustomValidity(message: string) {
        this._customValidity = message;
        this._validity.customError = !!message;
    }

    public checkDisabled() {
        // Set to disabled if no options
        let disabled = this._list.children.length <= 1;
        // Only set to disabled when the only option is equal to default
        if (this._list.children.length == 1 && this.hasAttribute("default"))
            disabled = this.options[0].textContent == this.getAttribute("default");
        this.toggleAttribute("disabled", disabled);

        if (!this.hasAttribute("disabled")) {
            this._button.removeEventListener("click", this.Button_Click);
            this._button.addEventListener("click", this.Button_Click);

            if (this._list) {
                this._select.removeEventListener("blur", this.Select_Blur);
                this._select.addEventListener("blur", this.Select_Blur);
            }
        }

        this.UpdateDisabled();
    }

    public checkValidity() {
        if (this.hasAttribute("required") && !this._value) {
            this._validity.valueMissing = true;
            return false;
        }

        return true;
    }

    public setDefaultSelected() {
        for (let i = 0; i < this._list.children.length; i++) {
            const option = this._list.children.item(i) as HTMLElement;

            if (option.hasAttribute("selected")) {
                const oldValue = this.value;
                const newValue = option.dataset.value;
                this.selectedIndex = i;

                if (oldValue !== newValue)
                    this.dispatchEvent(new Event("change"));
                return;
            }
        }

        this.SetDefault();
    }

    public get validationMessage() { return this._customValidity; }

    public get value() { return this._value; }
    public set value(value: string) { this.Option_Click(this.GetOptionIndex(value)); }

    public get name() { return this.getAttribute("name"); }

    public get disabled() { return this.hasAttribute("disabled"); }
    public set disabled(value: boolean) {
        if (!this.toggleAttribute("disabled", value))
            this.checkDisabled();
    }

    public get validity() { return this._validity; }

    public get selectedIndex() { return this.GetSelectedIndex(); }
    public set selectedIndex(index: number) { this.Option_Click(index); }

    public get options() { return Array.prototype.slice.call(this._list.children) as Array<HTMLElement>; }

    protected static get observedAttributes() { return ["disabled"]; }
}

export class CSOption extends HTMLElement {
    private _option: HTMLElement;
    private _select: CSSelect;

    constructor() {
        // Always call super first in constructor
        super();
    }

    protected connectedCallback() {
        if (this.parentElement.tagName !== "CS-SELECT")
            return;
        this._select = this.parentElement as CSSelect;

        // Remove node; to be positioned inside select list
        this.remove();

        const optionTemplate = document.querySelector("#csSelectOption") as HTMLTemplateElement;
        const root = optionTemplate.content.cloneNode(true) as HTMLElement;

        this._option = root.querySelector("li");

        if (this.hasAttribute("color")) {
            const color = document.createElement("span");
            color.className = "color-" + this.getAttribute("color");
            this._option.appendChild(color);
            this.removeAttribute("color");
        }

        const text = document.createTextNode(this.textContent);
        this._option.appendChild(text);

        if (this.hasAttribute("value")) {
            this._option.dataset.value = this.getAttribute("value");
            this.removeAttribute("value");
        }

        if (this.hasAttribute("href")) {
            this._option.dataset.href = this.getAttribute("href");
            this.removeAttribute("href");
        }

        for (let i = 0; i < this.attributes.length; i++) {
            const attr = this.attributes[i];
            this._option.setAttribute(attr.name, attr.value);
        }

        this._option.removeEventListener("click", this.Option_Click);
        this._option.addEventListener("click", this.Option_Click);

        const list = this._select.querySelector(".list");
        list.appendChild(root);

        this._select.checkDisabled();

        if (this._option.classList.toggle("selected", this.hasAttribute("selected")) && this._select.value !== this._option.dataset.value)
            this.Select();
    }

    private Option_Click = ((e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        const href = this._option.dataset.href;
        if (href)
            window.location.href = href;
        else
            this.Select();

        this._select.toggle(false);
    }).bind(this);

    private Select() {
        const oldValue = (this._select as any).value;
        const newValue = this._option.dataset.value;
        this._select.value = newValue;

        if (oldValue !== newValue)
            this._select.dispatchEvent(new Event("change"));
    }
}
if (typeof customElements !== "undefined") {
    customElements.define("cs-select", CSSelect);
    customElements.define("cs-option", CSOption);
}

if (typeof customElements === "undefined") {
    document.querySelectorAll("cs-select").forEach(cssel => {
        const sel = document.createElement("select");

        let selected = false;
        for (let i = 0; i < cssel.children.length; i++) {
            const csopt = cssel.children[i] as HTMLElement;
            const opt = document.createElement("option");
            opt.text = csopt.textContent;
            opt.value = csopt.getAttribute("value");
            if (csopt.classList.contains("selected"))
                selected = opt.selected = true;
            sel.appendChild(opt);
        }

        // Default text
        if (!selected && cssel.hasAttribute("default")) {
            const defOpt = document.createElement("option");
            defOpt.defaultSelected = defOpt.disabled = defOpt.hidden = true;
            defOpt.text = cssel.getAttribute("default");
            sel.prepend(defOpt);
            sel.selectedIndex = 0;
        }

        for (let i = 0; i < cssel.attributes.length; i++) {
            const attr = cssel.attributes[i];
            sel.setAttribute(attr.name, attr.value);
        }

        cssel.parentElement.replaceChild(sel, cssel);
    });
}