import * as Variables from "./variables";
import { isScrolledIntoView } from "./utilities";
import { header } from "./headerV2";

export class MainMenu {
    private static _instance: MainMenu;
    private static readonly _context: HTMLElement = document.getElementById("main-menu-v2");
    private readonly _title = MainMenu._context.querySelector(".title") as HTMLElement;
    private readonly _mainMenuTimeoutDelay = 300;
    private readonly _headerV2 = document.querySelector(".header-v2");
    private readonly _headerV3Top = this._headerV2.querySelector(".header-top-v3");
    private readonly _menus = Array.prototype.slice.call(MainMenu._context.querySelectorAll(":scope > ul > li")) as Array<HTMLElement>;
    private _mainMenuTimeoutId: ReturnType<typeof setTimeout>;
    private _lastMenuShown: HTMLElement;
    private _dropDownMouseDown: boolean;
    public _dropDownBlur = false;

    private constructor() {
        this.Initialise();
    }

    private Initialise() {
        this._title.addEventListener("click", (e) => this.DropDown_Click(e));
        MainMenu._context.querySelectorAll(".indicator").forEach(indicator => indicator.addEventListener("click", (e) => this.DropDown_Click(e)));
        MainMenu._context.querySelectorAll("ul > li > a").forEach(menuItem => menuItem.addEventListener("click", (e) => this.DropDown_Click(e)));
        this._menus.forEach(menu => {
            menu.addEventListener("mouseenter", (e) => this.MainMenu_MouseEnter(e));
            menu.addEventListener("touchend", (e) => this.MainMenu_TouchEnd(e));
            menu.addEventListener("blur", () => this.MainMenu_MouseLeave());
            menu.addEventListener("mousedown", () => this.DropDown_MouseDown());
        });
        MainMenu._context.querySelector(":scope > ul").addEventListener("mouseleave", () => this.MainMenu_MouseLeave());
        MainMenu._context.addEventListener("transitionend", (e) => this.MainMenu_TransitionEnd(e));
        MainMenu._context.addEventListener("touchmove", function (e) {
            if (Variables.mobile.matches) {
                if (mainMenu._headerV2.getElementsByClassName("container").item(0).className.includes("collapsed")) {
                    mainMenu._headerV2.getElementsByTagName("ul").item(0).setAttribute("style", "max-height: calc(100vh - 40px);");
                }
                else {
                    mainMenu._headerV2.getElementsByTagName("ul").item(0).setAttribute("style", "max-height: calc(100vh - 84px);");
                }

                let lastLi = MainMenu.Instance._menus[MainMenu.Instance._menus.length - 1];

                if (isScrolledIntoView(lastLi)) {
                    e.preventDefault();
                }
            }
            return false;
        });

        let selScrollable = "#main-menu-v2 > .container";
        // Uses document because document will be topmost level in bubbling
        $(document).on("touchmove", function (e: any) {
            e.preventDefault();
        });
        // Uses body because jQuery on events are called off of the element they are
        // added to, so bubbling would not work if we used document instead.
        $("body").on("touchstart", selScrollable, function (e: any) {
            if (e.currentTarget.scrollTop === 0) {
                e.currentTarget.scrollTop = 1;
            } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
                e.currentTarget.scrollTop -= 1;
            }
        });
        // Stops preventDefault from being called on document if it sees a scrollable div
        $("body").on("touchmove", selScrollable, function (e: any) {
            e.stopPropagation();
        });
    }

    private MainMenu_TouchEnd(e: Event) {
        if (Variables.mobile.matches)
            return;

        const menu = e.currentTarget as HTMLAnchorElement;

        if (menu.classList.contains("show"))
            return;

        this.MainMenu_MouseEnter(e);
        menu.focus();

        e.preventDefault();
    }

    private MainMenu_MouseEnter(e: Event) {
        if (Variables.mobile.matches)
            return;

        const menu = e.currentTarget as HTMLElement;

        if (this._lastMenuShown)
            this.ShowMenu(menu);
        else {
            clearTimeout(this._mainMenuTimeoutId);
            this._mainMenuTimeoutId = setTimeout(() => {
                this.ShowMenu(menu);
            }, this._mainMenuTimeoutDelay);
        }
    }

    private MainMenu_TransitionEnd(e: Event) {
        const menuItem = e.target as HTMLElement;

        if (menuItem.style.height)
            menuItem.style.height = "auto";
        e.preventDefault();
    }

    private MainMenu_MouseLeave() {
        if (Variables.mobile.matches)
            return;

        clearTimeout(this._mainMenuTimeoutId);
        this.HideMenu(this._lastMenuShown);
    }

    private ShowMenu(menu: HTMLElement) {
        if (!menu)
            return;

        if (this._lastMenuShown) {
            this.HideMenu(this._lastMenuShown);
            this._lastMenuShown = menu;
            MainMenu._context.classList.add("hover");
            menu.classList.add("show");
        }
        else {
            MainMenu._context.classList.add("hover");
            const menuItem = menu.querySelector(".main-menu-item") as HTMLElement;
            if (menuItem) {
                this._lastMenuShown = menu;
                requestAnimationFrame(_ => {
                    menuItem.style.transition = `opacity ${Variables.transition.easeOut} .3s, visibility ${Variables.transition.easeOut} .3s`;
                    menuItem.style.setProperty("will-change", "opacity, visibility");
                    const onAnimationComplete = () => {
                        menuItem.style.transition = "";
                        menuItem.style.removeProperty("will-change");
                        menuItem.removeEventListener("transitionend", onAnimationComplete);
                    };
                    menuItem.addEventListener("transitionend", onAnimationComplete);
                    requestAnimationFrame(_ => {
                        menu.classList.add("show");
                    });
                });
            }
        }
    }

    private HideMenu(menu: HTMLElement) {
        if (!menu)
            return;

        this._lastMenuShown = undefined;
        menu.classList.remove("show");
        MainMenu._context.classList.remove("hover");
    }

    private DropDown_MouseDown = (() => {
        this._dropDownMouseDown = true;
    }).bind(this);

    private DropDown_Click = ((e: Event) => {
        if (!Variables.mobile.matches)
            return;

        this._dropDownBlur = false;

        if (mainMenu._headerV2.getElementsByClassName("container").item(0).className.includes("collapsed")) {
            mainMenu._headerV2.getElementsByTagName("ul").item(0).setAttribute("style", "max-height: calc(100vh - 40px);");
        }
        else {
            mainMenu._headerV2.getElementsByTagName("ul").item(0).setAttribute("style", "max-height: calc(100vh - 84px);");
        }

        let self = e.currentTarget as HTMLElement;
        let parent = self.parentElement;

        if (!parent.querySelector(":scope > .main-menu-item") && !parent.querySelector(":scope > ul") && !parent.classList.contains("main-menu-v2"))
            return;

        if (self.tagName == "A" && self.getAttribute("href").indexOf("javascript:") == -1 && parent.classList.contains("open"))
            return;

        let cloOpen = parent.closest(".open");
        if ((!cloOpen || cloOpen.classList.contains("main-menu-v2")) && !parent.classList.contains("main-menu-v2")) {
            let opens = MainMenu._context.querySelectorAll(".open");
            for (let i = 0; i < opens.length; i++) {
                opens[i].classList.remove("open");
                const menuItem = opens[i].querySelector(":scope > ul") as HTMLElement;
                if (menuItem)
                    menuItem.style.height = "";
            }
        }

        if (this._headerV2) {
            const heightTransition: HTMLElement = self === this._title ? MainMenu._context : parent.querySelector(":scope > ul");
            if (!parent.classList.toggle("open")) {
                requestAnimationFrame(() => {
                    heightTransition.style.height = `${heightTransition.scrollHeight}px`;
                    requestAnimationFrame(() => {
                        heightTransition.style.height = "";
                    });
                });

                MainMenu._context.removeEventListener("focusout", this.MainMenu_Blur);
            }
            else {
                heightTransition.style.height = `${heightTransition.scrollHeight}px`;
                if (self === this._title) {

                    MainMenu._context.addEventListener("focusout", this.MainMenu_Blur);
                    MainMenu._context.focus();
                }
            }
        }
        else {
            parent.classList.toggle("open");
        }

        e.preventDefault();
    }).bind(this);

    private MainMenu_Blur = (() => {
        if (this._dropDownMouseDown) {
            this._dropDownMouseDown = false;
            return;
        }

        header.Menu_Click();

        setTimeout(() => {
            this._dropDownBlur = false;
        }, 1);
        this._dropDownBlur = true;
    }).bind(this);

    public Toggle = (() => {
        this._title.click();
        return MainMenu._context.classList.contains("open");
    }).bind(this);

    public static get Context() {
        return MainMenu._context;
    }

    public static get Instance() {
        if (!this._context)
            return;

        if (!this._instance && (window as any).mainMenu)
            this._instance = (window as any).mainMenu;

        return this._instance || (this._instance = new this());
    }
}
export const mainMenu = MainMenu.Instance;

if (mainMenu) {
    (window as any).mainMenu = mainMenu;
}