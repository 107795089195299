﻿import { error } from "jquery";

$.validator.unobtrusive.adapters.addBool("mustbetrue", "required");
$.validator.setDefaults({
	onkeyup: function (element) {
		if ($(element).attr("data-bind-validatephone") != "true") {
			//$.validator.defaults.onkeyup.apply(this, arguments);
		}
	}
});
jQuery(document).ready(function ($) {
	clampLines();
	$(".btn-cookies-accept").click(acceptCookies);
	$("[data-cookies-policy-link]").click(processCookiesPolicyLinkClick);
	$("[data-checkout-terms-link]").click(processCheckoutTermsLinkClick);

	// Delete ic, productposition and productgtmlisting cookies
	document.cookie = "productPosition=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	document.cookie = "productGTMListing=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	document.cookie = "ic=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";

	$(window).resize(function () {
		if ($(".menu-item .indicator").css("display") == "block") {
			var $c = $(".footer-upper"),
				$w = $(".menu-item"),
				totalWidth = $(".container-fluid").outerWidth(),
				wellWidth = $w.outerWidth(),
				diff = totalWidth - wellWidth,
				marg = -Math.floor(diff / 2) + "px";
			$w.each(function () {
				$(this).css({
					"margin-left": marg,
					"margin-right": marg
				});
			});
		}
		else {
			var $w = $(".menu-item");
			$w.each(function () {
				$(this).removeAttr("style");
			});
		}

		resizeBestSellersContianer();
	});
	$(window).resize();

	$(".menu-item").click(function () {
		if ($(".menu-item .indicator").css("display") == "block" ||
			$(".menu-item .indicator").css("display") == "inline")
			$(this).toggleClass("selected");
	});

	$(".btn-add-item-to-cart-from-card").on("click", LinkConfigurablesToPDP);

	resizeBestSellersContianer();
});

// Textarea and select clone() bug workaround | Spencer Tipping
// Licensed under the terms of the MIT source code license

// Motivation.
// jQuery's clone() method works in most cases, but it fails to copy the value of textareas and select elements. This patch replaces jQuery's clone() method with a wrapper that fills in the
// values after the fact.

// An interesting error case submitted by Piotr Przybył: If two <select> options had the same value, the clone() method would select the wrong one in the cloned box. The fix, suggested by Piotr
// and implemented here, is to use the selectedIndex property on the <select> box itself rather than relying on jQuery's value-based val().

(function (original) {
	jQuery.fn.clone = function () {
		let result = original.apply(this, arguments),
			my_textareas = this.find("textarea").add(this.filter("textarea")),
			result_textareas = result.find("textarea").add(result.filter("textarea")),
			my_selects = this.find("select").add(this.filter("select")),
			result_selects = result.find("select").add(result.filter("select"));

		for (var i = 0, l = my_textareas.length; i < l; ++i) $(result_textareas[i]).val($(my_textareas[i]).val());
		for (var i = 0, l = my_selects.length; i < l; ++i) result_selects[i].selectedIndex = my_selects[i].selectedIndex;

		return result;
	};
})(jQuery.fn.clone);

// CustomEvent
(function () {

	if (typeof window.CustomEvent === "function") return false;

	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: null };
		let evt = document.createEvent("CustomEvent");
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	window.CustomEvent = CustomEvent;
})();

// https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
function handleFirstTab(e) {
	if (e.keyCode === 9) { // the "I am a keyboard user" key
		document.body.classList.add("user-is-tabbing");
		window.removeEventListener("keydown", handleFirstTab);
	}
}
window.addEventListener("keydown", handleFirstTab);

function LinkConfigurablesToPDP(e) {
	if ($(e.currentTarget.parentElement).find("[name=configurableUrl]").length > 0)
		createCookie("ic", "true", 1);
}

function LinkToPDP(event) {
	createCookie("productPosition", event.currentTarget.dataset.gtmDetailPosition, 1);
	createCookie("productGTMListing", event.currentTarget.dataset.gtmDetailSource, 1);
}

let originalLeave = $.fn.popover.Constructor.prototype.leave;
$.fn.popover.Constructor.prototype.leave = function (obj) {
	let self = obj instanceof this.constructor ?
		obj : $(obj.currentTarget)[this.type](this.getDelegateOptions()).data("bs." + this.type);
	let container, timeout;

	originalLeave.call(this, obj);

	if (obj.currentTarget) {
		container = $(obj.currentTarget).siblings(".popover");
		timeout = self.timeout;
		container.one("mouseenter", function () {
			//We entered the actual popover – call off the dogs
			clearTimeout(timeout);
			//Let's monitor popover content instead
			container.one("mouseleave", function () {
				if ($(this).find(".focused").length > 0)
					return false;

				$.fn.popover.Constructor.prototype.leave.call(self, self);
			});
		});
	}
};
$("body").on("click", function (e) {
	//did not click a popover toggle, or icon in popover toggle, or popover
	if ($(e.target).data("toggle") !== "popover"
		&& $(e.target).parents("[data-toggle=\"popover\"]").length === 0
		&& $(e.target).parents(".popover.in").length === 0) {
		$("[data-toggle=\"popover\"][aria-describedby]").popover("hide");
	}
	//else if (!closeThePopUpIfButtonClicked(e)) {
	//    closePopUpIfClickedNotButtonOrPopUp(e);
	//}

});

function resizeBestSellersContianer() {
	let windowWidth = $(window).innerWidth();

	if (windowWidth < 767) {
		$.each($(".bestsellerscontainer"), function (i, v) {
			let count = $(this).find(".item").length;
			let minwidth = $(this).find(".item:first-child").css("min-width");
			if (minwidth != undefined) {
				minwidth = minwidth.substring(0, minwidth.length - 2);
				$(this).find("> .row").css("width", (count * minwidth) + (count * 26));
			}
		});
	} else {
		$(".bestsellerscontainer > .row").css("width", "");
	}
}
function closeThePopUpIfButtonClicked(e) {
	//popups with an aria-describedby are open popups. if the targer is a click on the button, then we close it
	if ($(e.target).parents("[data-toggle=\"popover\"][aria-describedby]").length != 0) {
		$(e.target).parents("[data-toggle=\"popover\"][aria-describedby]").popover("hide");
		return true;
	}
	return false;
}
function closePopUpIfClickedNotButtonOrPopUp(e) {
	if ($(e.target).closest(".popover").length == 0) {
		$("[data-toggle=\"popover\"][aria-describedby]").not($(e.target).parents("[data-toggle=\"popover\"]")).popover("hide");
	}
}

function clampLines() {
	$("[data-clamp-lines]").dotdotdot({
		watch: "window"
	});
}

function updateQueryStringParameter(uri, key, value) {
	if (!value)
		return removeQueryStringParameter(uri, key);

	let queryString = "";
	let newUrl = "";
	if (uri.indexOf("?") != -1) {
		newUrl = uri.substring(0, uri.indexOf("?"));
		queryString = uri.substring(uri.indexOf("?") + 1, uri.length);
	}
	else {
		newUrl = uri;
		queryString = "";
	}

	let found = false;
	let urlQueryStrings = queryString.split(/[?#&]+/);

	let currentSeparator = "?";
	$(urlQueryStrings).each(function (eindex, evalue) {
		{
			let keyvalue = evalue.split("=");
			if (keyvalue.length > 1) {
				let qrykey = keyvalue[0];
				let qryvalue = keyvalue[1];

				if (qrykey.toLowerCase() == key.toLowerCase()) {
					qryvalue = value;
					found = true;
				}

				newUrl += currentSeparator + qrykey + "=" + qryvalue;
				currentSeparator = "&";
			}
		}
	});
	if (!found) {
		newUrl += currentSeparator + key + "=" + value;
	}
	//console.log("New url after update " + key + " : " + newUrl);
	return newUrl;
}
function removeQueryStringParameter(uri, key) {
	let queryString = "";
	let newUrl = "";
	if (uri.indexOf("?") != -1) {
		newUrl = uri.substring(0, uri.indexOf("?"));
		queryString = uri.substring(uri.indexOf("?") + 1, uri.length);
	}
	else {
		newUrl = uri;
		queryString = "";
	}

	let found = false;
	let urlQueryStrings = queryString.split(/[?#&]+/);

	let currentSeparator = "?";
	let newQueryString = [];
	$(urlQueryStrings).each(function (eindex, evalue) {
		{
			let keyvalue = evalue.split("=");
			if (keyvalue.length > 1) {
				let qrykey = keyvalue[0];
				let qryvalue = keyvalue[1];

				if (qrykey.toLowerCase() == key.toLowerCase()) {
					found = true;
				}
				else {
					newUrl += currentSeparator + qrykey + "=" + qryvalue;
					currentSeparator = "&";
				}
			}
		}
	});
	//console.log("New url after delete " + key + " : " + newUrl);
	return newUrl;
}
function readCookie(name) {
	let nameEQ = encodeURIComponent(name) + "=";
	let ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
	}
	return null;
}
function createCookie(name, value, days) {
	let expires;

	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toGMTString();
	} else {
		expires = "";
	}
	document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

$(document).ready(function () {
	bindFormSubmit();
});

function bindFormSubmit() {
	$("form").on("submit", function () { FormValidate(this); });
}

function FormValidate(form) {
	let validator = $(form).validate({
		focusInvalid: true
	});
	if (validator.errorList.length > 0) {
		return false;
	}

	return true;
}

function scrollToVerticallyCenterElementByName(inputName) {
	let el = $("[name='" + inputName + "']")[0];
	if ($(el).is("select")) {
		let id = $(el).attr("id");
		let button = $("button[data-id=" + id + "]");
		if ($(button).length > 0) {
			el = $(button);
		}
	}
	let offsetValue = 0;
	//checks if it is in a modal popup
	let modalContainer = $(el).parents(".modal");
	if ($(modalContainer).length > 0) {
		let wHeight = $(window).height();
		let container = $(modalContainer).find(".modal-content");
		let eHeight = $(el).outerHeight(true);
		let cHeight = $(container).height();
		let eFromTop = $(el).offset().top - $(container).offset().top;
		offsetValue = eFromTop - (wHeight - eHeight) / 2;
		if (offsetValue < 0) {
			offsetValue = 0;
		}
		$(modalContainer).animate(
			{
				scrollTop: offsetValue
			}
			, 1000);
	}
	else {
		offsetValue = $(el).offset().top - ($(window).height() - $(el).outerHeight(true)) / 2;
		if (offsetValue < 0) {
			offsetValue = 0;
		}
		$("html,body").animate({ scrollTop: offsetValue }, 1000);
	}
	//console.log(offsetValue);  

	return false;
}
function scrollToVerticallyCenterElementBySelector(selector) {
	let el = $(selector);
	scrollToVerticallyCenterElement(el);
	return false;
}
function scrollToVerticallyCenterElement(element) {
	if (element.length > 0) {

		let visibleHeight = $(window).height() - $("header").outerHeight();
		let offSet = 0;

		if ($(element).outerHeight() > visibleHeight) {
			offSet = $(element).offset().top - $("header").outerHeight();
		}
		else {
			offSet = $(element).offset().top - (visibleHeight - $(element).outerHeight(true)) / 2;
		}
		$("html,body").animate({
			scrollTop: offSet
		}, 1000);
	}
	return false;
}
function scrollToEdgeOfElementAtBottomBySelector(selector) {
	let el = $(selector);
	if (el.length > 0) {
		$("html,body").animate({
			scrollTop: $(el).offset().top - ($(window).height() - $(el).outerHeight(true))
		}, 1000);
	}
	return false;
}

/*             Cookies Start                     */

let step = 25;
let scrolling = false;
let rightReached = false;

function acceptCookies() {
	$(".cookies").slideUp();
	createCookie("CookieUsage", true, 360);
	return false;
}

function processCookiesPolicyLinkClick() {
	//Cookies Policy
	//var flag = "mobile";
	//if (window.innerWidth > 767) {
	let flag = "desktop";
	if (IsCookiesPolicyModalLoaded()) {
		$(cookiesPolicyModalId).modal("show");
		return false;
	}
	//}
	$.post("/StaticPage/ProcessCookiesPolicyLinkClick", {
		"flag": flag,
	},
		function (data) {
			if (isSuccessfulResponse(data)) {
				//if (flag == "mobile") {
				//    window.location = data.Url;
				//}
				//else {
				processCookiesPolicyLoadDesktop(data.Html);
				//}
			}
			else {
				console.log("Error while getting data");
			}
		});
	return false;
}
var cookiesPolicyModalId = "#cookies-policy-modal";
function IsCookiesPolicyModalLoaded() {
	return $(cookiesPolicyModalId).length > 0;
}
function isSuccessfulResponse(data) {
	return data.StatusAsString == "Successful" || data.Status.Status == 1;
}
function processCookiesPolicyLoadDesktop(html) {
	let section = 1;
	let sectionTemplateLG = "";
	let sectionTemplateLGSubSection = "";
	let cookiesPolicyHtml = $("<div/>").html(html);
	sectionTemplateLG = $(cookiesPolicyHtml).find("[data-cookies-policy-templates] [data-section-template-lg]").html();
	sectionTemplateLGSubSection = $(cookiesPolicyHtml).find("[data-cookies-policy-templates] [data-section-template-lg] [data-sub-section]").html();

	//set popup title
	$(cookiesPolicyHtml).find("[data-page-title]").html($(cookiesPolicyHtml).find("[data-cookies-policy-list] [data-page-title]").html());

	$(cookiesPolicyHtml).find("[data-cookies-policy-list] [data-section]").each(function () {
		let title = $(this).find("[data-title]").html();
		let content = $(this).find("[data-content] [data-summary]").html();
		let sectionTemp = "";
		let subSection = 0;

		/* Desktop Screen */
		sectionTemp = " [data-section-body-lg] ";

		//title
		$(cookiesPolicyHtml).find(sectionTemp + " [data-titles]").append($("<div/>").html(sectionTemplateLG).contents().find("[data-title]").html());

		//content
		$(cookiesPolicyHtml).find(sectionTemp + " [data-sections]").append($("<div/>").html(sectionTemplateLG).contents().find("[data-content]").html());
		$(cookiesPolicyHtml).find(sectionTemp + " [data-sections] [data-content-inner][data-temp]").html(content);

		//title text
		$(cookiesPolicyHtml).find(sectionTemp + " [data-title-text][data-temp]").html(title);

		//show hide js
		$(cookiesPolicyHtml).find(sectionTemp + " [data-titles] [data-title-text][data-temp]").attr("data-par", section);
		$(cookiesPolicyHtml).find(sectionTemp + " [data-sections] [data-section][data-temp]").attr("data-cond-par", section);


		subSection = 0;
		$(this).find("[data-sub-section]").each(function () {
			let subSectionTempHolder = sectionTemp + " [data-content-inner][data-main][data-temp] ";
			let titleSub = $(this).find("[data-title]").html();
			let contentSub = $(this).find("[data-content]").html();

			$(cookiesPolicyHtml).find(subSectionTempHolder).append(sectionTemplateLGSubSection);
			$(cookiesPolicyHtml).find(subSectionTempHolder + " [data-title-text][data-temp] ").html(titleSub);
			$(cookiesPolicyHtml).find(subSectionTempHolder + " [data-content-inner][data-temp] ").html(contentSub);


			let targetSub = $(cookiesPolicyHtml).find(subSectionTempHolder + " [data-target][data-temp] ").attr("data-target");
			$(cookiesPolicyHtml).find(subSectionTempHolder + " [data-target][data-temp] ").attr("data-target", targetSub + "-" + section + "-" + subSection);

			let contentIdSub = $(cookiesPolicyHtml).find(subSectionTempHolder + " [data-content-inner][data-temp] ").attr("id");
			$(cookiesPolicyHtml).find(subSectionTempHolder + " [data-content-inner][data-temp] ").attr("id", contentIdSub + "-" + section + "-" + subSection);

			subSection++;

			$(cookiesPolicyHtml).find(subSectionTempHolder + " [data-temp]").removeAttr("data-temp");
		});

		///* Desktop Screen End */
		$(cookiesPolicyHtml).find("[data-section-body-xs] [data-temp]").removeAttr("data-temp");
		$(cookiesPolicyHtml).find("[data-section-body-lg] [data-temp]").removeAttr("data-temp");
		section++;
	});
	$(cookiesPolicyModalId).remove();
	$("body").prepend($(cookiesPolicyHtml).find(cookiesPolicyModalId)[0].outerHTML);
	$(cookiesPolicyModalId).modal();
	$(cookiesPolicyModalId).modal("show");

	popupShow(1);
	initModalMenu(cookiesPolicyModalId);

	$(".displayConditions.show-me-xs").click(function () {
		// $("#tarjeta-main-content").hide();
		$("#cookies-policy-xs").show();
		$("html,body").animate({
			scrollTop: 0
		}, 1000);
		return false;
	});

	$("[data-par]").click(popupMenuClick);


	$(".displayHidePar").click(function () {
		$(".arrow-up", this).toggle();
		$(".arrow-down", this).toggle();
	});
	return false;
}
function processCookiesPolicyLoadMobile() {
	if ($("[data-cookies-policy-list]").length) {
		$("#conditons-xs").hide();
		$(".arrow-up").hide();
		$(".arrow-down").show();
	}

	let section = 1;
	let sectionTemplateXS = "";
	let sectionTemplateXSSubSection = "";
	sectionTemplateXS = $("[data-cookies-policy-templates] [data-section-template-xs] [data-section]").html();
	sectionTemplateXSSubSection = $("[data-cookies-policy-templates] [data-section-template-xs] [data-sub-section]").html();


	$("[data-cookies-policy-list] [data-section]").each(function () {
		let title = $(this).find("[data-title]").html();
		let content = $(this).find("[data-content] [data-summary]").html();
		let sectionTemp = "";
		let subSection = 0;
		/* Creating for mobile screen*/
		sectionTemp = " [data-section-body-xs] [data-sections]";
		$("[data-section-body-xs] [data-sections]").append(sectionTemplateXS);
		$(sectionTemp + " [data-title-text][data-temp] ").html(title);
		$(sectionTemp + " [data-content][data-temp] ").html(content);

		let target = $(sectionTemp + " [data-target][data-temp] ").attr("data-target");
		$(sectionTemp + " [data-target][data-temp] ").attr("data-target", target + "" + section + "-mob");

		let contentId = $(sectionTemp + " [data-content][data-temp] ").attr("id");
		$(sectionTemp + " [data-content][data-temp] ").attr("id", contentId + "" + section + "-mob");

		if (section == 1) {
			$(sectionTemp + " [data-content][data-temp] ").addClass("in");
			$(sectionTemp + " .arrow-up").show();
			$(sectionTemp + " .arrow-down").hide();
		}
		//$(this).find("[data-sub-section]").each(function () {
		//    var subSectionTempHolder = sectionTemp + " [data-content][data-main][data-temp]";
		//    var titleSub = $(this).find("[data-title]").html();
		//    var contentSub = $(this).find("[data-content]").html();

		//    $(subSectionTempHolder).append(sectionTemplateXSSubSection);
		//    $(subSectionTempHolder + " [data-title-text][data-temp] ").html(titleSub);
		//    $(subSectionTempHolder + " [data-content][data-temp] ").html(contentSub);


		//    var targetSub = $(subSectionTempHolder + " [data-target][data-temp] ").attr("data-target");
		//    $(subSectionTempHolder + " [data-target][data-temp] ").attr("data-target", targetSub + "-" + section + "-" + subSection + "-dtp");

		//    var contentIdSub = $(subSectionTempHolder + " [data-content][data-temp] ").attr("id");
		//    $(subSectionTempHolder + " [data-content][data-temp] ").attr("id", contentIdSub + "-" + section + "-" + subSection + "-dtp");

		//    subSection++;

		//    $(subSectionTempHolder + " [data-temp]").removeAttr("data-temp");;
		//});
		/* Sub Sections */

		///* Desktop Screen End */

		$("[data-section-body-xs] [data-temp]").removeAttr("data-temp");
		$("[data-section-body-lg] [data-temp]").removeAttr("data-temp");
		section++;
	});

	popupShow(1);

	$(".displayConditions.show-me-xs").click(function () {
		// $("#tarjeta-main-content").hide();
		$("#cookies-policy-xs").show();
		$("html,body").animate({
			scrollTop: 0
		}, 1000);
		return false;
	});

	$("[data-par]").click(function () {
		let section = $(this).attr("data-par");
		popupShow(section);
		return false;
	});


	$(".displayHidePar").click(function () {
		$(".arrow-up", this).toggle();
		$(".arrow-down", this).toggle();
	});

	//$("[data-section-body-xs]").show();
}

/*                             Cookies End                          */

/*              Terms and Conditions Start                  */
function processCheckoutTermsLinkClick() {
	console.log("Terms and conditions link clicked.");
	return false;
}

let domicilioPopUpHolder = "body";
let domicilioSectionId = "#domicilio-modal";
let domicilioLinkClass = ".entrega-link";
function ShowDomicilioModalPopUp() {
	if ($(domicilioPopUpHolder).length == 0) {
		console.log("holder " + domicilioPopUpHolder + " not found");
		return false;
	}

	if (IsDomicilioSectionLoaded()) {
		$(domicilioSectionId).modal("show");
	}
	else {
		$.post("/ShoppingCart/GetDomicilioPopUp",
			{},
			function (data) {
				if (isSuccessful(data)) {
					$(domicilioPopUpHolder).prepend(data.Html);
					$(domicilioSectionId).modal();
					$(domicilioSectionId).on("hide.bs.modal", function () { gtmVirtualPageviewClosed(domicilioLinkClass); });
					$(domicilioSectionId).modal("show");
					//$(montajeLinkId).attr("data-toggle", "modal");
					//$(montajeLinkId).attr("data-target", domicilioSectionId);

					$(".section-shipping-fee form .btn").on("click", function () {
						return GetShippingPromotion($(domicilioSectionId).find(".section-shipping-fee form > input").val());
					});
				}
				populateStatusMessage(data);
			});
	}
	return false;

}
function IsDomicilioSectionLoaded() {
	return $(domicilioPopUpHolder + " " + domicilioSectionId).length > 0;
}
//function ResetDomicilioPopUpLinks() {
//    $(montajeLinkId).unbind("click", ShowMontajeModalPopUp);
//    $(montajeLinkId).bind("click", ShowMontajeModalPopUp);
//}

function GetShippingPromotion(promoCode) {
	disableAjaxBusySettings();
	$.post("/ShoppingCart/GetShippingPromotion",
		{
			postCode: promoCode
		},
		function (data) {
			if (isSuccessful(data)) {
				$("#shippingFeePlaceholder").html(data.Html);
				$(domicilioSectionId).find(".modal-body").show();
				if (typeof refreshCart !== "undefined")
					refreshCart();

				$(".section-shipping-fee form .btn").on("click", function () {
					return GetShippingPromotion($("#shippingFeePlaceholder").find(".section-shipping-fee form > input").val());
				});
			}
			populateStatusMessage(data);
			createAjaxBusySettings();
		});
	return false;
}

// Object.assign polyfill: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (typeof Object.assign != "function") {
	// Must be writable: true, enumerable: false, configurable: true
	Object.defineProperty(Object, "assign", {
		value: function assign(target, varArgs) { // .length of function is 2
			"use strict";
			if (target == null) { // TypeError if undefined or null
				throw new TypeError("Cannot convert undefined or null to object");
			}

			let to = Object(target);

			for (let index = 1; index < arguments.length; index++) {
				let nextSource = arguments[index];

				if (nextSource != null) { // Skip over if undefined or null
					for (let nextKey in nextSource) {
						// Avoid bugs when hasOwnProperty is shadowed
						if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
							to[nextKey] = nextSource[nextKey];
						}
					}
				}
			}
			return to;
		},
		writable: true,
		configurable: true
	});
}

// Classlist polyfill: https://developer.mozilla.org/en-US/docs/Web/API/Element/classList
// 1. String.prototype.trim polyfill
if (!"".trim) String.prototype.trim = function () { return this.replace(/^[\s﻿]+|[\s﻿]+$/g, ""); };
(function (window) {
	"use strict"; // prevent global namespace pollution
	let Element = window.Element, wsI = 0, wsRE = /[\11\12\14\15\40]/; // WhiteSpace Regular Expression;
	function checkIfValidClassListEntry(O, V) {
		if (V === "") throw new DOMException(
			"Failed to execute '" + O + "' on 'DOMTokenList': The token provided must not be empty.");
		if ((wsI = V.search(wsRE)) !== -1) throw new DOMException("Failed to execute '" + O + "' on 'DOMTokenList': " +
			"The token provided ('" + V[wsI] + "') contains HTML space characters, which are not valid in tokens.");
	}
	// 2. Implement the barebones DOMTokenList livelyness polyfill
	if (typeof DOMTokenList !== "function") (function (window) {
		let document = window.document, Object = window.Object, hasOwnProp = Object.prototype.hasOwnProperty;
		let defineProperty = Object.defineProperty, allowTokenListConstruction = 0, skipPropChange = 0;
		function DOMTokenList() {
			if (!allowTokenListConstruction) throw TypeError("Illegal constructor"); // internally let it through
		}
		DOMTokenList.prototype.toString = DOMTokenList.prototype.toLocaleString = function () { return this.value; };
		DOMTokenList.prototype.add = function () {
			a: for (var v = 0, argLen = arguments.length, val = "", ele = this["uCL"], proto = ele[" uCLp"]; v !== argLen; ++v) {
				val = arguments[v] + "", checkIfValidClassListEntry("add", val);
				for (var i = 0, Len = proto.length, resStr = val; i !== Len; ++i)
					if (this[i] === val) continue a; else resStr += " " + this[i];
				this[Len] = val, proto.length += 1, proto.value = resStr;
			}
			skipPropChange = 1, ele.className = proto.value, skipPropChange = 0;
		};
		DOMTokenList.prototype.remove = function () {
			for (var v = 0, argLen = arguments.length, val = "", ele = this["uCL"], proto = ele[" uCLp"]; v !== argLen; ++v) {
				val = arguments[v] + "", checkIfValidClassListEntry("remove", val);
				for (var i = 0, Len = proto.length, resStr = "", is = 0; i !== Len; ++i)
					if (is) { this[i - 1] = this[i]; } else { if (this[i] !== val) { resStr += this[i] + " "; } else { is = 1; } }
				if (!is) continue;
				delete this[Len], proto.length -= 1, proto.value = resStr;
			}
			skipPropChange = 1, ele.className = proto.value, skipPropChange = 0;
		};
		window.DOMTokenList = DOMTokenList;
		function whenPropChanges() {
			let evt = window.event, prop = evt.propertyName;
			if (!skipPropChange && (prop === "className" || (prop === "classList" && !defineProperty))) {
				let target = evt.srcElement, protoObjProto = target[" uCLp"], strval = "" + target[prop];
				let tokens = strval.trim().split(wsRE), resTokenList = target[prop === "classList" ? " uCL" : "classList"];
				let oldLen = protoObjProto.length;
				a: for (var cI = 0, cLen = protoObjProto.length = tokens.length, sub = 0; cI !== cLen; ++cI) {
					for (let innerI = 0; innerI !== cI; ++innerI) if (tokens[innerI] === tokens[cI]) { sub++; continue a; }
					resTokenList[cI - sub] = tokens[cI];
				}
				for (let i = cLen - sub; i < oldLen; ++i) delete resTokenList[i]; //remove trailing indexs
				if (prop !== "classList") return;
				skipPropChange = 1, target.classList = resTokenList, target.className = strval;
				skipPropChange = 0, resTokenList.length = tokens.length - sub;
			}
		}
		function polyfillClassList(ele) {
			if (!ele || !("innerHTML" in ele)) throw TypeError("Illegal invocation");
			srcEle.detachEvent("onpropertychange", whenPropChanges); // prevent duplicate handler infinite loop
			allowTokenListConstruction = 1;
			try { function protoObj() { } protoObj.prototype = new DOMTokenList(); }
			finally { allowTokenListConstruction = 0; }
			let protoObjProto = protoObj.prototype, resTokenList = new protoObj();
			a: for (var toks = ele.className.trim().split(wsRE), cI = 0, cLen = toks.length, sub = 0; cI !== cLen; ++cI) {
				for (let innerI = 0; innerI !== cI; ++innerI) if (toks[innerI] === toks[cI]) { sub++; continue a; }
				this[cI - sub] = toks[cI];
			}
			protoObjProto.length = Len - sub, protoObjProto.value = ele.className, protoObjProto[" uCL"] = ele;
			if (defineProperty) {
				defineProperty(ele, "classList", { // IE8 & IE9 allow defineProperty on the DOM
					enumerable: 1, get: function () { return resTokenList; },
					configurable: 0, set: function (newVal) {
						skipPropChange = 1, ele.className = protoObjProto.value = (newVal += ""), skipPropChange = 0;
						let toks = newVal.trim().split(wsRE), oldLen = protoObjProto.length;
						a: for (var cI = 0, cLen = protoObjProto.length = toks.length, sub = 0; cI !== cLen; ++cI) {
							for (let innerI = 0; innerI !== cI; ++innerI) if (toks[innerI] === toks[cI]) { sub++; continue a; }
							resTokenList[cI - sub] = toks[cI];
						}
						for (let i = cLen - sub; i < oldLen; ++i) delete resTokenList[i]; //remove trailing indexs
					}
				}); defineProperty(ele, " uCLp", { // for accessing the hidden prototype
					enumerable: 0, configurable: 0, writeable: 0, value: protoObj.prototype
				}); defineProperty(protoObjProto, " uCL", {
					enumerable: 0, configurable: 0, writeable: 0, value: ele
				});
			} else { ele.classList = resTokenList, ele[" uCL"] = resTokenList, ele[" uCLp"] = protoObj.prototype; }
			srcEle.attachEvent("onpropertychange", whenPropChanges);
		}
		try { // Much faster & cleaner version for IE8 & IE9:
			// Should work in IE8 because Element.prototype instanceof Node is true according to the specs
			window.Object.defineProperty(window.Element.prototype, "classList", {
				enumerable: 1, get: function (val) {
					if (!hasOwnProp.call(window.Element.prototype, "classList")) polyfillClassList(this);
					return this.classList;
				},
				configurable: 0, set: function (val) { this.className = val; }
			});
		} catch (e) { // Less performant fallback for older browsers (IE 6-8):
			window[" uCL"] = polyfillClassList;
			// the below code ensures polyfillClassList is applied to all current and future elements in the doc.
			document.documentElement.firstChild.appendChild(document.createElement("style")).styleSheet.cssText = (
				"_*{x-uCLp:expression(!this.hasOwnProperty(\"classList\")&&window[\" uCL\"](this))}" + //  IE6
				"[class]{x-uCLp/**/:expression(!this.hasOwnProperty(\"classList\")&&window[\" uCL\"](this))}" //IE7-8
			);
		}
	})(window);
	// 3. Patch in unsupported methods in DOMTokenList
	(function (DOMTokenListProto, testClass) {
		if (!DOMTokenListProto.item) DOMTokenListProto.item = function (i) {
			function NullCheck(n) { return n === void 0 ? null : n; } return NullCheck(this[i]);
		};

		if (!DOMTokenListProto.toggle || testClass.toggle("a", 0) !== false) DOMTokenListProto.toggle = function (val) {
			if (arguments.length > 1) return (this[arguments[1] ? "add" : "remove"](val), !!arguments[1]);
			let oldValue = this.value;
			return (this.remove(oldValue), oldValue === this.value && (this.add(val), true) /*|| false*/);
		};
		if (!DOMTokenListProto.replace || typeof testClass.replace("a", "b") !== "boolean")
			DOMTokenListProto.replace = function (oldToken, newToken) {
				checkIfValidClassListEntry("replace", oldToken), checkIfValidClassListEntry("replace", newToken);
				let oldValue = this.value;
				return (this.remove(oldToken), this.value !== oldValue && (this.add(newToken), true));
			};
		if (!DOMTokenListProto.contains) DOMTokenListProto.contains = function (value) {
			for (let i = 0, Len = this.length; i !== Len; ++i) if (this[i] === value) return true;
			return false;
		};
		if (!DOMTokenListProto.forEach) DOMTokenListProto.forEach = function (f) {
			if (arguments.length === 1) for (var i = 0, Len = this.length; i !== Len; ++i) f(this[i], i, this);
			else for (var i = 0, Len = this.length, tArg = arguments[1]; i !== Len; ++i) f.call(tArg, this[i], i, this);
		};
		if (!DOMTokenListProto.entries) DOMTokenListProto.entries = function () {
			let nextIndex = 0, that = this;
			return {
				next: function () {
					return nextIndex < that.length ? { value: [nextIndex, that[nextIndex]], done: false } : { done: true };
				}
			};
		};
		if (!DOMTokenListProto.values) DOMTokenListProto.values = function () {
			let nextIndex = 0, that = this;
			return {
				next: function () {
					return nextIndex < that.length ? { value: that[nextIndex], done: false } : { done: true };
				}
			};
		};
		if (!DOMTokenListProto.keys) DOMTokenListProto.keys = function () {
			let nextIndex = 0, that = this;
			return {
				next: function () {
					return nextIndex < that.length ? { value: nextIndex, done: false } : { done: true };
				}
			};
		};
	})(window.DOMTokenList.prototype, window.document.createElement("div").classList);
})(window);

//:scope polyfill
(function (doc, proto) {
	try { // check if browser supports :scope natively
		doc.querySelector(":scope body");
	} catch (err) { // polyfill native methods if it doesn't
		["querySelector", "querySelectorAll"].forEach(function (method) {
			let nativ = proto[method];
			proto[method] = function (selectors) {
				if (/(^|,)\s*:scope/.test(selectors)) { // only if selectors contains :scope
					let id = this.id; // remember current element id
					this.id = "ID_" + Date.now(); // assign new unique id
					selectors = selectors.replace(/((^|,)\s*):scope/g, "$1#" + this.id); // replace :scope with #ID
					let result = doc[method](selectors);
					this.id = id; // restore previous id
					return result;
				} else {
					return nativ.call(this, selectors); // use native code for other selectors
				}
			};
		});
	}
})(window.document, Element.prototype);

// NodeList.forEach polyfill
(function NodeList_ForEach_Polyfill() {
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
})();

// Element.matches polyfill
if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.matchesSelector ||
		Element.prototype.mozMatchesSelector ||
		Element.prototype.msMatchesSelector ||
		Element.prototype.oMatchesSelector ||
		Element.prototype.webkitMatchesSelector ||
		function (s) {
			let matches = (this.document || this.ownerDocument).querySelectorAll(s),
				i = matches.length;
			while (--i >= 0 && matches.item(i) !== this) { }
			return i > -1;
		};
}

// Optimised resize: https://developer.mozilla.org/en-US/docs/Web/Events/resize
(function () {
	let throttle = function (type, name, obj) {
		obj = obj || window;
		let running = false;
		let func = function () {
			if (running) { return; }
			running = true;
			requestAnimationFrame(function () {
				obj.dispatchEvent(new CustomEvent(name));
				running = false;
			});
		};
		obj.addEventListener(type, func);
	};

	/* init - you can init any event */
	throttle("resize", "optimizedResize");
})();

// Closest polyfill: IE11 https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.matches)
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
	Element.prototype.closest = function (s) {
		let el = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};

function greCaptchaV2Callback(response) {
	if (!grecaptcha)
		return;

	const error = document.querySelector(".g-recaptcha-error");
	if (error)
		error.classList.remove("field-validation-error");

	document.querySelectorAll(".g-recaptcha-container").forEach((container, key) => {
		if (grecaptcha.getResponse(key) === response) {
			container.classList.remove("error");
			return;
		}
	});
}

//App4Less: top.postMessage({'url' : 'url_we_want_to_go'}, '*');
window.addEventListener("message", function (event) {
	if ((event.origin !== "https://app.reskyt.com" && event.origin.indexOf(".conforama.") === -1) || !event.data)
		return;

	if (event.data.url)
		window.open(event.data.url, event.data.target || "_self");
}, false);

function popupShow(section) {
	let holder = $(this).closest(".modal");
	if (holder.length == 0) {
		holder = $(this).closest(".container");
	}
	if (holder.length == 0) {
		holder = $("body");
	}
	$(holder).find("[data-section]").hide();
	$(holder).find("[data-section][data-cond-par=" + section + "]").show();

}

function initModalMenu(popupID) {
	$(popupID + " [data-par]").removeClass("active");
	$($(popupID + " [data-par]")[0]).addClass("active");
}

function popupMenuClick() {
	$(this).closest(".modal").find("[data-par]").removeClass("active");
	$(this).addClass("active");
	let section = $(this).attr("data-par");
	popupShow(section);
	return false;
}

export {
	processCookiesPolicyLoadMobile,
	processCookiesPolicyLinkClick,
	popupShow
}
