﻿//var HeaderScript = function () {

$(".liofferstab").on("mouseover", function () {
    let hovcol = $(this).find("> a").data("hovercolor");
    $(this).css("background-color", hovcol);
}).on("mouseout", function () {
    $(this).css("background-color", "");
});

function deleteItem() {

    let row = $(this).closest("li");
    let itemReference = $(row).find(".itemReference").val();

    let isGift = $(row).attr("data-isgift").toLowerCase() == "true";
    // console.log("Item Reference to delete : " + itemReference);
    if (itemReference != "") {
        //call ajax query
        $.post("/ShoppingCart/Step1DeleteItem",
            {
                "itemReference": itemReference,
                "isGift": isGift
            },
        function (data) {
            updateHeaderCartWrapper(data.TopCartContentResponse.Content);
            populateStatusMessage(data);
            $(".top-cart-wrapper").popover("hide");
            initTopCartPopOver();
        });
    }
    return false;
}
function populateStatusMessage(data) {
    //console.log("Status : " + data.StatusAsString);
    //console.log("Possible status Html : " + data.RenderedStatus);
    if (isSuccessful(data)) {
        //console.log("Message is successful!");
        $("#status-message").html("");
    }
    else {
        // console.log("Not successful. Html will be :" + data.RenderedStatus);
        $("#status-message").html(data.RenderedStatus);
    }
}
function getRowByItemReference(itemReference) {
    //console.log("getting row : " + itemReference);
    //return $("tr.cart_item[data-itemReference='" + itemReference + "']");
    return $("input[value='" + itemReference + "']").closest("li");
}
function populateStatusMessage(data) {
    //console.log("Status : " + data.StatusAsString);
    //console.log("Possible status Html : " + data.RenderedStatus);
    if (isSuccessful(data)) {
        //console.log("Message is successful!");
        $("#status-message").html("");
    }
    else {
        //console.log("Not successful. Html will be :" + data.RenderedStatus);
        $("#status-message").html(data.RenderedStatus);
    }
}
function updateCartHeader(data) {
    updateHeaderCartWrapper(data.TopCartContentResponse.Content);
}
function updateCartHeaderTotal(total) {
    $(".top-cart-wrapper .title-count").html(total);
}
function isSuccessful(data) {
    return data.StatusAsString == "Successful";
}
function initTopCartPopOver() {
    if ($(".top-cart-content-contain")[0]) {
        $(".top-cart-wrapper").popover(
                    {
                        trigger: "hover click",
                        content: function () { return $(".top-cart-content-contain").html(); },
                        html: true,
                        placement: "bottom",
                        delay: { show: 50, hide: 50 }
                    }
                ).on("shown.bs.popover", function () {
                    initialiseHeaderCart();
                });
    }
}
function initAccountPopOver() {
    let tDown = ".user-account[data-toggle=popover] .triangle-down";
    let tUp = ".user-account[data-toggle=popover] .triangle-up";
    if ($(".login-section")[0]) {
        $(".user-account-contain .user-account[data-toggle=popover]").popover(
            {
                trigger: "hover focus",
                content: function () { return $(".login-section")[0].outerHTML; },
                html: true,
                placement: "bottom",
                delay: { show: 50, hide: 50 }
            }
        ).on("shown.bs.popover", function () {
            $(tDown).addClass("hidden-xs");
            $(tUp).removeClass("hidden-xs");

            // Tablet
            $(tDown).addClass("hidden-sm");
            $(tUp).removeClass("hidden-sm");

            // Tablet
            $(tDown).addClass("hidden-md");
            $(tUp).removeClass("hidden-md");
        }).on("hidden.bs.popover", function () {
            $(tDown).removeClass("hidden-xs");
            $(tUp).addClass("hidden-xs");

            // Tablet
            $(tDown).removeClass("hidden-sm");
            $(tUp).addClass("hidden-sm");

            // Tablet
            $(tDown).removeClass("hidden-md");
            $(tUp).addClass("hidden-md");
        });

        $(".mobile-search-contain .user-account[data-toggle=popover]").popover(
            {
                content: function () { return $(".login-section")[0].outerHTML; },
                html: true,
                placement: "bottom",
                delay: { show: 50, hide: 50 }
            }
        ).on("shown.bs.popover", function () {
            $(tDown).addClass("hidden-xs");
            $(tUp).removeClass("hidden-xs");

            // Tablet
            $(tDown).addClass("hidden-sm");
            $(tUp).removeClass("hidden-sm");

            // Tablet
            $(tDown).addClass("hidden-md");
            $(tUp).removeClass("hidden-md");
        }).on("hidden.bs.popover", function () {
            $(tDown).removeClass("hidden-xs");
            $(tUp).addClass("hidden-xs");

            // Tablet
            $(tDown).removeClass("hidden-sm");
            $(tUp).addClass("hidden-sm");

            // Tablet
            $(tDown).removeClass("hidden-md");
            $(tUp).addClass("hidden-md");
        });

        $(document).on("focus", ".login-section form input", function () {
            $(this).addClass("focused");
        }).on("blur", ".login-section form input", function () {
            $(this).removeClass("focused");
        });
    }
}
function initWebsitePopOver() {
    if ($(".language-section-wrapper")[0]) {
        $(".language-section-wrapper[data-toggle=popover]").popover(
            {
                trigger: "hover focus",
                content: function () { return $(".select-website-content")[0].outerHTML; },
                html: true,
                placement: "bottom",
                delay: { show: 50, hide: 50 }
            }
        ).click(function () {
            if ($(this).next("div.popover:visible").length)
                $(this).popover("hide");
            else
                $(this).popover("show");
        });
    }
}

function showTopCartPopOver() {
    $(".top-cart-wrapper").popover("show");
}
function closeTopCartPopover() {
    $(".top-cart-wrapper").popover("hide");
}
function initialiseHeader() {
    if ($("#header").length && window.header)
        return;

    initTopCartPopOver();
    initAccountPopOver();
    initWebsitePopOver();
    //$(".user-account-contain").hover(
    //    function () {
    //        $('.login-section').css('display', 'block');
    //    }, function () {
    //        $(".login-section").css('display', 'none');
    //    }
    //);

    //$(".user-account-contain").click(
    //    function () {
    //        // Mobile
    //        $(".mobile-user-menu").toggleClass("hidden-xs");
    //        $(".triangle-down").toggleClass("hidden-xs");
    //        $(".triangle-up").toggleClass("hidden-xs");

    //        // Tablet
    //        $(".mobile-user-menu").toggleClass("hidden-sm");
    //        $(".triangle-down").toggleClass("hidden-sm");
    //        $(".triangle-up").toggleClass("hidden-sm");

    //        // Tablet
    //        $(".mobile-user-menu").toggleClass("hidden-md");
    //        $(".triangle-down").toggleClass("hidden-md");
    //        $(".triangle-up").toggleClass("hidden-md");
    //    }
    //);
}

function loggedInToggle() {
    //check if not visible
    let a = " .user-account[data-loggedin] ";
    if (!$(".mobile-user-menu").is(":visible"))
    {
            
        $(a + ".triangle-down").addClass("hidden-xs");
        $(a + ".triangle-up").removeClass("hidden-xs");
            
        $(a + ".triangle-down").addClass("hidden-sm");
        $(a + ".triangle-up").removeClass("hidden-sm");
    }
    else
    {
        $(a + ".triangle-down").removeClass("hidden-xs");
        $(a + ".triangle-up").addClass("hidden-xs");

        $(a + ".triangle-down").removeClass("hidden-sm");
        $(a + ".triangle-up").addClass("hidden-sm");
    }
    $(".mobile-user-menu").slideToggle();
}

function initialiseHeaderCart() {
    $(".btn-remove").unbind("click", deleteItem);
    $(".btn-remove").bind("click", deleteItem);
    refreshGtmBindings();
}
function updateHeaderCartWrapper(content) {
    $(".top-cart-wrapper").html(content);
    initialiseHeaderCart(); //found in header.js
}
initialiseHeader();
//$(".btn-remove").bind("click", deleteItem);

$(window).on("resize", function () {
    if ($("#header").length && window.header)
        return;

    initTopCartPopOver();
    if ($(".popover-content .top-cart-content")[0]) {
        showTopCartPopOver();
    }
    initAccountPopOver();
    initWebsitePopOver();
});