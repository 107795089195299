﻿let popUpModalId = " .dynamic-popup-modal.modal ";
let htmlWaitingArea = "<div class='waiting-area'><div id='ajaxBusyIconWaiting'><img src='/Content/images/ajax-loader-white.gif' /> </div></div>";

let discountPopUpId = " #subscribe-modal ";
let discountPopUpModalWaitingArea = discountPopUpId +" .waiting-area";
let discountPopUpObjectModalButtonId = discountPopUpId + " .submit-form-btn";
let discountPopUpObjectModalMessageArea = discountPopUpId + " .message";
let discountPopUpFormId = discountPopUpId + " form";

$(document).ready(function () {
    let popUpModals = $(popUpModalId);
    if ($(popUpModals).length > 0 && !readCookie("ShowPopup")) {
        setTimeout(function () {
            $(popUpModals).each(function () {
                $(this).modal();
                $(this).modal("show");
                createCookie("ShowPopup", true, 15);
            });
        }, 7000);
    }
    InitDiscountPopUpObject();
});

function InitDiscountPopUpObject()
{
    let discountPopUpForm = $(discountPopUpFormId);
    if ($(discountPopUpForm).length > 0 )
    {
        $.validator.unobtrusive.parse(discountPopUpForm);
        $(discountPopUpObjectModalButtonId).bind("click", SubmitSubscribeForm);
    }

}

function SubmitSubscribeForm() {
    let discountPopUpForm = $(discountPopUpFormId);
    let url = $(discountPopUpForm).attr("action");
    if ($(discountPopUpForm).valid()) {
        $(discountPopUpObjectModalButtonId).hide();
        disableAjaxBusySettings();
        loadingAreas(discountPopUpModalWaitingArea);
        let hiddenShop = $("input[name=static-page-newsletter-shop]");
        let shopQueryString = "";
        if ($(hiddenShop).length > 0)
        {
            shopQueryString = "&Shop=" + $(hiddenShop).val();
        }
        $.post(url, $(discountPopUpForm).serialize() + shopQueryString,
           function (data) {
               removeloadingAreas(discountPopUpModalWaitingArea);
               ShowSubmitPopUpMessage(data.ReponseStatus.Message);
               if (data.StatusAsString == "Successful") {
                   $(discountPopUpId + " .main").html($(discountPopUpId + " .success").html());
                   $("html,body").animate({
                       scrollTop: 0
                   }, 1000);
               }
               createAjaxBusySettings();
           });
    }
    return false;
}
function loadingAreas(area) {
    $(area).html(htmlWaitingArea);
}
function removeloadingAreas(area) {
    $(area).html("");
}
function ShowSubmitPopUpMessage(message) {
    $(discountPopUpObjectModalButtonId).hide();
    $(discountPopUpObjectModalMessageArea).removeClass("hidden");
    $(discountPopUpObjectModalMessageArea).html(message);
    setTimeout(HideSubmitPopUpMessage, 3000);
}
function HideSubmitPopUpMessage() {
    $(discountPopUpObjectModalMessageArea).addClass("hidden");
    $(discountPopUpObjectModalButtonId).show();
}