import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import { modal } from "./modal";


class TermsAndConditionsPopup {
    private static _instance: TermsAndConditionsPopup;
    private _eventListenersAdded = false;

    private constructor() {
        document.addEventListener("click", (e: Event) => {
            if (e.target instanceof Element) {
                const target = e.target.closest("[data-terms-and-conditions-link]"); // Or any other selector.

                if (target) {
                    e.preventDefault();
                    let linkSection = target.getAttribute("data-terms-and-conditions-link");
                    this.ShowModal(linkSection);
                }
            }
        });
    }

    public Initialise() {
        if (document.getElementById("terms-and-conditions-xs") !== null) {
            let hash: string = null;
            if (window.location.hash) {
                hash = decodeURIComponent(window.location.hash.substring(1));
            }
            this.processTermsAndConditionsData(document.documentElement);
            this.processTermsAndConditionsLoad(document.documentElement, hash);
        }
    }

    public ShowModal(openElement:string = null) {
        let flag = "modal";
        if (window.innerWidth <= 767) {
            flag = "modal";
        }

        new Promise<void>((resolve, reject) => {
            let miModal = document.body.querySelector("#terms-and-conditions-xs__modal");
            if (miModal) {
                resolve();
                return;
            }

            Utilities.Fetch({
                url: `/StaticPage/ProcessTermsAndConditionsLinkClick?flag=${flag}`,
                success: (e) => {
                    let data: any = JSON.parse((e.target as XMLHttpRequest).response);
                    if (flag == "static") {
                        let urlHash = "";
                        if (openElement) {
                            urlHash = "#" + openElement;
                        }
                        window.open(data.Url + urlHash, "_blank");
                    }
                    else {
                        this.Modal_Response(data);
                    }
                    resolve();
                },
                error: (e) => reject(e)
            });
        })
            .then(() => {
                this.Modal_Show();
                this.processTermsAndConditionsLoad(document.getElementById("terms-and-conditions-xs__modal"), openElement);
            })
            .catch((e: any) => {
                if (e instanceof Error)
                    console.error(e, e.stack);
            });
    }

    private Modal_Response = ((data: any) => {
        if (!data)
            return;

        let dataElement = document.createElement("div");
        dataElement.innerHTML = data.Html;
        dataElement.id = "terms-and-conditions-xs__modal";
        this.processTermsAndConditionsData(dataElement);
        document.body.appendChild(dataElement);
    }).bind(this);

    private Modal_Show = (() => {
        modal.Show("terms-and-conditions-xs__modal");
    }).bind(this);

    private processTermsAndConditionsLoad = ((root: Element = document.documentElement, openElement: string = null) => {
        if (!this._eventListenersAdded) {
            root.querySelectorAll(".displayHidePar").forEach((el) => {
                el.addEventListener("click", function (event) {
                    (event.currentTarget as Element).querySelector(".arrow-up")?.toggle_element();
                    (event.currentTarget as Element).querySelector(".arrow-down")?.toggle_element();
                });
            });
            this._eventListenersAdded = true;
        }

        for (let el of root.querySelectorAll("[data-section-body-xs] [data-sections] [data-content]")) {
            el.classList.remove("in");
            let titleElement = root.querySelector(`[data-target="#${el.id}"]`);
            titleElement.setAttribute("aria-expanded", "false");
            titleElement.querySelector(".arrow-up")?.hide_element();
            titleElement.querySelector(".arrow-down")?.show_element();
            root.querySelector(`[data-target="#${el.id}"]`).setAttribute("aria-expanded", "false");
            (el as HTMLElement).style.height = null;
            (el as HTMLElement).style.display = null;
        }

        if (openElement) {
            let hashElement = root.querySelector("[name=\"" + openElement + "\"]");
            if (hashElement) {
                let target = hashElement.getAttribute("data-target");

                root.querySelector(target)?.classList.add("in");
                hashElement.setAttribute("aria-expanded", "true");
                hashElement.querySelector(".arrow-up")?.show_element();
                hashElement.querySelector(".arrow-down")?.hide_element();

                if (document.readyState === "complete") {
                    hashElement.scrollIntoView({ block: "center" });
                }
                else {
                    let listener = function () {
                        window.removeEventListener("load", listener, false);
                        hashElement.scrollIntoView({ block: "center" });
                    };
                    window.addEventListener("load", listener, false);
                }
            }
        }
        else {
            let firstSection = root.querySelector("[data-section-body-xs] [data-sections] [data-content]");
            if (firstSection) {
                let titleElement = root.querySelector(`[data-target="#${firstSection.id}"]`);
                firstSection.classList.add("in");
                titleElement.setAttribute("aria-expanded", "true");
                titleElement.querySelector(".arrow-up").show_element();
                titleElement.querySelector(".arrow-down").hide_element();
            }
        }

    }).bind(this);

    private processTermsAndConditionsData = ((root: Element) => {

        root.querySelector("#conditons-xs")?.hide_element();
        [].forEach.call(root.getElementsByClassName("arrow-up"), (el: Element) => {
            el.hide_element();
        });
        [].forEach.call(root.getElementsByClassName("arrow-down"), (el: Element) => {
            el.show_element();
        });

        let section = 1;
        let sectionTemplateXS = "";
        let sectionTemplateXSSubSection = "";
        sectionTemplateXS = root.querySelector("[data-terms-and-conditions-templates] [data-section-template-xs] [data-section]").innerHTML;
        sectionTemplateXSSubSection = root.querySelector("[data-terms-and-conditions-templates] [data-section-template-xs] [data-sub-section]").innerHTML;

        //set popup title
        //document.body.querySelector("[data-policy-page] [data-page-title]").innerHTML = (document.querySelector("[data-cookies-policy-list] [data-page-title]").innerHTML);

        let section_body_element = root.querySelector("[data-section-body-xs] [data-sections]");
        if (section_body_element && section_body_element.children.length == 0) {

            for (let el of root.querySelectorAll("[data-terms-and-conditions-list] [data-section]")) {
                let title = el.querySelector("[data-title]").innerHTML;
                let content = el.querySelector("[data-content] [data-summary]").innerHTML;
                let sectionTemp = "";
                let subSection = 0;
                /* Creating for mobile screen*/
                sectionTemp = " [data-section-body-xs] [data-sections]";
                root.querySelector("[data-section-body-xs] [data-sections]").insertAdjacentHTML("beforeend", sectionTemplateXS);
                root.querySelector(sectionTemp + " [data-title-text][data-temp] ").innerHTML = title;
                root.querySelector(sectionTemp + " [data-content][data-temp] ").innerHTML = content;

                root.querySelector(sectionTemp + " [data-target][data-temp] ").setAttribute("name", title.toLowerCase().replace(/ /g, "-"));
                root.querySelector(sectionTemp + " [data-target][data-temp] ").setAttribute("aria-expanded", "false");

                let target = root.querySelector(sectionTemp + " [data-target][data-temp] ").getAttribute("data-target");
                root.querySelector(sectionTemp + " [data-target][data-temp] ").setAttribute("data-target", target + "" + section + "-mob" + (root == document.documentElement ? "" : "-modal"));

                let contentId = root.querySelector(sectionTemp + " [data-content][data-temp] ").getAttribute("id");
                root.querySelector(sectionTemp + " [data-content][data-temp] ").setAttribute("id", contentId + "" + section + "-mob" + (root == document.documentElement ? "" : "-modal"));

                el.querySelectorAll("[data-sub-section]").forEach(el => {
                    let subSectionTempHolder = sectionTemp + " [data-content][data-main][data-temp]";
                    //let titleSub = el.querySelector("[data-title]").innerHTML;
                    //let contentSub = el.querySelector("[data-content]").innerHTML;

                    el.querySelectorAll(subSectionTempHolder).forEach(el1 => {
                        el1.insertAdjacentHTML("beforeend", sectionTemplateXSSubSection);
                    });
                    //document.querySelector(subSectionTempHolder + " [data-title-text][data-temp] ").innerHTML = titleSub;
                    //document.querySelector(subSectionTempHolder + " [data-content][data-temp] ").innerHTML = contentSub;

                    let targetSub = root.querySelector(subSectionTempHolder + " [data-target][data-temp] ");
                    if (targetSub !== null) {
                        el.querySelector(subSectionTempHolder + " [data-target][data-temp] ").setAttribute("data-target", targetSub.getAttribute("data-target") + "-" + section + "-" + subSection + "-dtp");
                    }
                    let contentIdSub = root.querySelector(subSectionTempHolder + " [data-content][data-temp] ");
                    if (contentIdSub !== null) {
                        root.querySelector(subSectionTempHolder + " [data-content][data-temp] ").setAttribute("id", contentIdSub.getAttribute("id") + "-" + section + "-" + subSection + "-dtp");
                        root.querySelector(subSectionTempHolder + " [data-temp]").removeAttribute("data-temp");
                    }
                    subSection++;

                });
                /* Sub Sections */

                ///* Desktop Screen End */

                root.querySelectorAll("[data-section-body-xs] [data-temp]").forEach(el1 => {
                    el1.removeAttribute("data-temp");
                });
                root.querySelectorAll("[data-section-body-lg] [data-temp]").forEach(el1 => {
                    el1.removeAttribute("data-temp");
                });
                section++;
            }
        }
        root.querySelector(".displayConditions.show-me-xs")?.addEventListener("click", function () {
            // $("#tarjeta-main-content").hide();
            root.querySelector("#terms-and-conditions-xs")?.show_element();
            document.querySelector("html,body")?.animate({
                scrollTop: 0
            }, 1000);
            return false;
        });

        root.querySelector("[data-terms-and-conditions-list]")?.hide_element();

        [].forEach.call(root.querySelectorAll("[data-section-body-xs]"), (el: Element) => {
            el.show_element();
        });
    }).bind(this);

    public static get Instance() {
        return (this._instance || (this._instance = new this()));
    }
}
export let termsAndConditionsPopup = (window as any).termsAndConditionsPopup;
if (!termsAndConditionsPopup) {
    termsAndConditionsPopup = TermsAndConditionsPopup.Instance;
    if (termsAndConditionsPopup) {
        termsAndConditionsPopup.Initialise();
        (window as any).termsAndConditionsPopup = termsAndConditionsPopup;
    }
}