import * as Variables from "./variables";
import * as Utilities from "./utilities";
import * as Enums from "./_enumerations";
import { miniCart } from "./mini-cart";
import { miniCart as miniCartV2 } from "./mini-cartV2";
import { pageMiniCart } from "./page-mini-cart";
import { mainMenu } from "./main-menu";
import { checkoutTotals } from "./checkoutTotals";
declare function refreshGtmBindings(...args: any[]): void;
declare let animateCSSGrid: any;
import { verticalMenu } from "./verticalMenu";
import { GtmAnalytics } from "./gtm";

export class Header {
    private readonly _Collapsed = "collapsed";
    private static _instance: Header;
    private static readonly _context: HTMLElement = document.getElementById("headerTop");
    private static readonly _header: HTMLElement = document.querySelector(".header-v2");
    private readonly _contextContainer = Header._context.firstElementChild;
    private readonly _logo = Header._context.querySelector(".logo") as HTMLElement;
    private readonly _cart = Header._context.querySelector(".cart") as HTMLElement;
    private readonly _user = Header._context.querySelector(".user") as HTMLElement;
    private readonly _back = Header._context.querySelector(".back") as HTMLElement;
    private readonly _dropdownUser = Header._context.querySelector(".dropdown-user") as HTMLElement;
    private readonly _mainSearch = Header._context.querySelector("#txtSearch") as HTMLInputElement;
    private readonly _search = document.querySelectorAll(".search") as NodeListOf<HTMLInputElement>;
    private readonly _stickySentinel: HTMLElement = document.getElementById("headerTopSentinel");
    private readonly _trustedShops = this._contextContainer.querySelector(".trustedShops") as HTMLElement;
    private readonly _trustedShopsBadge = document.getElementById("trustedShopsBadge-container");
    private readonly _ekomi = this._contextContainer.querySelector(".ekomi") as HTMLElement;
    private readonly _ekomiWidget = document.getElementById("widget-container");
    public readonly _menu = Header._context.querySelector(".menu");
    public readonly _menuV4 = this._contextContainer.querySelector(".menuV4");
    private readonly _animate_end_evt: Event;
    private _io: IntersectionObserver;

    private constructor() {
        this.BasketCount_Request();

        if (typeof animateCSSGrid === "object") {
            try {
                animateCSSGrid.wrapGrid(this._contextContainer, {
                    duration: 350,
                    easing: "linear",
                    onEnd: this.Animate_End
                });
            } catch (ex) {
                console.error(ex, ex.stack);
            }
            Header._context.querySelectorAll(".item").forEach((item) => item.addEventListener("transitionend", (e) => this.Item_TransitionEnd(e as TransitionEvent)));
        }

        if (this._back != undefined) {
            if (document.referrer == "") {
                this._contextContainer.classList.add("hiddenBack");
            }
            else {
                this._back.addEventListener("click", () => window.history.back());
            }
        }

        this._animate_end_evt = new CustomEvent("animate_end");

        this._stickySentinel.style.height = `${Header._header.offsetHeight}px`;

        this._menu.addEventListener("click", () => this.Menu_Click());
        this._menuV4?.addEventListener("click", () => this.MenuV4_Click());
        this._cart.addEventListener("click", (e) => this.Basket_Click(e));
        this._search.forEach(s => {
            s.addEventListener("keypress", (e) => this.Search_KeyPress(e));
            s.addEventListener("click", () => this.SearchTrigger_Click());
        });
        Header._context.querySelector(".search-bar button").addEventListener("click", () => this.Search());

        if (Variables.notTouch) {
            Header._context.querySelectorAll(".dropdown").forEach((dropdown) => {
                const parent = dropdown.parentElement;
                parent.addEventListener("mouseenter", (e) => this.Dropdown_Show(e));
                parent.addEventListener("mouseleave", (e) => this.Dropdown_Hide(e));
            });
        }

        if (typeof IntersectionObserver === "function" && !Header._header.classList.contains("checkout")) {
            this._io = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    this.ToggleCollapse(entry.intersectionRatio < 1);
                });
            }, { threshold: [1] });
            this._io.observe(this._stickySentinel);
        }

        const screen_Sm_Lower = Variables.screen(Enums.Screen.sm, Enums.ScreenBoundary.lower);
        screen_Sm_Lower.addListener((e) => this.Screen_Sm_Lower_Change(e.matches));
        this.Screen_Sm_Lower_Change(screen_Sm_Lower.matches);

        //const screen_Md_Lower = Variables.screen(Variables.Screen.md, Variables.ScreenBoundary.lower);
        //screen_Md_Lower.addListener((e) => this.Screen_Md_Lower_Change(e.matches));
        //this.Screen_Md_Lower_Change(screen_Md_Lower.matches);
    }

    private Animate_End = (() => {
        if (this._trustedShopsBadge &&
            !this._trustedShops.contains(this._trustedShopsBadge) &&
            Header._header.classList.contains(this._Collapsed)) {

            this._trustedShops.appendChild(this._trustedShopsBadge);
            this.ShowTrustedShopsBadge();
        }

        if (this._ekomi && this._ekomiWidget &&
            !this._ekomi.contains(this._ekomiWidget) &&
            Header._header.classList.contains(this._Collapsed)) {

            this._ekomi.appendChild(this._ekomiWidget);
            this.ShowEkomiWidget();
        }

        dispatchEvent(this._animate_end_evt);
    }).bind(this);

    private Screen_Sm_Lower_Change(matches: boolean) {
        if (matches) {
            this._user.onclick = undefined;
        } else {
            this._user.onclick = (e) => this.User_Click(e);

            if (this._dropdownUser) {
                const ddUserContainer = this._dropdownUser.parentElement;
                const ddUserClose = ddUserContainer.querySelector(".btn-close") as HTMLElement;
                if (ddUserClose)
                    ddUserClose.onclick = this.User_Click;
            }
        }
    }

    //private Screen_Md_Lower_Change(matches: boolean) {
    //    document.documentElement.classList.toggle("noscroll", this._menu.classList.contains("open") && !matches);
    //}

    private Search_KeyPress(e: Event) {
        if ((e as KeyboardEvent).key !== "Enter")
            return;

        this.Search((e.target as HTMLInputElement));
    }

    private Search(el: HTMLInputElement = this._mainSearch) {
        window.location.href = `/catalogsearch/result?q=${el.value}`;
    }

    private Item_TransitionEnd(e: TransitionEvent) {
        if (e.propertyName != "visibility")
            return;

        this._contextContainer.classList.toggle("collapsed-text", this._contextContainer.classList.contains("collapsed"));
    }

    private User_Click = ((e: Event) => {
        if (!this._dropdownUser)
            return;

        this._contextContainer.classList.toggle("dd-user");
        e.preventDefault();
    }).bind(this);

    public Menu_Click() {
        if (mainMenu) {
            if (!mainMenu._dropDownBlur)
                this._menu.classList.toggle("open", mainMenu.Toggle());
            else {
                this._menu.classList.remove("open");
                mainMenu._dropDownBlur = false;
            }
        }

        //document.documentElement.classList.toggle("noscroll", open);
    }

    public MenuV4_Click() {
        if (verticalMenu)
            verticalMenu.Show();
    }

    private SearchTrigger_Click() {
        this.ToggleCollapse(false, true);
        this._mainSearch.focus();
    }

    private Basket_Click(e: Event) {
        // RM63516: Skip mini cart show if we're in the mini cart page (app only)
        if (pageMiniCart)
            return;

        e.preventDefault();

        if (checkoutTotals) {
            checkoutTotals.Show();
            return;
        }

        if (miniCartV2)
            miniCartV2.Show();
        else if (miniCart)
            miniCart.Show();
    }

    private Dropdown_Show(e: Event) {
        const target = e.currentTarget as HTMLElement;
        target.classList.add("show");
    }

    private Dropdown_Hide(e: Event) {
        const target = e.currentTarget as HTMLElement;
        target.classList.remove("show");
    }
    
    private ToggleCollapse(force: boolean, comingFromSearch = false) {
        if (Header._header.classList.contains("checkout")) {
            this._contextContainer.classList.add("expand");
            return;
        }

        requestAnimationFrame(() => {
            Header._header.classList.toggle(this._Collapsed, force);
            if (this._logo) {
                requestAnimationFrame(() => {
                    this._logo.classList.toggle("fade-in", force);
                });
            }

            if (this._menu) {
                requestAnimationFrame(() => {
                    this._menu.classList.toggle("fade-in", force);
                });
            }
        });
        this._contextContainer.classList.toggle(this._Collapsed, force);
        if (typeof animateCSSGrid !== "object")
            this._contextContainer.classList.toggle("collapsed-text", this._contextContainer.classList.contains("collapsed"));

        if (!force && this._trustedShops && (!comingFromSearch || !Variables.tabletTrustedShopsBadge.matches)) {
            document.body.appendChild(this._trustedShopsBadge);
            this.ShowTrustedShopsBadge();
        }

        if (!force && this._ekomi && this._ekomiWidget && !comingFromSearch) {
            document.body.appendChild(this._ekomiWidget);
            this.ShowEkomiWidget();
        }

        if (force && this._trustedShopsBadge &&
            !this._trustedShops.contains(this._trustedShopsBadge)) {

            this._trustedShops.appendChild(this._trustedShopsBadge);
            this.ShowTrustedShopsBadge();
        }

        if (force && this._ekomi && this._ekomiWidget &&
            !this._ekomi.contains(this._ekomiWidget)) {

            this._ekomi.appendChild(this._ekomiWidget);
            this.ShowEkomiWidget();
        }

        if (this._logo) {
            const className = force ? "collapsed" : "uncollapsed";
            this._logo.querySelectorAll(`:scope > picture > source[data-${className}]`).forEach((source: HTMLSourceElement) => {
                source.srcset = source.dataset[className];
            });
        }
    }

    private ShowEkomiWidget() {
        this._ekomiWidget.classList.remove("fade-in");
        this._ekomiWidget.classList.remove("show");

        this._ekomiWidget.classList.add("show");
        requestAnimationFrame(() => {
            this._ekomiWidget.classList.add("fade-in");
        });
    }

    private ShowTrustedShopsBadge() {
        this._trustedShopsBadge.classList.remove("fade-in");
        this._trustedShopsBadge.classList.remove("show");

        this._trustedShopsBadge.classList.add("show");
        requestAnimationFrame(() => {
            this._trustedShopsBadge.classList.add("fade-in");
        });
    }

    public BasketCount_Request() {
        Utilities.Fetch({
            url: "/layout/getbasketcount",
            success: (e) => this.UpdateBasketCount(parseInt((e.target as XMLHttpRequest).responseText))
        });
    }

    public UpdateBasketCount(count: number): boolean {
        if (isNaN(count) || count < 0)
            return;

        sessionStorage.setItem("BasketCount", count.toString());

        const icon = (Header._context.querySelector(".cart").querySelector(".item > span") as HTMLElement);
        if (icon)
            icon.dataset.itemCount = count.toString();
    }

    public UpdateBasketModal(data: string, count = -1) {
        const html = document.createElement("div");
        html.innerHTML = data;

        // Get basket count section
        if (count == -1)
            count = parseInt(html.querySelector(".title-count").innerHTML);
        this.UpdateBasketCount(count);
    }

    public static get Context() {
        return this._header;
    }

    public static get Top() {
        return this._context;
    }

    public get Menu() {
        return this._menu;
    }

    public get User() {
        return this._user;
    }

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}

export let header: Header;
if (!(window as any).header)
    header = Header.Instance;

if (header) {
    (window as any).header = header;
    (window as any).updateCartHeaderTotal = function (total: number) { header.UpdateBasketCount(total); };
    (window as any).updateHeaderCartWrapper = function (content: string) { header.UpdateBasketModal(content); };
}