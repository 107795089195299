﻿import * as Utilities from "./utilities.ts";

$(document).ready(function () {

	refreshGtmBindings();

	// Tracking Errors - JQuery.Validate.Hooks
	$("form").addTriggersToJqueryValidate().triggerElementValidationsOnFormValidation();
	$("form").bind("formValidation", function (event, element, result) {
		if (!result) {
			let formName = $(element).prop("name");
			$(element).find("select.input-validation-error,input.input-validation-error").each(function () {
				pushError(formName, $(this));
				//console.log("Validation fail for: " + formName + " - " + $(this).prop("name"));
			});
		}
	});

	// breakpoint
	//if ($(this).width() < 1024)
	//    dataLayer.push({
	//        'breakPoint': 'S'
	//    });
	//else
	//    dataLayer.push({
	//        'breakPoint': 'M'
	//    });
	if (DataList.PageType == "Home")
		window.addEventListener("scroll", scrollHandler);

	window.addEventListener("message", function (event) {
		let data;

		try {
			data = JSON.parse(event.data);

			if (event.origin.indexOf("tiendeo") < 0 || data.type != "trakkFrame") return; // add correct iframe origin

		} catch (e) { return; }

		if (typeof data.eventLabel != "undefined" && data.eventLabel == "")
			return;

		dataLayer.push(data);
	});

});

$(window).on("load", function () {
	refreshGtmBindings();
	Utilities.createCookie("AdGTMValue", window["_adblocker"] ? "false" : "true", 1);
});

$.fn.isInViewport = function () {
	let distance = this[0].getBoundingClientRect();
	return (
		distance.top >= 0 &&
		distance.left >= 0 &&
		distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		distance.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

function scrollHandler(e) {
	$(".swiper-wrapper a").each(function () {
		if ($(this).isInViewport()) {
			gtmImpressionPromotion(this);
		}
	});
}

let setTimeoutHover;

function refreshGTMBindingsShopSelection() {
	$("[data-gtm-click-shopavailability=true]").off("click", gtmClickShopAvailability);
	$("[data-gtm-click-shopavailability=true]").firstOn("click", gtmClickShopAvailability);

	$("[data-gtm-click-start-shop-availability=true]").off("click", gtmVirtualPageViewStoreSelect);
	$("[data-gtm-click-start-shop-availability=true]").firstOn("click", gtmVirtualPageViewStoreSelect);

}
function refreshGtmBindings() {
	refreshGTMBindingsShopSelection();
	//$("[data-gtm-click-promo=true]").off("click", gtmClickPromotion);
	//$("[data-gtm-click-promo=true]").firstOn("click", gtmClickPromotion);

	//$("[data-gtm-click-additemtocart=true]").off("click", gtmClickAddItemToCart);
	//$("[data-gtm-click-additemtocart=true]").firstOn("click", gtmClickAddItemToCart);

	//$("[data-gtm-click-product=true]").off("click", gtmClickProduct);
	//$("[data-gtm-click-product=true]").firstOn("click", gtmClickProduct);

	//$("[data-gtm-click-removeitemfromcart=true]").off("click", gtmClickRemoveItemFromCart);
	//$("[data-gtm-click-removeitemfromcart=true]").firstOn("click", gtmClickRemoveItemFromCart);

	$("[data-gtm-click-selectfilter]").off("click", gtmClickFilter);
	$("[data-gtm-click-selectfilter]").firstOn("click", gtmClickFilter);

	$(".orderBy .dropdown-menu .inner li").off("click", gtmClickFilter);
	$(".orderBy .dropdown-menu .inner li").firstOn("click", gtmClickFilter);

	$(".pageSize .dropdown-menu .inner li").off("click", gtmClickPageSize);
	$(".pageSize .dropdown-menu .inner li").firstOn("click", gtmClickPageSize);

	$("#bannerMap form [data-id=\"community-list\"] +").find("li").off("click", gtmRegionSelector);
	$("#bannerMap form [data-id=\"community-list\"] +").find("li").firstOn("click", gtmRegionSelector);

	$("#bannerMap form [data-id=\"Shop\"] +").find("li").off("click", gtmStoreSelector);
	$("#bannerMap form [data-id=\"Shop\"] +").find("li").firstOn("click", gtmStoreSelector);

	$("[data-gtm-click-eventtracking]").off("click", gtmEventTracking);
	$("[data-gtm-click-eventtracking]").firstOn("click", gtmEventTracking);

	$("[data-gtm-click-servicetracking]").off("click", gtmServiceTracking);
	$("[data-gtm-click-servicetracking]").firstOn("click", gtmServiceTracking);

	$("[data-gtm-contact-track]").off("click", gtmContactTracking);
	$("[data-gtm-contact-track]").firstOn("click", gtmContactTracking);

	$("[data-gtm-click-virtualpv]").off("click", gtmVirtualPageviews);
	$("[data-gtm-click-virtualpv]").firstOn("click", gtmVirtualPageviews);

	$("a[data-gtm-click-createaccount=true]").off("click", gtmCreateAccount);
	$("a[data-gtm-click-createaccount=true]").firstOn("click", gtmCreateAccount);

	// Email links
	$("a[href^=\"mailto:\"]").off("click", gtmClickEventMailto);
	$("a[href^=\"mailto:\"]").firstOn("click", gtmClickEventMailto);

	// Phone links
	$("a[href^=\"tel:\"]").off("click", gtmClickEventPhone);
	$("a[href^=\"tel:\"]").firstOn("click", gtmClickEventPhone);

	// Downloadable links
	$("a[target=_blank][href$='.pdf']").off("click", gtmClickEventDownload);
	$("a[target=_blank][href$='.pdf']").firstOn("click", gtmClickEventDownload);

	// Outbound links
	let currLoc = new RegExp(location.host);
	$("a").each(function () {
		let link = $(this).prop("href");
		if (link.length > 0
			&& !currLoc.test(link)
			&& !/mailto/i.test(link)
			&& !/javascript:/i.test(link)
			&& !/tel/i.test(link)) {
			$(this).off("click", gtmClickEventExternalLink);
			$(this).firstOn("click", gtmClickEventExternalLink);
		}
	});

	// Generic Event Click
	$("[data-gtm-event-click=true]").off("click", gtmClickEvent);
	$("[data-gtm-event-click=true]").firstOn("click", gtmClickEvent);

	// 
	$("[data-toggle=tab][data-product-tab]").off("click", gtmClickProductTab);
	$("[data-toggle=tab][data-product-tab]").firstOn("click", gtmClickProductTab);

	//$(window).on("resize scroll", gtmListOfferCards);

	$("#bannerMap form").off("change", refreshGtmBindings);
	$("#bannerMap form").on("change", refreshGtmBindings);
}

function gtmListOfferCards() {

	if (Utilities.DataListObj.IsGA4Enabled)
		return;

	let impressionsToPush = [];
	Array.prototype.forEach.call($(".offer-card, .offer-grid > article, .nosto-carousel article"), function (e) {
		if ($(e).isInViewport() && typeof $(e).data("isGTMListed") == "undefined") {
			$(e).data("isGTMListed", "true");
			impressionsToPush.push($(e));
		}
	});
	if (impressionsToPush.length > 0) {
		let products = [];
		Array.prototype.forEach.call(impressionsToPush, function (e) {
			let prod = generateGTMProduct(e.find("a")[0]);

			let wrapper = $(e).find(".product-container-wrapper");
			if (!wrapper.is("a"))
				wrapper = $(e).find("a").eq(0);
			if (typeof wrapper.attr("data-gtm-detail-productlisting") == "undefined") {
				wrapper = $(e).find(".nosto-btn").eq(0);
				prod = generateGTMProduct(e.find(".nosto-btn")[0]);
			}

			prod.list = wrapper.attr("data-gtm-detail-productlisting");
			prod.position = parseInt(wrapper.attr("data-gtm-detail-position"));

			if (isNaN(prod.position))
				if (wrapper.attr("href").split("?").length > 1)
					if (wrapper.attr("href").split("?")[1].split("&pos=").length > 1)
						prod.position = wrapper.attr("href").split("?")[1].split("&pos=")[1];

			products.push(prod);
		});

		dataLayer.push({
			"event": "EECproductImpression",
			"ecommerce": {
				"currencyCode": "EUR",
				"impressions": products
			}
		});
	}
}

function gtmContactTracking() {

	if (!$("#contacto").valid())
		return true;

	let action = $("#contacto [data-id=\"InquiryType\"]").attr("title");
	let label = "";

	if ($("#shop-list-section").css("opacity") != "1") {
		label = "Online shop";
	}
	else {
		label = $("#shop-list-section [data-id=Shop]").attr("title");
	}

	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": "Contact submitted",
		"eventAction": action,
		"eventLabel": label
	});
}

function generateGTMProduct(target) {
	let gtmDetailPrice = $(target).attr("data-gtm-detail-price");
	if (gtmDetailPrice)
		gtmDetailPrice = parseFloat(gtmDetailPrice.replace(",", ".")).toFixed(2);

	let prod = {
		"name": $(target).attr("data-gtm-detail-name"),
		"id": $(target).attr("data-gtm-detail-id"),
		"price": gtmDetailPrice,
		"brand": $(target).attr("data-gtm-detail-brand"),
		"category": $(target).attr("data-gtm-detail-category"),
		"variant": $(target).attr("data-gtm-detail-variant")
	};
	let dimension8 = $(target).attr("data-gtm-detail-dimension8");
	if (dimension8) {
		prod["dimension8"] = dimension8;
	}
	let dimension14 = $(target).attr("data-gtm-detail-dimension14");
	if (dimension14) {
		prod["dimension14"] = dimension14;
	}
	let dimension126 = $(target).attr("data-gtm-detail-dimension126");
	if (dimension126) {
		prod["dimension126"] = dimension126;
	}
	let dimension1 = $(target).attr("data-gtm-detail-dimension1");
	if (dimension1) {
		prod["dimension1"] = dimension1;
	}
	let dimension2 = $(target).attr("data-gtm-detail-dimension2");
	if (dimension2) {
		prod["dimension2"] = dimension2;
	}
	let dimension18 = $(target).attr("data-gtm-detail-dimension18");
	if (dimension18) {
		prod["dimension18"] = dimension18;
	}
	let dimension19 = $(target).attr("data-gtm-detail-dimension19");
	if (dimension19) {
		prod["dimension19"] = dimension19;
	}
	let dimension20 = $(target).attr("data-gtm-detail-dimension20");
	if (dimension20) {
		prod["dimension20"] = dimension20;
	}
	let dimension21 = $(target).attr("data-gtm-detail-dimension21");
	if (dimension21) {
		prod["dimension21"] = dimension21;
	}
	let dimension24 = $(target).attr("data-gtm-detail-dimension24");
	if (dimension24) {
		prod["dimension24"] = dimension24;
	}
	let dimension25 = $(target).attr("data-gtm-detail-dimension25");
	if (dimension25) {
		prod["dimension25"] = dimension25;
	}
	let dimension127 = $(target).attr("data-gtm-detail-dimension127");
	if (dimension127) {
		prod["dimension127"] = dimension127;
	}
	let dimension128 = $(target).attr("data-gtm-detail-dimension128");
	if (dimension128) {
		prod["dimension128"] = dimension128;
	}
	let dimension129 = $(target).attr("data-gtm-detail-dimension129");
	if (dimension129) {
		prod["dimension129"] = dimension129;
	}
	let dimension130 = $(target).attr("data-gtm-detail-dimension130");
	if (dimension130) {
		prod["dimension130"] = dimension130;
	}
	let dimension135 = $(target).attr("data-gtm-detail-dimension135");
	if (dimension135) {
		prod["dimension135"] = dimension135;
	}
	let dimension137 = $(target).attr("data-gtm-detail-dimension137");
	if (dimension137) {
		prod["dimension137"] = dimension137;
	}
	let dimension139 = $(target).attr("data-gtm-detail-dimension139");
	if (dimension139) {
		prod["dimension139"] = dimension139;
	}
	let dimension140 = $(target).attr("data-gtm-detail-dimension140");
	if (dimension140) {
		prod["dimension140"] = dimension140;
	}
	let dimension142 = $(target).attr("data-gtm-detail-dimension142");
	if (dimension142) {
		prod["dimension142"] = dimension142;
	}
	let dimension143 = $(target).attr("data-gtm-detail-dimension143");
	if (dimension143) {
		prod["dimension143"] = dimension143;
	}
	let dimension153 = $(target).attr("data-gtm-detail-dimension153");
	if (dimension153) {
		prod["dimension153"] = dimension153;
	}
	let dimension154 = $(target).attr("data-gtm-detail-dimension154");
	if (dimension154) {
		prod["dimension154"] = dimension154;
	}
	let dimension158 = $(target).attr("data-gtm-detail-dimension158");
	if (dimension158) {
		prod["dimension158"] = dimension158;
	}
	let dimension159 = $(target).attr("data-gtm-detail-dimension159");
	if (dimension159) {
		prod["dimension159"] = dimension159;
	}
	let dimension160 = $(target).attr("data-gtm-detail-dimension160");
	if (dimension160) {
		prod["dimension160"] = dimension160;
	}
	let dimension164 = $(target).attr("data-gtm-detail-dimension164");
	if (dimension164) {
		prod["dimension164"] = dimension164;
	}
	let dimension166 = $(target).attr("data-gtm-detail-dimension166");
	if (dimension164) {
		prod["dimension166"] = dimension166;
	}
	let metric103 = $(target).attr("data-gtm-detail-metric103");
	if (metric103) {
		prod["metric103"] = metric103;
	}
	let metric104 = $(target).attr("data-gtm-detail-metric104");
	if (metric104) {
		prod["metric104"] = metric104;
	}

	return prod;
}
function getGTMProductListing(target) {
	return $(target).attr("data-gtm-detail-productlisting");
}
function getGTMPosition(target) {
	let pos = $(target).attr("data-gtm-detail-position");
	if (pos) {
		return parseFloat(pos.replace(",", "."));
	}
	else {
		return 1;
	}
}
function generateGTMCustomEvent(target) {
	let customEvent = {
		"category": $(target).attr("data-gtm-event-category"),
		"action": $(target).attr("data-gtm-event-action"),
		"label": $(target).attr("data-gtm-event-label"),
		"event": "eventga"
	};

	if (!customEvent["category"])
		customEvent["category"] = "Interaccion";

	return customEvent;
}

function generateGTMCustomEventSTHF(target) {
	return newcustomEvent = {
		"event": "gaEvent",
		"eventCategory": $(target).attr("data-gtm-event-category"),
		"eventAction": $(target).attr("data-gtm-event-action"),
		"eventLabel": $(target).attr("data-gtm-event-label")
	};
}

function gtmClickFilter() {
	let eventLabel = "";

	if (typeof $(this).attr("data-gtm-filter-value") != "undefined")
		eventLabel = $(this).attr("data-gtm-filter-value");
	else
		eventLabel = $(this).find(".text").html();

	let customEvent = generateGTMCustomEventSTHF($(".selectpicker.orderBy").find(":contains(" + eventLabel + ")")[0]);

	if (typeof customEvent.eventAction != "undefined")
		dataLayer.push(customEvent);
	else
		dataLayer.push({
			"event": "gaEvent",
			"eventCategory": "Product Filter",
			"eventAction": "Product Sorting",
			"eventLabel": "Select " + eventLabel
		});
}

function gtmVirtualPageviews() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + $(this).attr("data-gtm-overlayname") + "/"
	});
}

function gtmVirtualPageviewsClosed() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/",
		"csAction": "close-credit-overlay"
	});
}

function gtmVirtualPageViewCart() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/add-to-cart-overlay/"
	});
}

function gtmVirtualPageViewAvailabilityWarningCart() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/prd-no-availabile-overlay/"
	});
}

function gtmVirtualPageViewStoreSelect() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/availability-checker-overlay/"
	});
}

function gtmVirtualPageviewAvailabilityWarningClosed() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/",
		"csAction": "close-prd-no-availabile-overlay"
	});
}

function gtmVirtualPageviewCartClosed() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/",
		"csAction": "close-add-to-cart-overlay"
	});
}

function gtmVirtualPageviewStoreSelectClosed() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/",
		"csAction": "close-availability-checker-overlay"
	});
}

function gtmVirtualPageviewClosed(target) {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/",
		"csAction": $($(target)[0]).attr("data-gtm-click-virtualpv-close")
	});
}

function gtmVirtualAvailabilityClosed() {
	dataLayer.push({
		"event": "virtPath",
		"virtPath": "/virtPath" + window.location.pathname + "/",
		"csAction": "close-availability-checker-overlay"
	});
}


function gtmClickPageSize() {

	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": "Product List",
		"eventAction": "Number products per page",
		"eventLabel": $(this).find(".text").html()
	});
}

function gtmNewsletter_Success(email) {

	dataLayer.push({
		"newsletterSubscriber": "true",
		"userEmailId": email,
		"event": "gaEvent",
		"eventCategory": "Newsletter",
		"eventAction": "Register",
		"eventLabel": "Success"
	});
}

function gtmNewsletter_Error(message) {
	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": "Newsletter",
		"eventAction": "Register",
		"eventLabel": "Failure: Error Message: " + message
	});
}

function gtmEventTracking() {

	let value = $(this).attr("data-gtm-event-value");
	if (typeof $(this).attr("data-gtm-event-category") != "undefined" && $(this).attr("data-gtm-event-category") == "Product Filter"
		&& ($(this).attr("data-gtm-event-type") == "Brand" || $(this).attr("data-gtm-event-type") == "Size") || $(this).attr("data-gtm-event-type") == "Energy Rating") {
		if ($(this).parent().find("[checked]").length > 0)
			return true;

		Array.prototype.forEach.call($(this).parent().parent().parent().find("[checked] +"), function (e) {
			value += " | " + $(e).html().trim();
		});
	}

	if ($(this).slider && (typeof this.dataset.gtmEventType == "undefined" || this.dataset.gtmEventType == "Price")) {
		value = "Select " + $(this).slider("values").join("-");
	}

	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": $(this).attr("data-gtm-event-category"),
		"eventAction": $(this).attr("data-gtm-event-type"),
		"eventLabel": value
	});
}

function gtmServiceTracking() {

	if (this.checked)
		dataLayer.push({
			"event": "gaEvent",
			"eventCategory": $(this).attr("data-gtm-event-category"),
			"eventAction": $(this).attr("data-gtm-event-type"),
			"eventLabel": $(this).attr("data-gtm-event-value")
		});
}



function gtmProductList() {

}

function gtmClickPromotion(e) {

	if (typeof e.type != "undefined" && e.type.toLowerCase() == "click")
		e = e.currentTarget;

	if (typeof $(e).data("isGTMListed") == "undefined" && typeof $(e).attr("data-gtm-detail-name") != "undefined") {
		let promotions = [];
		let promotion = {
			"id": $(e).attr("data-gtm-detail-id"),
			"name": $(e).attr("data-gtm-detail-creative"),
			"creative": $(e).attr("data-gtm-detail-name"),
			"position": $(e).attr("data-gtm-detail-position"),
		};
		let dimension14 = $(e).attr("data-gtm-detail-dimension14");
		if (dimension14) {
			promotion["dimension14"] = dimension14;
		}
		promotions.push(promotion);


		//dataLayer.push({
		//    'event': 'EECpromotionImpression',
		//    'ecommerce': {
		//        'promoClick': {
		//            'promotions': promotions
		//        }
		//    }
		//    //,
		//    //'eventCallback': callback
		//    //    function () {
		//    //    document.location = '/compacto-manhattan.html';
		//    //}
		//});

		dataLayer.push({
			"event": "EECpromotionClick",
			"ecommerce": {
				"promoClick": {
					"promotions": promotions
				}
			}
		});

		$(e).data("isGTMListed", "true");
	}
}
function gtmImpressionPromotion(e) {

	if (typeof e != "undefined" && typeof e.type != "undefined" && e.type.toLowerCase() == "click")
		e = e.currentTarget;

	if (typeof $(e).data("isGTMImpressionListed") == "undefined" && typeof $(e).attr("data-gtm-detail-name") != "undefined") {
		let promotions = [];
		let promotion = {
			"id": $(e).attr("data-gtm-detail-id"),
			"name": $(e).attr("data-gtm-detail-creative"),
			"creative": $(e).attr("data-gtm-detail-name"),
			"position": $(e).attr("data-gtm-detail-position"),
		};
		let dimension14 = $(e).attr("data-gtm-detail-dimension14");
		if (dimension14) {
			promotion["dimension14"] = dimension14;
		}
		promotions.push(promotion);


		dataLayer.push({
			"event": "EECpromotionImpression",
			"ecommerce": {
				"promoView": {
					"promotions": promotions
				}
			}
		});

		$(e).data("isGTMImpressionListed", "true");
	}
}
function gtmClickProductTab() {

	let impressions = [];
	if ($(this).attr("data-tab-clicked").toLowerCase() == "false") {
		let productTabID = $($(this).attr("href"));
		$(productTabID).find("[data-gtm-impression=true]").each(function () {
			let prod = generateGTMProduct($(this));
			prod.list = $(this).attr("data-gtm-detail-productlisting");
			prod.position = parseInt($(this).attr("data-gtm-detail-position"));
			delete prod.variant;
			impressions.push(prod);
		});

		dataLayer.push({
			"event": "EECproductClick",
			"ecommerce": {
				"impressions": impressions
			}
		});
		$(this).attr("data-tab-clicked", "true");
	}

}

(function () {
	return window["_adblocker"] ? "false" : "true";
});

function gtmAddToWishList($addToWishlistElement) {
	let label = "";

	if (typeof $addToWishlistElement != "undefined"
		&& typeof $addToWishlistElement.dataset != "undefined"
		&& typeof $addToWishlistElement.dataset.gtmEventTitle != "undefined")
		label = $addToWishlistElement.dataset.gtmEventTitle;

	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": "Engagement",
		"eventAction": "Add to wishlist",
		"eventLabel": label
	});

}

function gtmStoreSelector() {
	let shop = $(this).find(".text").html();

	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": "Catalogs",
		"eventAction": "Store selected",
		"eventLabel": shop
	});
}

function gtmRegionSelector() {
	let community = $(this).find(".text").html();

	dataLayer.push({
		"event": "gaEvent",
		"eventCategory": "Catalogs",
		"eventAction": "Select region",
		"eventLabel": community
	});
}

function gtmCreateAccount() {

}

function gtmLoginAccount() {
}

function gtmLogoutAccount() {
	if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0].pageType != "undefined")
		dataLayer.push({
			"event": "gaEvent",
			"eventCategory": "Checkout",
			"eventAction": "Logout",
			"eventLabel": undefined
		});
}

function gtmClickProduct() {

	let prod = generateGTMProduct($(this));

	prod["position"] = getGTMPosition(this);

	if (isNaN(prod.position))
		if ($(this).attr("href").split("?").length > 1)
			if ($(this).attr("href").split("?")[1].split("&pos=").length > 1)
				prod.position = $(this).attr("href").split("?")[1].split("&pos=")[1];

	let eventName = "EECproductClick";

	//dataLayer.push({
	//    'event': eventName,
	//    'ecommerce': {
	//        "click": {
	//            'actionField': { 'list': getGTMProductListing(this) },
	//            'products': [prod]
	//        }
	//    }
	//});

	dataLayer.push({
		"event": eventName,
		"ecommerce": {
			"click": {
				"actionField": { "list": getGTMProductListing(this) },
				"products": [prod]
			}
		}
	});
}
function gtmClickStartShopAvailability() {

	dataLayer.push({
		"virtualUrl": "/ver-disponibilidad-en-tienda/",
		"event": "virtualurl"
	});
}
function gtmClickShopAvailability() {

	let radChecked = $("input[type=radio][data-gtm-click-shopavailability-detail=\"ident\"]:checked");

	let action = "";
	let label = "";

	if (radChecked) {
		let detail = $("input[type=radio][data-gtm-click-shopavailability-detail=\"ident\"]:checked + label [data-gtm-click-shopavailability-detail=\"value\"]");
		if ($(detail).is("select")) {
			action = "Tienda Favorita";
			label = $(detail).children(":selected").text();
		}
		else {
			action = "CP";
			label = $(detail).val();
		}

		let customEvent = generateGTMCustomEvent($(this));

		customEvent["category"] = "Consulta Disponibilidad";
		customEvent["action"] = action;
		customEvent["label"] = label;

		dataLayer.push(customEvent);
	}

}

function gtmClickAddItemToCart() {

	let prod = generateGTMProduct($(this));
	if (!prod["variant"]) {
		prod["variant"] = $("#configurable-picker option:selected").text();
		if (!prod["variant"])
			prod["variant"] = $(".offer-configurable-single-value").text();

		if (prod["variant"] == "") {
			delete prod["variant"];
		}
	}
	let quantityId = $(this).attr("data-gtm-detail-quantity-name");
	if (quantityId) {
		prod["quantity"] = parseInt($("input[name=" + quantityId + "]").val());
	}
	else if ($("input[name=quantity]").length != 0) {
		prod["quantity"] = parseInt($("input[name=quantity]").val());
	}
	else {
		prod["quantity"] = 1;
	}


	let eventCategory = "Product Detail Page";

	if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0]["pageType"] != "undefined"
		&& (dataLayer[0]["pageType"].toLowerCase() == "category" || dataLayer[0]["pageType"].toLowerCase() == "Search"))
		eventCategory = "Product List";

	if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0]["pageType"] != "undefined"
		&& dataLayer[0]["pageType"].toLowerCase() == "home")
		eventCategory = "Home Page";

	if (typeof dataLayer[0] != "undefined" && typeof dataLayer[0]["pageType"] != "undefined"
		&& dataLayer[0]["pageType"].toLowerCase() == "checkout")
		eventCategory = "Checkout Page";

	dataLayer.push({
		"event": "EECaddToCart",
		"ecommerce": {
			"currencyCode": "EUR",
			"add": {
				"products": [prod]
			}
		}
	});

	if (typeof prod != "undefined")
		dataLayer.push({
			"event": "gaEvent",
			"eventCategory": eventCategory,
			"eventAction": "Add to cart",
			"eventLabel": prod.name
		});
}

function gtmClickRemoveItemFromCart() {

	let prod = generateGTMProduct($(this));

	prod["position"] = parseFloat($(this).attr("data-gtm-detail-position").replace(",", "."));
	prod["quantity"] = parseInt($(this).attr("data-gtm-detail-quantity"));

	dataLayer.push({
		"event": "EECremoveFromCart",
		"ecommerce": {
			"currencyCode": "EUR",
			"remove": {
				"products": [prod]
			}
		}
	});

}

function gtmClickEventMailto() {

	let customEvent = generateGTMCustomEvent($(this));

	let mailto = this.href;
	let maildata = getMailto(mailto);

	customEvent["action"] = "MailTo";
	customEvent["label"] = maildata.email ? maildata.email : "";

	dataLayer.push(customEvent);
}

function gtmClickEventPhone() {

	let customEvent = generateGTMCustomEvent($(this));

	let phone = this.href;
	let number = phone.match(/tel:(.*)$/);
	number = number[1] ? number[1] : "";

	customEvent["action"] = "ClickToCall";
	customEvent["label"] = number;

	dataLayer.push(customEvent);
}

function gtmClickEventExternalLink() {

	let customEvent = generateGTMCustomEvent($(this));

	customEvent["action"] = "OutboundLink";
	customEvent["label"] = this.href;

	dataLayer.push(customEvent);
}

function gtmClickEventDownload() {

	let customEvent = generateGTMCustomEvent($(this));

	customEvent["action"] = "FileDownload";
	customEvent["label"] = this.href.split("/").reverse()[0];

	dataLayer.push(customEvent);
}

function gtmClickEvent() {

	let customEvent = generateGTMCustomEvent($(this));
	dataLayer.push(customEvent);
}

/* Helpers */

function getMailto(s) {
	let r = {};
	let email = s.match(/mailto:([^\?]*)/);
	email = email[1] ? email[1] : false;
	let subject = s.match(/subject=([^&]+)/);
	subject = subject ? subject[1].replace(/\+/g, " ") : false;
	let body = s.match(/body=([^&]+)/);
	body = body ? body[1].replace(/\+/g, " ") : false;

	if (email) { r["email"] = email; }
	if (subject) { r["subject"] = subject; }
	if (body) { r["body"] = body; }

	return r;
}

// Closure to pass Form Name parameter
function pushError(formName, $element) {

	let label = $element.data("gtm-fail-name");
	if (typeof label == "undefined" || label == "" || label == "Datos Personales - ")
		label = $($element).prop("name");

	let customEvent = {
		"event": "gaEvent",
		"eventCategory": "Checkout",
		"eventAction": "Form Error",
		"eventLabel": label
	};

	dataLayer.push(customEvent);
}

export {
	refreshGtmBindings,
	getGTMProductListing,
	getGTMPosition,
	gtmVirtualPageViewCart,
	gtmAddToWishList,
	gtmNewsletter_Success,
	gtmNewsletter_Error,
	generateGTMProduct,
	gtmVirtualPageviewClosed,
	gtmImpressionPromotion
};