﻿function validatePhone(obj) {

    $(obj.target).closest(".address-container").find("input[data-bind-validatephone=true]").removeData("previousValue");
    $(obj.target).closest(".address-container").find("input[data-bind-validatephone=true]").valid();
}

$(document).ready(function () {
    initialisePhoneItems();
});


function initialisePhoneItems() {
    $("input[data-bind-validatephone=true]").each(function () {
        $(this).firstOn("focusout", function () {
            this.value = $(this).val().replace(/[^0-9]/g, "");
        });
    });

    $("select[id$=PhonePrefix][data-bind-change-validatephone=true]").off("change", validatePhone);
    $("select[id$=PhonePrefix][data-bind-change-validatephone=true]").on("change", validatePhone);


    $("#input-basketTelephone").on("focusout", function (e) {
        const self = e.currentTarget;
        const mbWayPhone = $("#input-mbWayTelephone");
        if (mbWayPhone.length && self) {
            if (mbWayPhone.val().trim() == "" || !mbWayPhone.valid()) {
                mbWayPhone.val(self.value);
                requestAnimationFrame(function () { mbWayPhone.focusout(); });
            }
        }
    });
}

export {
    initialisePhoneItems
};