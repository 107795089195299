import * as Utilities from "./utilities";

export class CSCheckbox extends HTMLElement {
    private _checkbox: HTMLInputElement;
    private _label: HTMLElement;

    // Expose validation methods
    public setCustomValidity: any;
    public checkValidity: any;
    public validity: any;
    public validationMessage: any;

    constructor() {
        // Always call super first in constructor
        super();
    }

    protected attributeChangedCallback(name: string, _oldValue: string, _newValue: string) {
        switch (name) {
            case "checked":
                this.updateCheckbox();
                break;
            case "disabled":
                this.updateDisabled();
                break;
        }
    }

    protected connectedCallback() {
        // Check if select contents are already in place
        if (this.querySelector(":scope > label"))
            return;

        const checkboxTemplate = document.querySelector("#csCheckbox") as HTMLTemplateElement;
        const root = checkboxTemplate.content.cloneNode(true) as HTMLElement;

        this._checkbox = root.querySelector("input");
        this._label = root.querySelector(".label");

        // Checkbox
        for (let i = 0; i < this.attributes.length; i++) {
            const attr = this.attributes[i];
            this._checkbox.setAttribute(attr.name, attr.value);
        }

        // Label
        this._label.innerHTML = this.innerHTML;
        this.innerHTML = "";

        // Validation
        if (this._checkbox.hasAttribute("required")) {
            const validation = document.createElement("span");
            validation.classList.add("validation");
            this._label.after(validation);
        }
        this.setCustomValidity = this._checkbox.setCustomValidity.bind(this._checkbox);
        this.checkValidity = this._checkbox.checkValidity.bind(this._checkbox);
        this.validity = this._checkbox.validity;
        this.validationMessage = this._checkbox.validationMessage;

        this._checkbox.addEventListener("change", this.Checkbox_Change);

        this.updateDisabled();

        this.appendChild(root);
    }

    private Checkbox_Change = (() => {
        //this.dispatchEvent(new Event("change"));
    }).bind(this);

    private updateCheckbox() {
        if (!this._checkbox)
            return;

        this._checkbox.toggleAttribute("checked", this.hasAttribute("checked"));
    }

    private updateDisabled() {
        if (!this._checkbox)
            return;

        const disabled = this._checkbox.toggleAttribute("disabled", this.hasAttribute("disabled"));
        this.tabIndex = disabled ? -1 : 0;
    }

    public get checked() { return this._checkbox.checked; }
    public set checked(value: boolean) { this._checkbox.checked = value; }

    public get defaultChecked() { return this._checkbox.defaultChecked; }

    public get disabled() { return this._checkbox.disabled; }
    public set disabled(value: boolean) { this._checkbox.disabled = value; }

    public get checkbox() { return this._checkbox; }

    protected static get observedAttributes() { return ["checked", "disabled"]; }
}
if (typeof customElements !== "undefined")
    customElements.define("cs-checkbox", CSCheckbox);

if (typeof customElements === "undefined") {
    document.querySelectorAll("cs-checkbox").forEach(cschk => {
        const lbl = document.createElement("label");
        const chk = document.createElement("input");
        const txt = document.createTextNode(cschk.textContent);
        chk.type = "checkbox";

        for (let i = 0; i < cschk.attributes.length; i++) {
            const attr = cschk.attributes[i];
            chk.setAttribute(attr.name, attr.value);
        }

        lbl.appendChild(chk);
        lbl.appendChild(txt);
        cschk.parentElement.replaceChild(lbl, cschk);
    });
} 