import * as Variables from "./variables";
import * as Utilities from "./utilities";
import * as Enums from "./_enumerations";
import { isScrolledIntoView } from "./utilities";
import { header, Header } from "./headerV2";
import { Footer } from "./footer";

export class Menu {
    private static _instance: Menu;
    private static readonly _context: HTMLElement = document.getElementById("menuV3");
    private readonly _container: HTMLElement = Menu._context.querySelector(".menu-v3__container");
    private readonly _title: HTMLElement = this._container.querySelector(":scope > .title");
    private readonly _menu: HTMLElement = this._container.querySelector(":scope > ul");
    private readonly _menuItems = Array.from(this._menu.querySelectorAll(":scope > li"));
    private readonly _subMenus = this._menu.querySelectorAll(":scope > li li");
    private readonly _rightMenuItems: Array<Element> = [];
    private readonly _screen_Md_Lower = Variables.screen(Enums.Screen.md, Enums.ScreenBoundary.lower);
    private readonly _hoverSensitivityMs = 50;
    private _parentSubMenu: HTMLElement;
    private _hoverTimeoutHandle: number;

    private constructor() {
        this._menu.querySelectorAll(".close").forEach(_ => _.addEventListener("click", _ => this.ToggleShown(false)));

        const halfWindowWidth = document.documentElement.clientWidth / 2;
        let rightMenusFound = false;
        for (let i = 0; i < this._menuItems.length; i++) {
            const menuItem = this._menuItems[i];

            menuItem.addEventListener("click", this.Item_Click);

            menuItem.querySelectorAll("li").forEach(_ => _.addEventListener("click", this.Item_Click));

            if (!rightMenusFound) {
                const rect = menuItem.getBoundingClientRect();
                if (rect.left > halfWindowWidth) {
                    this._rightMenuItems = this._menuItems.slice(i);
                    rightMenusFound = true;
                }
            }
        }
        this._rightMenuItems.forEach(_ => _.classList.add("rtl"));

        this._title.addEventListener("click", _ => this.Back());

        this._screen_Md_Lower.addListener((e) => this.Screen_Md_Lower_Change(e.matches));
        this.Screen_Md_Lower_Change(this._screen_Md_Lower.matches, true);

        if (header)
            header.Menu.addEventListener("click", _ => this.ToggleMobile());
    }

    private GetLeafSubMenu(subMenu: HTMLElement, i = 1): HTMLElement {
        // Recursive safety check
        if (i > 5)
            return subMenu;

        const inner = subMenu.querySelector("ul, section") as HTMLElement;
        if (!inner)
            return subMenu;

        return this.GetLeafSubMenu(inner, ++i);
    }

    private GetTopMenuItem(subMenu: HTMLElement, i = 1): HTMLElement {
        // Recursive safety check
        if (i > 5)
            return subMenu;

        const parent = subMenu.parentElement.parentElement;
        if (!parent.classList.contains("show"))
            return subMenu;

        return this.GetTopMenuItem(parent, ++i);
    }

    private Screen_Md_Lower_Change(matches: boolean, fromCtor = false) {
        this._subMenus.forEach(_ => {
            _.removeEventListener("pointerover", this.Item_PointerOver);
            if (matches)
                _.addEventListener("pointerover", this.Item_PointerOver);
        });

        if (!fromCtor)
            this.ToggleMobile(false);
    }

    private Item_Click = ((e: Event) => {
        const item = e.currentTarget as HTMLElement;

        e.stopPropagation();

        const subMenu = item.querySelector(":scope > ul, :scope > section") as HTMLElement;
        if (!subMenu || e.target === subMenu)
            return;

        e.preventDefault();

        this.Show(item, subMenu);
    }).bind(this);

    private Item_PointerOver = ((e: PointerEvent) => {
        window.clearTimeout(this._hoverTimeoutHandle);

        if (e.pointerType !== "mouse")
            return;

        const item = e.currentTarget as HTMLElement;

        e.stopPropagation();

        const subMenu = item.querySelector(":scope > ul, :scope > section") as HTMLElement;
        if (!subMenu || e.target === subMenu)
            return;

        this._hoverTimeoutHandle = window.setTimeout(() =>
            this.Show(item, subMenu), this._hoverSensitivityMs);
    }).bind(this);

    private Body_MouseDown = ((e: Event) => {
        if (e.currentTarget !== e.target)
            return;

        this.ToggleShown(false);
    }).bind(this);

    private Back() {
        if (!this._parentSubMenu)
            return;

        const parent: HTMLElement = this._parentSubMenu == this._menu ? this._menu
            : this._parentSubMenu.closest(".out");

        this.Hide(parent);
    }

    private Show(item: HTMLElement, subMenu: HTMLElement) {
        if (item.classList.contains("show"))
            return;

        const parent = item.parentElement;
        parent.querySelectorAll(".show").forEach((item: HTMLElement) => this.HideItem(item));

        if (!this._screen_Md_Lower.matches) {
            parent.classList.add("out");

            const title = item.querySelector(":scope > a");
            if (!this._title.classList.toggle("hidden", !title))
                this._title.textContent = title.textContent;
        }

        this._parentSubMenu = parent;

        item.classList.add("show");
        requestAnimationFrame(_ => {
            if (this._screen_Md_Lower.matches) {
                const rect = subMenu.getBoundingClientRect();
                const docWidth = document.documentElement.clientWidth;
                const menuItem = this.GetTopMenuItem(item) as HTMLElement;
                const miSubMenu = menuItem.querySelector(":scope > ul, :scope > section") as HTMLElement;

                let oldTranslateX = 0;
                const match = /-?\d+/.exec(miSubMenu.style.transform);
                if (match && match.length)
                    oldTranslateX = parseInt(match[0]);

                let newTranslateX;
                if (rect.left < 0)
                    newTranslateX = Math.abs(Math.round(rect.left)) + oldTranslateX;
                else if (rect.right > docWidth)
                    newTranslateX = -Math.abs(Math.round(rect.right - docWidth)) + oldTranslateX;

                miSubMenu.style.transform = `translateX(${newTranslateX}px)`;

                if (menuItem == item)
                    requestAnimationFrame(_ => item.classList.add("fade"));
            }
            else
                this._menu.style.minHeight = subMenu.clientHeight + "px";
        });


        this.ToggleShown(true);
    }

    private Hide(parent?: HTMLElement) {
        window.clearTimeout(this._hoverTimeoutHandle);

        parent = parent || this._menu;
        parent.querySelectorAll(".show").forEach((item: HTMLElement) => this.HideItem(item, parent === this._menu));

        if (!this._screen_Md_Lower.matches)
            parent.classList.remove("out");

        this._parentSubMenu = parent;

        if (parent === this._menu) {
            this.ResetMobile();
        }
        else if (!this._screen_Md_Lower.matches) {
            const title = parent.parentElement.querySelector(":scope > a");
            if (!this._title.classList.toggle("hidden", !title))
                this._title.textContent = title.textContent;

            this._menu.style.minHeight = this._parentSubMenu.clientHeight + "px";
        }
    }

    private HideItem(item: HTMLElement, isMenu = false) {
        if (item.classList.contains("fade")) {
            const subMenu = item.querySelector(":scope > ul, :scope > section") as HTMLElement;
            const transitionEnd = () => {
                if (subMenu)
                    subMenu.style.removeProperty("transform");
                if (isMenu)
                    this._menu.style.removeProperty("--head-height");
                requestAnimationFrame(_ => item.classList.remove("show"));
                item.removeEventListener("transitionend", transitionEnd);
            };
            item.addEventListener("transitionend", transitionEnd);

            requestAnimationFrame(_ => item.classList.remove("fade"));
        }
        else if (item.classList.contains("show")) {
            item.classList.remove("show");
        }
    }

    private ToggleShown(force: boolean) {
        document.documentElement.classList.toggle("noscroll", force);

        if (!force) {
            this.Hide();
            this.ResetMobile();
            Menu._context.style.removeProperty("--head-height");
        }
        else {
            if (this._screen_Md_Lower.matches)
                this._menu.style.setProperty("--head-height", `${Header.Context.offsetHeight - 1}px`); // Remove 1px as offsetHeight gets ceil value
            else
                Menu._context.style.setProperty("--head-height", `${Header.Context.offsetHeight - 1}px`);
        }

        if (this._screen_Md_Lower.matches) {
            this.ToggleVeil(force);

            if (force)
                document.body.addEventListener("mousedown", this.Body_MouseDown);
            else
                document.body.removeEventListener("mousedown", this.Body_MouseDown);
        }
        else {
            this.ToggleVeil(false);
        }
    }

    private ToggleVeil(force: boolean) {
        if (force) {
            document.body.classList.remove("out");
            document.body.classList.add("veil-header");
        }
        else if (document.body.classList.contains("veil-header")) {
            const transitionEnd = () => {
                requestAnimationFrame(_ => {
                    document.body.classList.remove("out");
                    document.body.classList.remove("veil-header");
                });
                document.body.removeEventListener("transitionend", transitionEnd);
            };
            document.body.addEventListener("transitionend", transitionEnd);
            requestAnimationFrame(_ => document.body.classList.add("out"));
        }
    }

    private ResetMobile() {
        this._title.classList.add("hidden");
        this._menu.style.removeProperty("min-height");
    }

    public ToggleMobile(force?: boolean) {
        const shown = Menu._context.classList.toggle("show", force);
        header.Menu.classList.toggle("open", shown);

        this.ToggleShown(shown);

        return shown;
    }

    public static get Instance() {
        if (!this._context)
            return;

        if (!this._instance && (window as any).menu)
            this._instance = (window as any).menu;

        return this._instance || (this._instance = new this());
    }
}
export const menu = Menu.Instance;

if (menu) {
    (window as any).menu = menu;
}