import * as Utilities from "./utilities";
import * as Variables from "./variables";
declare let EmpathyX: any;
declare let InterfaceX: any;
declare global {
	interface Window { AddItemToCart: any; }
}

function initEmpathyX() {
	const initObj: any = {
		instance: Utilities.DataListObj.Empathy_Instance,
		lang: Utilities.DataListObj.LangCode.split("-")[0],
		store: Utilities.DataListObj.Empathy_Store,
		scope: Variables.mobile.matches ? "mobile" : "desktop",
		currency: "EUR",
		consent: true,
		//eventCallbacks: {
		//	add2cart: function (data: any) {
		//		console.log(data);
		//		window.AddItemToCart(data.groupId, data.productId, 1);
		//	}
		//}
	};

	if (!Utilities.DataListObj.Empathy_LiveMode)
		initObj.env = "staging";

	if (Utilities.DataListObj.JIRA_CNF20_Enabled) {
		if (typeof InterfaceX != "undefined") {
			InterfaceX.init(initObj);
			let searchBar = document.querySelector("#headerTop .container .search-bar #txtSearch") as HTMLElement;
			let searchButton = document.querySelector("#headerTop .container .search-bar button") as HTMLElement;

			searchBar.addEventListener("focusin", () => (window as any).InterfaceX.search());
			searchButton.addEventListener("click", () => (window as any).InterfaceX.search());
		}
	}
	else {
		if (typeof EmpathyX != "undefined")
			EmpathyX.init(initObj);
	}
}

if (Utilities.DataListObj.JIRA_CNF20_Enabled)
	(window as any).initX = initEmpathyX;
else
	(window as any).initEmpathyX = initEmpathyX;