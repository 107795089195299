import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import * as HeaderV2 from "./headerV2";
import { CSSelect } from "./csSelect";
import { app4Less } from "./app4Less";
import { miniCart } from "./mini-cartV2";

declare let cart: any;

class BuyBox {
    private static _instance: BuyBox;
    private static readonly _context: HTMLElement = document.getElementById("buy-box");
    protected readonly _container: HTMLElement = BuyBox._context.querySelector(".buy-box__container");
    protected _busyContainer = this._container;
    protected _lines = this._container.querySelector(".lines-container");
    private readonly _statusMessage = BuyBox._context.querySelector(".status-message");
    private _startX: number;
    private _currentX: number;
    private _touching: boolean;
    private _touchingThreshold: number = document.documentElement.clientWidth / 3;
    private readonly _update = this.Update.bind(this);

    private constructor() {
        BuyBox._context.addEventListener("mousedown", () => this.Hide());
        this._container.addEventListener("mousedown", (e) => e.stopPropagation());
        this._container.querySelectorAll(".close").forEach(close => close.addEventListener("click", () => this.Hide()));
        
        document.addEventListener("touchstart", (e) => this.BuyBox_TouchStart(e));
        document.addEventListener("touchmove", (e) => this.BuyBox_TouchMove(e));
        document.addEventListener("touchend", () => this.BuyBox_TouchEnd());

        const screen_Md_Lower = Variables.screen(Enums.Screen.md, Enums.ScreenBoundary.lower);
        screen_Md_Lower.addListener((e) => this.Screen_Md_Lower_Change(e.matches));
        this.Screen_Md_Lower_Change(screen_Md_Lower.matches);
    }

    private Screen_Md_Lower_Change(matches: boolean) {
        this._lines.querySelectorAll(":scope > * > .actions > .quantityV2").forEach((quantity: HTMLElement) => {
            this.Quantity_Screen_Md_Lower_Matches(matches, quantity);
        });
    }

    private InitialiseLines() {
        (Array.prototype.slice.call(this._lines.children) as Array<Node>).forEach((line) => {
            this.InitialiseLine(line as HTMLElement);
        });
    }

    private InitialiseLine(line: HTMLElement) {
        (line.querySelector(":scope > .actions > .quantityV2") as HTMLElement).onclick = (e) => this.Quantity_Click(e, line);
        (line.querySelector(":scope > .actions > .add-to-cart") as HTMLElement).onclick = (e) => this.AddToCart_Click(e, line);
    }

    private Quantity_Click = ((e: Event) => {
        const self = e.target as HTMLButtonElement;
        const currentTarget = e.currentTarget as HTMLElement;
        if (self == currentTarget)
            return;

        const isMinus = self.classList.contains("minus");
        const isPlus = self.classList.contains("plus");
        const quantityInput = currentTarget.querySelector(":scope > input[name=quantity]") as HTMLInputElement;
        if (!isMinus && !isPlus)
            return;
        const other = (isMinus ? currentTarget.querySelector(":scope .plus") : currentTarget.querySelector(":scope .minus")) as HTMLButtonElement;
        let intQuantity = parseInt(quantityInput.value) || 1;


        if (isMinus) {
            intQuantity--;
            if (intQuantity < 1)
                return;
            if (intQuantity <= 1)
                self.disabled = true;
        }
        else {
            intQuantity++;
            if (intQuantity > Utilities.DataListObj.MaxLineQuantity)
                return;
            if (intQuantity >= Utilities.DataListObj.MaxLineQuantity)
                self.disabled = true;
        }
        other.disabled = false;

        quantityInput.value = intQuantity.toString();
    }).bind(this);

    private AddToCart_Click = ((e: Event, line: HTMLElement) => {
        const quantityInput = (line.querySelector(":scope > .actions > .quantityV2 input[name=quantity]") as HTMLInputElement);

        if (!(this.Quantity_CheckValidity(quantityInput)))
            return;

        const addToCart = e.currentTarget as HTMLButtonElement;
        const quantity = quantityInput.value;

        addToCart.onclick = undefined;

        miniCart.AddToCart(
            line.dataset.productreference,
            line.dataset.itemreference,
            quantity,
            null,
            0,
            false,
            false,
            false,
            line.dataset.shopcode)
            .finally(() => {
                addToCart.onclick = (e) => this.AddToCart_Click(e, line);
            })
            .then((res: any) => {
                if (!this.IsSuccess(res)) {
                    this._statusMessage.innerHTML = res.RenderedStatus;
                    return;
                }
                if (res.ShoppingCartResponse && typeof cart === "object") {
                    if (cart.UpdateCart)
                        cart.UpdateCart(res.ShoppingCartResponse);
                    else
                        parent.location.reload();

                    return;
                }

                if (res.SimpleCartResponse) {
                    this.Hide();
                    miniCart.Show(res.SimpleCartResponse, line.dataset.offerId);
                }
            });
    }).bind(this);

    private Quantity_Screen_Md_Lower_Matches(matches: boolean, quantity: HTMLElement) {
        const quantityInput = quantity.querySelector(":scope > input[name=quantity]") as HTMLInputElement;
        if (matches) {
            quantityInput.readOnly = true;
            quantityInput.removeEventListener("keyup", this.Quantity_KeyUp);
            quantityInput.removeEventListener("focusout", this.Quantity_FocusOut);
        }
        else {
            quantityInput.readOnly = false;
            quantityInput.addEventListener("keyup", this.Quantity_KeyUp);
            quantityInput.addEventListener("focusout", this.Quantity_FocusOut);
        }
    }

    protected Quantity_KeyUp = ((e: KeyboardEvent) => {
        const quantityInput = e.currentTarget as HTMLInputElement;
        const quantity = quantityInput.parentElement;
        const intQuantity = parseInt(quantityInput.value) || 1;
        const minus = quantity.querySelector(":scope > .minus") as HTMLButtonElement;
        const plus = quantity.querySelector(":scope > .plus") as HTMLButtonElement;

        if (intQuantity <= 1) {
            minus.disabled = true;
            plus.disabled = false;
        }
        else if (intQuantity >= Utilities.DataListObj.MaxLineQuantity) {
            plus.disabled = true;
            minus.disabled = false;
        }
        else {
            plus.disabled = false;
            minus.disabled = false;
        }

        if (e.key == "Enter") {
            quantityInput.blur();
            return;
        }

        if (!this.Quantity_CheckValidity(quantityInput))
            return;
    }).bind(this);

    protected Quantity_FocusOut = ((e: Event) => {
        const quantityInput = e.currentTarget as HTMLInputElement;
        const quantity = quantityInput.parentElement;
        const line = quantity.parentElement;

        if (quantityInput.value === "")
            quantityInput.value = "1";

        if (!this.Quantity_CheckValidity(quantityInput))
            return;
    }).bind(this);

    private Quantity_CheckValidity = ((quantityInput: HTMLInputElement) => {
        const quantity = quantityInput.parentElement;

        if (quantityInput.checkValidity()) {

            this.SetValid(quantity);
            return 1;
        }

        let state = undefined;
        if (quantityInput.validity.rangeUnderflow)
            state = Utilities.ValidityStates.Rangeunderflow;
        else if (quantityInput.validity.rangeOverflow)
            state = Utilities.ValidityStates.Rangeoverflow;
        if (quantityInput.validity.badInput)
            state = Utilities.ValidityStates.Badinput;

        this.SetInvalid(quantity, state);

        return 0;
    }).bind(this);

    private SetValid(container: HTMLElement) {
        if (!container.classList.contains("error"))
            return;

        container.classList.remove("error");
        delete container.dataset.status;
        //this._deliveryTitle.innerHTML = this._deliveryTitle.dataset.title;
    }

    private SetInvalid(container: HTMLElement, state: Utilities.ValidityStates = Utilities.ValidityStates.Invalid, message?: string) {
        container.classList.add("error");
        delete container.dataset.status;
        //this.ToggleAddToCart(false);

        if (!message && state == Utilities.ValidityStates.Valuemissing)
            message = Utilities.DataListObj.ValidationMessage.Valuemissing;

        if (!message)
            message = container.dataset[`validity${Utilities.ValidityStates[state]}`];

        if (message)
            container.dataset.status = message;
    }

    public UpdateCartCount(count?: number): number {
        if (!isNaN(count))
            sessionStorage.setItem("BasketCount", count.toString());
        else
            count = parseInt(sessionStorage.getItem("BasketCount"));

        // previously selected ":scope > header > h2"
        //(this._container.querySelector(".mini-cart-h2") as HTMLElement).dataset.quantity = count.toString();
        if (HeaderV2.header)
            HeaderV2.header.UpdateBasketCount(count);

        return count;
    }

    private IsSuccess(res: any) {
        return (res.Status || res.StatusAsString) === "Successful";
    }

    public UpdateCart(res: any) {
        if (!this.IsSuccess(res)) {
            this._statusMessage.innerHTML = res.StatusMessage;
            return;
        }

        this._statusMessage.innerHTML = "";

        //this.UpdateCartCount(res.ItemsCount);

        //if (this.ToggleEmptyCart(!res.ItemsCount))
        //    return;

        if (res.LinesHtml) {
            // Refresh all lines
            this._lines = Utilities.ReplaceInnerHTML(this._lines, res.LinesHtml);
            this.InitialiseLines();
        }
    }

    protected ToggleEmptyCart(force: boolean): boolean {
        if (typeof force === "boolean") {
            if (force)
                this._container.classList.add("empty");
            else
                this._container.classList.remove("empty");

            return force;
        }

        return this._container.classList.toggle("empty", force);
    }

    private BuyBox_TouchStart(e: TouchEvent) {
        if (!BuyBox._context.classList.contains("show") || this._lines.contains((e.target as Node)))
            return;

        this._startX = e.touches[0].pageX;
        this._currentX = this._startX;

        this._touching = true;
        requestAnimationFrame(this._update);
    }

    private BuyBox_TouchMove(e: TouchEvent) {
        if (!this._touching)
            return;

        this._currentX = e.touches[0].pageX;

        e.preventDefault();
    }

    private BuyBox_TouchEnd() {
        if (!this._touching)
            return;

        this._touching = false;

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = "";

        if (translateX > this._touchingThreshold)
            this.Hide();
    }

    private Update() {
        if (!this._touching)
            return;

        requestAnimationFrame(this._update);

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = `translateX(${translateX}px`;
    }

    public Show = (itemReference: string, offerId: string) => this.Toggle(true, itemReference, offerId);

    public Hide = () => this.Toggle(false);

    public Toggle(show?: boolean, itemReference?: string, offerId?: string) {
        BuyBox._context.classList.toggle("show", show);
        document.documentElement.classList.toggle("noscroll", show);

        if (show) {
            if (!itemReference)
                return;

            const cartContents = this._lines.querySelector(":scope > article") as HTMLElement;
            this.BuyBox_Request(itemReference, offerId);
        }
        else {
            (document.activeElement as HTMLElement).blur();
        }
    }

    public BuyBox_Request(itemReference: string, offerId?: string) {
        const url = `/offer/loadbuybox?itemreference=${itemReference}&excludeOfferId=${offerId}`;

        Utilities.Fetch({
            url: url,
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response)),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            showBusy: true,
            busyContainer: this._lines as HTMLElement
        });
    }

    public get Context() {
        return BuyBox._context;
    }

    public set BusyContainer(container: HTMLElement) {
        this._busyContainer = container;
    }

    public static get Instance() {
        if (!this._context)
            return;

        if (!this._instance && (window as any).buyBox)
            this._instance = (window as any).buyBox;

        return this._instance || (this._instance = new this());
    }
}

export let buyBox = (window as any).buyBox;
if (!buyBox) {
    buyBox = BuyBox.Instance;
    if (buyBox) {
        (window as any).buyBox = buyBox;
    }
}