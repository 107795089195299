declare function requestIdleCallback(...args: any[]): void;

class LazyLoader {
    private static _instance: LazyLoader;
    private _io: IntersectionObserver;

    private constructor() {
        const config = {
            rootMargin: "50px 0px 50px 0px",
            threshold: 0
        };

        if (typeof IntersectionObserver === "function")
            this._io = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.LoadLazyElem(entry.target as HTMLElement);
                    }
                });
            }, config);

        this.Observe(document.documentElement);
    }

    private LoadLazyElem(lazyTarget: HTMLElement) {
        if (this._io)
            this._io.unobserve(lazyTarget);

        if (lazyTarget.dataset.src) {
            lazyTarget.setAttribute("src", lazyTarget.dataset.src);
            delete lazyTarget.dataset.src;
        }
        else if (lazyTarget.dataset.srcset) {
            lazyTarget.setAttribute("srcset", lazyTarget.dataset.srcset);
            delete lazyTarget.dataset.srcset;
        }

        if (lazyTarget.dataset.onload) {
            lazyTarget.setAttribute("onload", lazyTarget.dataset.onload);
            delete lazyTarget.dataset.onload;
        }
    }

    public Observe(context: HTMLElement) {
        const entries = context.querySelectorAll("[data-srcset],[data-src]");
        
        entries.forEach((lazyTarget) => {
            if (!this._io) {
                this.LoadLazyElem(lazyTarget as HTMLElement);
                return;
            }

            this._io.observe(lazyTarget);

            // #61998: Disable requestIdleCallback so that images will not load automatically when browser is idle, but only on scroll
            //if (typeof requestIdleCallback === "function")
            //    requestIdleCallback(() => {
            //        this.LoadLazyElem(lazyTarget as HTMLElement);
            //    });
        });
    }

    public static get Instance() {
        return (this._instance || (this._instance = new this()));
    }
}
export const lazyLoader = LazyLoader.Instance;