import * as Utilities from "./utilities";
import { PostCode } from "./post-code";
import { GtmAnalytics } from "./gtm";
import { popup } from "./popup";
import { CSSelect, CSOption } from "./csSelect";

export abstract class CartBase {
    private _clickListener: any;
    protected _prevPostCode: string;
    protected _prevShopCode: string;

    protected abstract _lines: Element;
    protected abstract get AddQuantityUrl(): string;
    protected abstract get DeleteItemUrl(): string;
    protected abstract UpdateCart(res: any, line?: HTMLElement): void;
    protected abstract ToggleEmptyCart(force: boolean): boolean;
    protected abstract get ArticleMargin(): number;
    protected abstract _postCode: HTMLInputElement;
    protected abstract _shopList: CSSelect;
    protected abstract _delivery: HTMLElement;
    protected abstract _deliveryTitle: HTMLElement;
    protected abstract _selectList: NodeListOf<Element>;
    protected abstract _busyContainer: HTMLElement;
    protected abstract _submitOrderPickupDrive: HTMLButtonElement;
    protected abstract SetDeliveryValid(): void;
    protected abstract RefreshCart(): void;

    protected Init() {
        new PostCode(this._postCode, this, this.DeliverySearch_Request, this.SetDeliveryValid);
        this._shopList.addEventListener("change", () => {
            this.DeliverySearch_Request(Utilities.DeliveryType.ShopList);
        });
    }

    protected get ShopCode() {
        return this._shopList.value;
    }

    protected get PostCode() {
        return this._postCode.value.trim();
    }

    protected UpdatePreviousValues() {
        this._prevPostCode = this.PostCode;
        this._prevShopCode = this.ShopCode;
    }

    private SetDeliveryInvalid(state: Utilities.ValidityStates, message?: string) {
        this._delivery.classList.add("error");
        message = message || this._deliveryTitle.dataset[`validity${Utilities.ValidityStates[state]}`];
        if (message)
            this._deliveryTitle.innerHTML = message;
    }

    protected DeliverySearch_Request(delivery: Utilities.DeliveryType) {
        if (!(delivery in Utilities.DeliveryType))
            return;

        const shopCode = this.ShopCode;
        const postCode = this.PostCode;

        if (postCode === this._prevPostCode && shopCode === this._prevShopCode)
            return;

        this.UpdatePreviousValues();

        if (!this._postCode.checkValidity()) {
            let state = Utilities.ValidityStates.Patternmismatch;
            if (this._postCode.validity.tooShort)
                state = Utilities.ValidityStates.Tooshort;

            this.SetDeliveryInvalid(state);
            return;
        }

        if (!postCode && !shopCode) {
            this.SetDeliveryInvalid(Utilities.ValidityStates.Valuemissing);
            return;
        }

        const body = {
            postalCode: undefined as string,
            shopCode: undefined as string,
            isFromCart: true
        };
        switch (delivery) {
            case Utilities.DeliveryType.PostCode:
                body.postalCode = postCode;
                break;
            case Utilities.DeliveryType.ShopList:
                body.shopCode = shopCode;
                break;
        }

        Utilities.Fetch({
            url: "/offer/getshoplistforpostalcode",
            method: Utilities.FetchMethod.POST,
            success: (e) => this.DeliverySearch_Response(JSON.parse((e.target as XMLHttpRequest).response)),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify(body),
            showBusy: true,
            busyContainer: this._busyContainer
        });
    }

    protected Select_Click(select: HTMLElement, container: HTMLElement, force?: boolean) {
        const maxHeight = (container.firstElementChild.scrollHeight + 2) + "px";

        requestAnimationFrame(_ => {
            // Initialise max-height after reload with open services
            if (container.style.maxHeight == "none")
                container.style.maxHeight = maxHeight;

            requestAnimationFrame(_ => {
                container.style.maxHeight = select.classList.toggle("selected", force) ? maxHeight : "";
            });

        });
    }

    protected SelectOption_Click(item: HTMLElement, value: HTMLElement, options: Array<Node>): boolean {
        let changed = false;

        options.forEach((option: HTMLElement) => {
            if (option == item) {
                if (!option.classList.contains("selected")) {
                    option.classList.add("selected");
                    changed = true;
                }
                value.textContent = option.textContent;
            }
            else
                option.classList.remove("selected");
        });

        return changed;
    }

    protected DeliverySearch_Response(res: any) {
        if (!this.IsSuccess(res)) {
            if (res.InvalidPostCode)
                this.SetDeliveryInvalid(Utilities.ValidityStates.Customerror, res.InvalidPostCode);
            return;
        }
        this.SetDeliveryValid();

        this._postCode.value = res.PostCode || "";

        if (res.ShopList) {
            let i, L = this._shopList.options.length - 1;
            for (i = L; i >= 0; i--) {
                this._shopList.options[i].remove();
            }

            this._shopList.toggleAttribute("disabled", Object.keys(res.ShopList).length == 0);

            const tagName = typeof customElements === "undefined" ? "option" : "cs-option";
            if (Array.isArray(res.ShopList)) {
                res.ShopList.forEach((shop: any) => {
                    const option = document.createElement(tagName) as CSOption;
                    option.setAttribute("value", shop.ShopCode);
                    option.textContent = shop.Description;
                    if (shop.Availability)
                        option.classList.add("avail");
                    this._shopList.appendChild(option);
                });
            }
            else {
                for (const key in res.ShopList) {
                    if (res.ShopList.hasOwnProperty(key)) {
                        const option = document.createElement(tagName) as CSOption;
                        option.setAttribute("value", key);
                        option.textContent = res.ShopList[key];
                        this._shopList.appendChild(option);
                    }
                }
            }

            // Edge 18 and below
            if (typeof customElements === "undefined" && this._shopList.hasAttribute("default")) {
                const defOpt = document.createElement("option");
                defOpt.defaultSelected = defOpt.disabled = defOpt.hidden = true;
                defOpt.text = this._shopList.getAttribute("default");
                this._shopList.prepend(defOpt);
                (defOpt.parentElement as HTMLSelectElement).selectedIndex = 0;
            }
        }

        if (res.ShopCode) {
            this._shopList.value = res.ShopCode;
            this._shopList.setAttribute("default", res.ShopDescription);
        }

        if (this._submitOrderPickupDrive && typeof res.PickUpDriveEnabled === "boolean") {
            if (res.PickUpDriveEnabled) {
                this._submitOrderPickupDrive.classList.remove("disabled");
                this._submitOrderPickupDrive.title = "";
            }
            else {
                this._submitOrderPickupDrive.classList.add("disabled");

                const popupElem = this._submitOrderPickupDrive.parentElement.querySelector(".popup") as HTMLElement;
                if (popup)
                    popup.Close(popupElem);
            }
        }

        if (res.PostCode)
            this.RefreshCart();
    }

    protected Services_Request(type: string, checked: boolean, line: HTMLElement, quantity?: number, target?: HTMLElement) {
        if (!type)
            return;
        const itemReference = line.dataset.itemreference;
        if (!itemReference)
            return;
        const isGift = (line.dataset.isgift || "").toLowerCase() == "true";

        const options: Utilities.FetchOptions = {
            url: "/shoppingcart/step1updateservice",
            method: Utilities.FetchMethod.POST,
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response), line),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                itemReference: itemReference,
                state: checked,
                isGift: isGift,
                type: type,
                quantity: quantity
            }),
            showBusy: true
        };
        if (target) {
            this._clickListener = target.onclick;
            target.onclick = undefined;
            options.always = () => { if (this._clickListener) target.onclick = this._clickListener; };
        }
        Utilities.Fetch(options);
    }

    protected Quantity_Click(e: Event, line: HTMLElement, fromServices = false) {
        const self = e.target as HTMLButtonElement;
        const currentTarget = e.currentTarget as HTMLElement;
        if (self == currentTarget)
            return;

        const maxQuantity = fromServices ? parseInt(line.dataset.quantity) : Utilities.DataListObj.MaxLineQuantity;

        const isMinus = self.classList.contains("minus");
        const isPlus = self.classList.contains("plus");
        if (!isMinus && !isPlus)
            return;
        const quantity = currentTarget.querySelector(":scope > input[name=quantity]") as HTMLInputElement;
        const other = (isMinus ? currentTarget.querySelector(":scope .plus") : currentTarget.querySelector(":scope .minus")) as HTMLButtonElement;
        let intQuantity = parseInt(quantity.value);

        if (isMinus) {
            intQuantity--;
            if (intQuantity < 1)
                return;
            if (intQuantity <= 1)
                self.disabled = true;
        }
        else {
            intQuantity++;
            GtmAnalytics.Instance.gtmClickAddItemToCart(self);
            if (intQuantity > maxQuantity)
                return;
            if (intQuantity >= maxQuantity)
                self.disabled = true;
        }
        other.disabled = false;

        quantity.value = intQuantity.toString();

        if (fromServices) {
            const checkbox = Utilities.PreviousSibling(currentTarget, ".checkbox").querySelector(":scope > input") as HTMLInputElement;
            if (checkbox.checked) {
                this._clickListener = currentTarget.onclick;
                currentTarget.onclick = undefined;

                this.Services_Request(checkbox.dataset.type, checkbox.checked, line, intQuantity, currentTarget);
            }
        }
        else {
            this._clickListener = currentTarget.onclick;
            currentTarget.onclick = undefined;

            this.AddQuantity_Request(intQuantity, line, currentTarget);
        }
    }

    protected Quantity_KeyUp = ((e: KeyboardEvent) => {
        const quantityInput = e.currentTarget as HTMLInputElement;
        const quantity = quantityInput.parentElement;
        const intQuantity = parseInt(quantityInput.value) || 1;
        const minus = quantity.querySelector(":scope > .minus") as HTMLButtonElement;
        const plus = quantity.querySelector(":scope > .plus") as HTMLButtonElement;

        if (intQuantity <= 1) {
            minus.disabled = true;
            plus.disabled = false;
        }
        else if (intQuantity >= Utilities.DataListObj.MaxLineQuantity) {
            plus.disabled = true;
            minus.disabled = false;
        }
        else {
            plus.disabled = false;
            minus.disabled = false;
        }

        if (e.key == "Enter") {
            quantityInput.blur();
            return;
        }

        if (!this.Quantity_CheckValidity(quantityInput))
            return;
    }).bind(this);

    protected Quantity_FocusOut = ((e: Event) => {
        const quantityInput = e.currentTarget as HTMLInputElement;
        const quantity = quantityInput.parentElement;
        const line = quantity.parentElement;

        if (quantityInput.value === "")
            quantityInput.value = "1";

        if (!this.Quantity_CheckValidity(quantityInput))
            return;

        this.AddQuantity_Request(parseInt(quantityInput.value), line, quantity);
    }).bind(this);

    private AddQuantity_Request(quantity: number, line: HTMLElement, target: HTMLElement) {
        if (quantity == 0)
            return;
        const itemReference = line.dataset.itemreference;
        if (!itemReference)
            return;

        Utilities.Fetch({
            url: this.AddQuantityUrl,
            method: Utilities.FetchMethod.POST,
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response), line),
            always: () => { if (this._clickListener) target.onclick = this._clickListener; },
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                itemReference: itemReference,
                quantity: quantity
            }),
            showBusy: true
        });
    }

    private Quantity_CheckValidity = ((quantityInput: HTMLInputElement) => {
        const quantity = quantityInput.parentElement;

        if (quantityInput.checkValidity()) {

            this.SetValid(quantity);
            return 1;
        }

        let state = undefined;
        if (quantityInput.validity.rangeUnderflow)
            state = Utilities.ValidityStates.Rangeunderflow;
        else if (quantityInput.validity.rangeOverflow)
            state = Utilities.ValidityStates.Rangeoverflow;
        if (quantityInput.validity.badInput)
            state = Utilities.ValidityStates.Badinput;

        this.SetInvalid(quantity, state);

        return 0;
    }).bind(this);

    private SetValid(container: HTMLElement) {
        if (!container.classList.contains("error"))
            return;

        container.classList.remove("error");
        delete container.dataset.status;
        //this._deliveryTitle.innerHTML = this._deliveryTitle.dataset.title;
    }

    private SetInvalid(container: HTMLElement, state: Utilities.ValidityStates = Utilities.ValidityStates.Invalid, message?: string) {
        container.classList.add("error");
        delete container.dataset.status;
        //this.ToggleAddToCart(false);

        if (!message && state == Utilities.ValidityStates.Valuemissing)
            message = Utilities.DataListObj.ValidationMessage.Valuemissing;

        if (!message)
            message = container.dataset[`validity${Utilities.ValidityStates[state]}`];

        if (message)
            container.dataset.status = message;
    }

    protected Delete_Click(e: Event, line: HTMLElement) {
        const currentTarget = e.currentTarget as HTMLElement;

        this._clickListener = currentTarget.onclick;
        currentTarget.onclick = undefined;

        this.Delete_Request(line, currentTarget);
    }

    private Delete_Request(line: HTMLElement, target: HTMLElement) {
        const itemReference = line.dataset.itemreference;
        if (!itemReference)
            return;
        const isGift = (line.dataset.isgift || "").toLowerCase() == "true";

        Utilities.Fetch({
            url: this.DeleteItemUrl,
            method: Utilities.FetchMethod.POST,
            success: (e) => this.Delete_Response(JSON.parse((e.target as XMLHttpRequest).response), line),
            always: () => { if (this._clickListener) target.onclick = this._clickListener; },
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                itemReference: itemReference,
                shopCode: line.dataset.shopcode,
                isGift: isGift
            }),
            showBusy: true
        });
    }

    protected IsSuccess(res: any) {
        return (res.Status || res.StatusAsString) === "Successful";
    }

    private Delete_Response(res: any, line: HTMLElement) {
        //if (this.ToggleEmptyCart(this._lines.childElementCount <= 1))
        //    return;

        if (this.IsSuccess(res)) {
            line.addEventListener("transitionend", () => this.Delete_Animate(line));
            line.classList.add("deleted");
        }

        this.UpdateCart(res, line);
    }

    private Delete_Animate(lineToDelete: HTMLElement) {
        const parent = lineToDelete.parentNode as HTMLElement;

        const nodes = Array.prototype.slice.call(parent.children) as Array<Node>;
        const startIndex = nodes.indexOf(lineToDelete) + 1;
        const parentLength = nodes.length;
        const height = lineToDelete.getBoundingClientRect().height + this.ArticleMargin; // margin

        if (startIndex > parentLength)
            return;
        else if (startIndex === parentLength) {
            parent.removeChild(lineToDelete);
            return;
        }

        requestAnimationFrame(_ => {
            for (let i = startIndex; i < parentLength; i++) {
                const line = nodes[i] as HTMLElement;

                line.style.transition = "transform .5s cubic-bezier(0,0,0.31,1)";
            }

            const onAnimationComplete = (e: Event) => {
                for (let i = startIndex; i < parentLength; i++) {
                    const line = nodes[i] as HTMLElement;

                    line.style.transition = "";
                    line.style.transform = "";
                }
                parent.removeChild(lineToDelete);
                e.target.removeEventListener("transitionend", onAnimationComplete);
            };
            nodes[parentLength - 1].addEventListener("transitionend", onAnimationComplete);

            requestAnimationFrame(_ => {
                for (let i = startIndex; i < parentLength; i++) {
                    const line = nodes[i] as HTMLElement;

                    line.style.transform = `translateY(-${height}px)`;
                }
            });
        });
    }
}