import * as Utilities from "./utilities";
import * as Variables from "./variables";
import * as Enums from "./_enumerations";
import { CartBase } from "./cart-base";
import * as HeaderV2 from "./headerV2";
import { CSSelect } from "./csSelect";
import { app4Less } from "./app4Less";

declare function requestIdleCallback(...args: any[]): void;

class MiniCart extends CartBase {
    private static _instance: MiniCart;
    private static readonly _context: HTMLElement = document.getElementById("mini-cart");
    protected readonly _container: HTMLElement = MiniCart._context.querySelector(".mini-cart__container");
    protected _busyContainer = this._container;
    protected _lines = this._container.querySelector(".lines-container");
    private readonly _linePrices = this._container.querySelector(".lines-prices");
    private readonly _statusMessage = MiniCart._context.querySelector(".status-message");
    protected readonly _selectList = this._container.querySelectorAll(".select");
    protected readonly _delivery = this._container.querySelector(".delivery") as HTMLElement;
    protected readonly _deliveryTitle = this._delivery.querySelector(".delivery-grid > .mini-cart-h4") as HTMLElement;
    protected readonly _submitOrderPickupDrive: HTMLButtonElement;
    public _postCode = this._delivery.querySelector("#mini-cart-delivery") as HTMLInputElement;
    public _shopList = this._delivery.querySelector(".shop-list") as CSSelect;
    private _startX: number;
    private _currentX: number;
    private _touching: boolean;
    private _touchingThreshold: number = document.documentElement.clientWidth / 3;
    private readonly _update = this.Update.bind(this);
    protected RefreshCart = (): any => undefined;
    
    private _updateCartCalled: boolean;

    private constructor() {
        super();

        this.Init();

        this.UpdatePreviousValues();
        MiniCart._context.addEventListener("mousedown", () => this.Hide());
        this._container.addEventListener("mousedown", (e) => e.stopPropagation());
        this._container.querySelectorAll(".close").forEach(close => close.addEventListener("click", () => this.Hide()));
        
        document.addEventListener("touchstart", (e) => this.MiniCart_TouchStart(e));
        document.addEventListener("touchmove", (e) => this.MiniCart_TouchMove(e));
        document.addEventListener("touchend", () => this.MiniCart_TouchEnd());

        if (typeof requestIdleCallback === "function")
            requestIdleCallback(() => {
                this.MiniCart_Request();
            });

        const screen_Md_Lower = Variables.screen(Enums.Screen.md, Enums.ScreenBoundary.lower);
        screen_Md_Lower.addListener((e) => this.Screen_Md_Lower_Change(e.matches));
        this.Screen_Md_Lower_Change(screen_Md_Lower.matches);
    }

    private Screen_Md_Lower_Change(matches: boolean) {
        this._lines.querySelectorAll(":scope > * > .quantity").forEach((quantity: HTMLElement) => {
            this.Quantity_Screen_Md_Lower_Matches(matches, quantity);
        });
    }

    get AddQuantityUrl() { return "/layout/step1addquantity"; }
    get DeleteItemUrl() { return "/layout/step1deleteitem"; }
    get ArticleMargin() { return 0; }

    private InitialiseLines(itemReference: string) {
        this.Resize_Changed();
        (Array.prototype.slice.call(this._lines.children) as Array<Node>).forEach((line) => {
            this.InitialiseLine(line as HTMLElement, itemReference);
        });
    }

    private Resize_Changed() {
        this._container.classList.toggle("top-cont-buy", this._lines.children.length > 2);
        this._container.classList.toggle("has-scroll", this._lines.scrollHeight > this._lines.clientHeight);
    }

    protected SetDeliveryValid() {
        this._delivery.classList.remove("error");
        this._deliveryTitle.innerHTML = this._deliveryTitle.dataset.title || "";
    }

    private InitialiseLine(line: HTMLElement, itemReference?: string) {
        (line.querySelector(":scope > .quantity") as HTMLElement).onclick = (e) => this.Quantity_Click(e, line);
        (line.querySelector(".delete-bin") as HTMLElement).onclick = (e) => {
            this.Delete_Click(e, line);
            this.Resize_Changed();
        };

        if (itemReference && line.dataset.itemreference == itemReference) {
            this._lines.scrollTop = line.offsetTop;
            line.classList.add("added");
        }
    }

    private Quantity_Screen_Md_Lower_Matches(matches: boolean, quantity: HTMLElement) {
        const quantityInput = quantity.querySelector(":scope > input[name=quantity]") as HTMLInputElement;
        if (matches) {
            quantityInput.readOnly = true;
            quantityInput.removeEventListener("keyup", this.Quantity_KeyUp);
            quantityInput.removeEventListener("focusout", this.Quantity_FocusOut);
        }
        else {
            quantityInput.readOnly = false;
            quantityInput.addEventListener("keyup", this.Quantity_KeyUp);
            quantityInput.addEventListener("focusout", this.Quantity_FocusOut);
        }
    }

    public UpdateCartCount(count?: number): number {
        if (!isNaN(count))
            sessionStorage.setItem("BasketCount", count.toString());
        else
            count = parseInt(sessionStorage.getItem("BasketCount"));

        // previously selected ":scope > header > h2"
        (this._container.querySelector(".mini-cart-h2") as HTMLElement).dataset.quantity = count.toString();
        if (HeaderV2.header)
            HeaderV2.header.UpdateBasketCount(count);

        return count;
    }

    public UpdateCart(res: any, line?: HTMLElement, itemReference?: string) {
        if (!this.IsSuccess(res)) {
            this._statusMessage.innerHTML = res.StatusMessage;
            return;
        }

        this._updateCartCalled = true;

        this._statusMessage.innerHTML = "";

        this.UpdateCartCount(res.ItemsCount);

        if (this.ToggleEmptyCart(!res.ItemsCount))
            return;

        const services = this._linePrices.querySelector(".services");
        if (services.classList.toggle("hidden", !res.HasServices))
            services.querySelector(":scope > span").textContent = res.ServicesPrice;
        this._linePrices.querySelector(".total > span").textContent = res.SubTotal;

        if (res.LinesHtml || res.SingleLineHtml) {
            if (res.SingleLineHtml && line) {
                // Refresh single line
                line = Utilities.ReplaceOuterHTML(line, res.SingleLineHtml);
                this.InitialiseLine(line, itemReference);
            }
            else {
                // Refresh all lines
                this._lines = Utilities.ReplaceInnerHTML(this._lines, res.LinesHtml);
                this.InitialiseLines(itemReference);
            }
        }
    }

    protected ToggleEmptyCart(force: boolean): boolean {
        if (typeof force === "boolean") {
            if (force)
                this._container.classList.add("empty");
            else
                this._container.classList.remove("empty");

            return force;
        }

        return this._container.classList.toggle("empty", force);
    }

    public RefreshMiniCart(postalCode?: string, shopCode?: string) {

        const body = {
            postalCode: undefined as string,
            shopCode: undefined as string,
            isFromCart: true
        };
        body.postalCode = postalCode;
        body.shopCode = shopCode;

        Utilities.Fetch({
            url: "/offer/getshoplistforpostalcode",
            method: Utilities.FetchMethod.POST,
            success: (e) => this.DeliverySearch_Response(JSON.parse((e.target as XMLHttpRequest).response)),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify(body),
            showBusy: true,
            busyContainer: this._busyContainer
        });
    }

    private MiniCart_TouchStart(e: TouchEvent) {
        if (!MiniCart._context.classList.contains("show") || this._lines.contains((e.target as Node)))
            return;

        this._startX = e.touches[0].pageX;
        this._currentX = this._startX;

        this._touching = true;
        requestAnimationFrame(this._update);
    }

    private MiniCart_TouchMove(e: TouchEvent) {
        if (!this._touching)
            return;

        this._currentX = e.touches[0].pageX;

        e.preventDefault();
    }

    private MiniCart_TouchEnd() {
        if (!this._touching)
            return;

        this._touching = false;

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = "";

        if (translateX > this._touchingThreshold)
            this.Hide();
    }

    private Update() {
        if (!this._touching)
            return;

        requestAnimationFrame(this._update);

        const translateX = Math.max(0, this._currentX - this._startX);
        this._container.style.transform = `translateX(${translateX}px`;
    }

    public Show = (res?: any, itemReference?: string) => this.Toggle(true, res, itemReference);

    public Hide = () => this.Toggle(false);

    public Toggle(show?: boolean, res?: any, itemReference?: string) {
        MiniCart._context.classList.toggle("show", show);
        document.documentElement.classList.toggle("noscroll", show);

        if (show) {
            if (!res && this._updateCartCalled)
                return;

            const cartContents = this._container.querySelector(":scope > article");
            if (cartContents)
                this._container.removeChild(cartContents);


            if (res) {
                this.UpdateCart(res, undefined, itemReference);
                return;
            }

            this.MiniCart_Request();
        }
        else {
            (document.activeElement as HTMLElement).blur();
        }
    }

    public AddToCart(
        productReference: string,
        itemReference: string,
        quantity: number,
        listValue: string,
        position: number,
        isAssemblySelected: boolean,
        isRecoverySelected: boolean,
        isWarrantySelected: boolean) {

        return new Promise<any>((resolve, reject) => {
            Utilities.Fetch({
                url: "/ShoppingCart/AddToBasket",
                method: Utilities.FetchMethod.POST,
                success: (e) => resolve(JSON.parse((e.target as XMLHttpRequest).response)),
                error: (e) => reject(e),
                headers: { "Content-Type": "application/json;charset=UTF-8" },
                body: JSON.stringify({
                    productReference: productReference,
                    itemReference: itemReference,
                    quantity: quantity,
                    marketingTagListValue: listValue,
                    marketingTagPosition: position,
                    isAssemblySelected: isAssemblySelected,
                    isRecoverySelected: isRecoverySelected,
                    isWarrantySelected: isWarrantySelected
                }),
                showBusy: true
            });
        });
    }

    public MiniCart_Request() {
        const cartCount = this.UpdateCartCount();

        if (this.ToggleEmptyCart(!cartCount))
            return;

        Utilities.Fetch({
            url: "/layout/getbasketmodalv2",
            success: (e) => this.UpdateCart(JSON.parse((e.target as XMLHttpRequest).response)),
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            showBusy: true,
            busyContainer: this._lines as HTMLElement
        });
    }

    public get Context() {
        return MiniCart._context;
    }

    public set BusyContainer(container: HTMLElement) {
        this._busyContainer = container;
    }

    public static get Instance() {
        if (!this._context)
            return;

        if (!this._instance && (window as any).miniCart)
            this._instance = (window as any).miniCart;

        return this._instance || (this._instance = new this());
    }
}

//export let miniCart: MiniCart;
//if (!(window as any).miniCart)
//    miniCart = MiniCart.Instance;
export let miniCart = MiniCart.Instance;

if (miniCart) {
    (window as any).miniCart = miniCart;
}