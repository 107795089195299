﻿import { gtmVirtualPageViewCart, refreshGtmBindings } from "./cs.gtm";
import { miniCart } from "./mini-cartV2";

/** Shop Pop Up addItem start **/
let addItemLinkId = ".btn-add-item-to-cart";
let addItemPopUpHolder = "body";
let addItemSectionId = " .post-add-item-modal ";
let addItemSectionTempHolderId = " #addItem-modal-temp ";
let btnCount = 1;
//$(addItemLinkId).bind("click", GetaddItemForm);
$(document).ready(function () {
    $(".btn-add-item-to-cart-from-card").on("click", AddItemToCartFromCard);
    $(".btn-add-item-to-wishlist-from-card").on("click", AddToWishlist_Card_V2);
    //CheckButtons();
});
function AddItemToCartFromCard() {
    // Used in case of template for SEO reasons
    // Currently not viable
    /*if ('content' in document.createElement('template')) {
        let miniCartTemp = document.getElementById('mini-cart-template');
        if (typeof (miniCartTemp) != 'undefined' && miniCartTemp != null) {
            var clone = miniCartTemp.content.cloneNode(true);
            document.body.replaceChild(clone, miniCartTemp);
        }
    }*/

    let isMultipleConfigurable = $(this).parent().find("input[name=isMultipleConfigurable]").val().toLowerCase();
    if (isMultipleConfigurable == "true") {
        createCookie("productPosition", event.currentTarget.dataset.gtmDetailPosition, 1);
        createCookie("productGTMListing", event.currentTarget.dataset.gtmDetailSource, 1);
        createCookie("ic", "true", 1);
        return true;
    }
    else {
        let quantity = 1;
        let listing = getGTMProductListing(this);
        let position = getGTMPosition(this);
        let productReference = $(this).parent().find("input[name=productReference]").val();
        let itemReference = $(this).parent().find("input[name=itemReference]").val();
        AddItemToCart(productReference, itemReference, quantity, listing, position);
    }
    return false;
}
function AddItemToCart(productReference, itemReference, quantity, listValue, position) {
    if ($(addItemPopUpHolder).length == 0) {
        console.log("holder " + addItemPopUpHolder + " not found");
        return false;
    }
    if (quantity === undefined || quantity == ""
        || itemReference === undefined || itemReference == "" || productReference === undefined || productReference == "") {
        return false;
    }
    AddItemToCartAjaxCall(productReference, itemReference, quantity, listValue, position);
    return true;
}

function isSuccessful(data) {
    return data.StatusAsString == "Successful";
}

function AddItemToCartAjaxCall(productReference, itemReference, quantity, listValue, position) {

    let marketingObject =
    {
        "ListValue": listValue,
        "Position": position
    };
    //call ajax query
    if (ajaxBusy)
        ajaxBusy.PageBusy = true;
    $.post("/ShoppingCart/AddToBasket",
        {
            "productReference": productReference,
            "itemReference": itemReference,
            "quantity": quantity,
            "marketingTagListValue": listValue,
            "marketingTagPosition": position
        },
        (data) => {
            if (isSuccessful(data)) {
                if (data.ShoppingCartResponse && typeof cart === "object") {
                    if (cart.UpdateCart)
                        cart.UpdateCart(data.ShoppingCartResponse);
                    else
                        parent.location.reload();
                }

                if (typeof miniCart === "object" && data.SimpleCartResponse && !document.getElementById("cart")) {
                    miniCart.Show(data.SimpleCartResponse, itemReference);
                }
                else {
                    if (data.PostAddItemToBasketHtmlV2) {
                        const template = document.getElementById("modal-post-add-item");
                        if (template) {
                            $(template)[0].outerHTML = data.PostAddItemToBasketHtmlV2;
                        }
                        else {
                            $(addItemPopUpHolder).prepend(data.PostAddItemToBasketHtmlV2);
						}
                        modal.Show("modal-post-add-item");
                    }
                    else {
                        if (IsAddItemSectionLoaded()) {
                            $(addItemPopUpHolder + " " + addItemSectionId + " .contents").html($("<div/>").html(data.PostAddItemToBasketHtml).contents().find(".contents").html());
                        }
                        else {
                            $(addItemPopUpHolder).prepend(data.PostAddItemToBasketHtml);
                            $(addItemSectionId).modal();
                        }
                        $(addItemSectionId).on("hide.bs.modal", function (e) {
                            if (gtmVirtualPageviewCartClosed) {
                                gtmVirtualPageviewCartClosed();
                            }

                            if (typeof cart === "object" && cart.ScrollTo)
                                cart.ScrollTo(itemReference);
                        });
                        $(addItemSectionId).modal("show");
					}

                    if (data.TopCartContentResponse.Content)
                        updateHeaderCartWrapper(data.TopCartContentResponse.Content);//found in header.js
                }

                if (gtmVirtualPageViewCart) {
                    gtmVirtualPageViewCart();
                    refreshGtmBindings();
                }
            }
            populateStatusMessage(data);
        });

}
function IsAddItemSectionLoaded() {
    return $(addItemPopUpHolder + " " + addItemSectionId).length > 0;
}

function AddToWishlist_Card_V2(event) {
    let quantity = "1";
    let itemReference = $(this).closest("div.product-container").find(".itemReferenceCard").val();
    let productReference = $(this).closest("div.product-container").find(".productReferenceCard").val();
    let wishlistbtn = "#wishlistcardBtn";
    let actualbtn = wishlistbtn + "-" + productReference + "-" + itemReference;
    //actualbtn = actualbtn.concat(itemReference);

    $.post("/Customer/AddToWishlist_Version2",
        {
            "productReference": productReference,
            "itemReference": itemReference,
            "quantity": quantity
        },
        function (data) {
            if (isSuccessful(data)) {

                if (data.ReponseStatus.Message == "Redirect") {
                    window.location = data.ReponseStatus.Object;
                }
                else if (data.ReponseStatus.Object == "true") {
                    $(actualbtn).addClass("grey");
                    gtmAddToWishList($(actualbtn)[0]);
                }
                else {
                    $(actualbtn).removeClass("grey");
                }
            }
        });
}

function CheckButtons(event) {
    let itemReference = $(this).closest("div.product-container").find("input[name=itemReference]").val();
    let productReference = $(this).closest("div.product-container").find("input[name=productReference]").val();
    let wishlistbtn = "#wishlistcardBtn";
    let actualbtn = wishlistbtn.concat(itemReference);
    //actualbtn = actualbtn.concat(itemReference);

    $.post("/Customer/CheckWishlist",
        {
            "productReference": productReference,
            "itemReference": itemReference,
        },
        function (data) {
            if (isSuccessful(data)) {
                if (data.ReponseStatus.Object = "true")
                    $(actualbtn).toggleClass("grey");
            }
        });

}

function populateStatusMessage(data) {
    //console.log("Status : " + data.StatusAsString);
    //console.log("Possible status Html : " + data.RenderedStatus);
    if (isSuccessful(data)) {
        //console.log("Message is successful!");
        $("#status-message").html("");
    }
    else {
        //console.log("Not successful. Html will be :" + data.RenderedStatus);
        $("#status-message").html(data.RenderedStatus);
    }
}

export {
    AddItemToCart
}